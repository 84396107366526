import React from "react";
import useSVGCarrels from "../useSVGCarrels";

export default ({user, carrels}) => {
    const Modal = useSVGCarrels(user, carrels);

    return (
        (carrels.length > 0) ? <g id="layer2" className="all">
            <rect
                id="rect8498-4"
                width="3.976"
                height="197.152"
                x="510.592"
                y="-579.275"
                fill="#dfdfdf"
                fillOpacity="1"
                stroke="none"
                strokeDasharray="none"
                strokeLinecap="round"
                strokeMiterlimit="4"
                strokeOpacity="1"
                strokeWidth="1.329"
                enableBackground="new"
                opacity="0.688"
                ry="1.584"
                transform="rotate(90)"
            ></rect>
            <rect
                id="rect8748-9"
                width="5.452"
                height="87.476"
                x="57.11"
                y="264.24"
                fill="#dfdfdf"
                fillOpacity="1"
                stroke="none"
                strokeDasharray="none"
                strokeLinecap="round"
                strokeMiterlimit="4"
                strokeOpacity="1"
                strokeWidth="1.037"
                enableBackground="new"
                opacity="0.688"
                ry="0.703"
            ></rect>
            <path
                id="path6470-7"
                fill="#fff"
                fillOpacity="1"
                stroke="#dfdfdf"
                strokeDasharray="none"
                strokeLinecap="butt"
                strokeLinejoin="miter"
                strokeMiterlimit="4"
                strokeOpacity="1"
                strokeWidth="4"
                d="M159.874 593.56l-.233 55.826 9.981.3.187 92.925 218.983 1.013-.252-90.652 31.73-.106.055-18.621-23.53-.187 149.787-.057-38.693.347.118 23.339 67.29.118-.407-446.022-178.06-.113.151-26.232-237.763-1.023.308 28.183-111.382-.135-.151 380.914z"
                enableBackground="new"
                filter="url(#a)"
                opacity="1"
                transform="translate(-85.58 -112.438) scale(1.25234)"
            ></path>
            <rect
                id="rect8494-4"
                width="6.58"
                height="87.476"
                x="545.525"
                y="266.389"
                fill="#dfdfdf"
                fillOpacity="1"
                stroke="none"
                strokeDasharray="none"
                strokeLinecap="round"
                strokeMiterlimit="4"
                strokeOpacity="1"
                strokeWidth="1.139"
                enableBackground="new"
                opacity="0.688"
                ry="0.703"
            ></rect>
            <rect
                id="rect6480-2"
                width="127.22"
                height="246.209"
                x="-125.629"
                y="1125.015"
                fill="#78bddb"
                fillOpacity="1"
                stroke="none"
                strokeDasharray="none"
                strokeLinecap="round"
                strokeMiterlimit="4"
                strokeOpacity="1"
                strokeWidth="1.606"
                enableBackground="new"
                filter="url(#b)"
                opacity="1"
                ry="0.561"
                transform="matrix(1.17698 0 0 .34207 165.028 -206.186)"
            ></rect>
            <rect
                id="rect6482-4"
                width="127.22"
                height="246.209"
                x="-125.629"
                y="1125.015"
                fill="#78bddb"
                fillOpacity="1"
                stroke="none"
                strokeDasharray="none"
                strokeLinecap="round"
                strokeMiterlimit="4"
                strokeOpacity="1"
                strokeWidth="1.606"
                enableBackground="new"
                filter="url(#b)"
                opacity="1"
                ry="0.561"
                transform="matrix(.91487 0 0 .3371 446.985 -198.652)"
            ></rect>
            <rect
                id="rect8496-0"
                width="6.54"
                height="87.476"
                x="383.037"
                y="267.051"
                fill="#dfdfdf"
                fillOpacity="1"
                stroke="none"
                strokeDasharray="none"
                strokeLinecap="round"
                strokeMiterlimit="4"
                strokeOpacity="1"
                strokeWidth="1.136"
                enableBackground="new"
                opacity="0.688"
                ry="0.703"
            ></rect>
            <rect
                id="rect6484-2"
                width="127.22"
                height="246.209"
                x="-125.629"
                y="1125.015"
                fill="#78bddb"
                fillOpacity="1"
                stroke="none"
                strokeDasharray="none"
                strokeLinecap="round"
                strokeMiterlimit="4"
                strokeOpacity="1"
                strokeWidth="1.606"
                enableBackground="new"
                filter="url(#b)"
                opacity="1"
                ry="0.561"
                transform="matrix(.91487 0 0 .3371 586.152 -198.652)"
            ></rect>
            <rect
                id="rect6486-7"
                width="127.22"
                height="246.209"
                x="-125.629"
                y="1125.015"
                fill="#78bddb"
                fillOpacity="1"
                stroke="none"
                strokeDasharray="none"
                strokeLinecap="round"
                strokeMiterlimit="4"
                strokeOpacity="1"
                strokeWidth="1.606"
                enableBackground="new"
                filter="url(#b)"
                opacity="1"
                ry="0.561"
                transform="matrix(.35296 0 0 .64338 95.78 -361.457)"
            ></rect>
            <rect
                id="rect6488-2"
                width="127.22"
                height="246.209"
                x="-125.629"
                y="1125.015"
                fill="#78bddb"
                fillOpacity="1"
                stroke="none"
                strokeDasharray="none"
                strokeLinecap="round"
                strokeMiterlimit="4"
                strokeOpacity="1"
                strokeWidth="1.606"
                enableBackground="new"
                filter="url(#b)"
                opacity="1"
                ry="0.561"
                transform="matrix(.24419 0 0 .34293 294.72 -206.758)"
            ></rect>
            <rect
                id="rect6490-0"
                width="127.22"
                height="246.209"
                x="-125.629"
                y="1125.015"
                fill="#78bddb"
                fillOpacity="1"
                stroke="none"
                strokeDasharray="none"
                strokeLinecap="round"
                strokeMiterlimit="4"
                strokeOpacity="1"
                strokeWidth="1.606"
                enableBackground="new"
                filter="url(#b)"
                opacity="1"
                ry="0.561"
                transform="matrix(1.42072 0 0 .33268 359.073 -105.49)"
            ></rect>
            <rect
                id="rect6492-1"
                width="127.22"
                height="246.209"
                x="-125.629"
                y="1125.015"
                fill="#78bddb"
                fillOpacity="1"
                stroke="none"
                strokeDasharray="none"
                strokeLinecap="round"
                strokeMiterlimit="4"
                strokeOpacity="1"
                strokeWidth="1.606"
                enableBackground="new"
                filter="url(#b)"
                opacity="1"
                ry="0.561"
                transform="matrix(1.41184 0 0 .64188 358.522 -364.5)"
            ></rect>
            <rect
                id="rect6494-6"
                width="127.22"
                height="246.209"
                x="-125.629"
                y="1125.015"
                fill="#78bddb"
                fillOpacity="1"
                stroke="none"
                strokeDasharray="none"
                strokeLinecap="round"
                strokeMiterlimit="4"
                strokeOpacity="1"
                strokeWidth="1.606"
                enableBackground="new"
                filter="url(#b)"
                opacity="1"
                ry="0.561"
                transform="matrix(1.42075 0 0 .40008 359.406 73.138)"
            ></rect>
            <rect
                id="rect6496-1"
                width="127.22"
                height="246.209"
                x="-125.629"
                y="1125.015"
                fill="#78bddb"
                fillOpacity="1"
                stroke="none"
                strokeDasharray="none"
                strokeLinecap="round"
                strokeMiterlimit="4"
                strokeOpacity="1"
                strokeWidth="1.606"
                enableBackground="new"
                filter="url(#b)"
                opacity="1"
                ry="0.561"
                transform="matrix(.91487 0 0 .3371 524.83 -110.167)"
            ></rect>
            <rect
                id="rect6498-9"
                width="127.22"
                height="246.209"
                x="-125.629"
                y="1125.015"
                fill="#78bddb"
                fillOpacity="1"
                stroke="none"
                strokeDasharray="none"
                strokeLinecap="round"
                strokeMiterlimit="4"
                strokeOpacity="1"
                strokeWidth="1.606"
                enableBackground="new"
                filter="url(#b)"
                opacity="1"
                ry="0.561"
                transform="matrix(.38037 0 0 .34396 227.038 -207.932)"
            ></rect>
            <rect
                id="rect6500-4"
                width="127.22"
                height="246.209"
                x="-125.629"
                y="1125.015"
                fill="#78bddb"
                fillOpacity="1"
                stroke="none"
                strokeDasharray="none"
                strokeLinecap="round"
                strokeMiterlimit="4"
                strokeOpacity="1"
                strokeWidth="1.606"
                enableBackground="new"
                filter="url(#b)"
                opacity="1"
                ry="0.561"
                transform="matrix(.23112 0 0 .33624 584.906 -109.617)"
            ></rect>
            <g
                id="g6568-9"
                fill="#c6e48b"
                fillOpacity="0.985"
                stroke="#040404"
                strokeOpacity="0.418"
                transform="matrix(.79014 0 0 .79014 353.426 432.658)"
            >
                <text
                    xmlSpace="preserve"
                    style={{lineHeight: "0.5"}}
                    id="text5663"
                    x="-264.697"
                    y="-159.588"
                    fill="#000"
                    fillOpacity="1"
                    stroke="none"
                    strokeWidth="0.265"
                    fontFamily="sans-serif"
                    fontSize="10.583"
                    fontStyle="normal"
                    fontWeight="normal"
                    letterSpacing="0"
                    transform="rotate(-90)"
                    wordSpacing="0"
                >
                    <tspan id="tspan5661" x="-264.697" y="-159.588" strokeWidth="0.265">
                        <tspan
                            x="-264.697"
                            y="-159.588"
                            style={{lineHeight: "0.5"}}
                            id="tspan5659"
                            strokeWidth="0.265"
                            fontSize="6.35"
                        >
                            80
                        </tspan>
                    </tspan>
                </text>
                <rect
                    id="80"
                    className={`is-clickable ${((carrels[79].status === "RESERVED") || (carrels[79].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                    width="17.278"
                    height="9.217"
                    x="-270.305"
                    y="-164.862"
                    fill="#c6e48b"
                    fillOpacity="1"
                    stroke="#040404"
                    strokeOpacity="0.418"
                    strokeWidth="0.265"
                    opacity="0.5"
                    ry="0.561"
                    transform="rotate(-89.639)"
                ></rect>
                <path
                    id="path6566-2"
                    fill="#c6e48b"
                    fillOpacity="0.985"
                    stroke="#040404"
                    strokeDasharray="none"
                    strokeLinecap="butt"
                    strokeLinejoin="miter"
                    strokeMiterlimit="4"
                    strokeOpacity="0.418"
                    strokeWidth="1.613"
                    d="M-166.51 251.859l-.051 17.554"
                ></path>
            </g>
            <g
                id="g6574-3"
                fill="#c6e48b"
                fillOpacity="0.985"
                stroke="#040404"
                strokeOpacity="0.418"
                transform="matrix(.79014 0 0 .79014 353.426 447.328)"
            >
                <text
                    xmlSpace="preserve"
                    style={{lineHeight: "0.5"}}
                    id="text5669"
                    x="-264.542"
                    y="-159.883"
                    fill="#000"
                    fillOpacity="1"
                    stroke="none"
                    strokeWidth="0.265"
                    fontFamily="sans-serif"
                    fontSize="10.583"
                    fontStyle="normal"
                    fontWeight="normal"
                    letterSpacing="0"
                    transform="rotate(-90)"
                    wordSpacing="0"
                >
                    <tspan id="tspan5667" x="-264.542" y="-159.883" strokeWidth="0.265">
                        <tspan
                            x="-264.542"
                            y="-159.883"
                            style={{lineHeight: "0.5"}}
                            id="tspan5665"
                            strokeWidth="0.265"
                            fontSize="6.35"
                        >
                            81
                        </tspan>
                    </tspan>
                </text>
                <rect
                    id="81"
                    className={`is-clickable ${((carrels[80].status === "RESERVED") || (carrels[80].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                    width="17.278"
                    height="9.217"
                    x="-270.305"
                    y="-164.862"
                    fill="#c6e48b"
                    fillOpacity="0.985"
                    stroke="#040404"
                    strokeWidth="0.265"
                    opacity="0.5"
                    ry="0.561"
                    transform="rotate(-89.639)"
                ></rect>
                <path
                    id="path6572-6"
                    fill="#c6e48b"
                    fillOpacity="0.985"
                    stroke="#040404"
                    strokeDasharray="none"
                    strokeLinecap="butt"
                    strokeLinejoin="miter"
                    strokeMiterlimit="4"
                    strokeWidth="1.613"
                    d="M-166.51 251.859l-.051 17.554"
                ></path>
            </g>
            <g
                id="g6580-7"
                fill="#c6e48b"
                fillOpacity="0.985"
                stroke="#040404"
                strokeOpacity="0.418"
                transform="matrix(.79014 0 0 .79014 353.426 461.997)"
            >
                <text
                    xmlSpace="preserve"
                    style={{lineHeight: "0.5"}}
                    id="text5675"
                    x="-264.674"
                    y="-159.883"
                    fill="#000"
                    fillOpacity="1"
                    stroke="none"
                    strokeWidth="0.265"
                    fontFamily="sans-serif"
                    fontSize="10.583"
                    fontStyle="normal"
                    fontWeight="normal"
                    letterSpacing="0"
                    transform="rotate(-90)"
                    wordSpacing="0"
                >
                    <tspan id="tspan5673" x="-264.674" y="-159.883" strokeWidth="0.265">
                        <tspan
                            x="-264.674"
                            y="-159.883"
                            style={{lineHeight: "0.5"}}
                            id="tspan5671"
                            strokeWidth="0.265"
                            fontSize="6.35"
                        >
                            82
                        </tspan>
                    </tspan>
                </text>
                <rect
                    id="82"
                    className={`is-clickable ${((carrels[81].status === "RESERVED") || (carrels[81].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                    width="17.278"
                    height="9.217"
                    x="-270.305"
                    y="-164.862"
                    fill="#c6e48b"
                    fillOpacity="0.985"
                    stroke="#040404"
                    strokeWidth="0.265"
                    opacity="0.5"
                    ry="0.561"
                    transform="rotate(-89.639)"
                ></rect>
                <path
                    id="path6578-5"
                    fill="#c6e48b"
                    fillOpacity="0.985"
                    stroke="#040404"
                    strokeDasharray="none"
                    strokeLinecap="butt"
                    strokeLinejoin="miter"
                    strokeMiterlimit="4"
                    strokeWidth="1.613"
                    d="M-166.51 251.859l-.051 17.554"
                ></path>
            </g>
            <g
                id="g6588-9"
                fill="#c6e48b"
                fillOpacity="0.985"
                stroke="#040404"
                strokeOpacity="0.418"
                transform="translate(349.717 462.188) scale(.78827)"
            >
                <text
                    xmlSpace="preserve"
                    style={{lineHeight: "0.5"}}
                    id="text5681"
                    x="-265.088"
                    y="-115.944"
                    fill="#000"
                    fillOpacity="1"
                    stroke="none"
                    strokeWidth="0.265"
                    fontFamily="sans-serif"
                    fontSize="10.583"
                    fontStyle="normal"
                    fontWeight="normal"
                    letterSpacing="0"
                    transform="rotate(-90)"
                    wordSpacing="0"
                >
                    <tspan id="tspan5679" x="-265.088" y="-115.944" strokeWidth="0.265">
                        <tspan
                            x="-265.088"
                            y="-115.944"
                            style={{lineHeight: "0.5"}}
                            id="tspan5677"
                            strokeWidth="0.265"
                            fontSize="6.35"
                        >
                            88
                        </tspan>
                    </tspan>
                </text>
                <rect
                    id="88"
                    className={`is-clickable ${((carrels[87].status === "RESERVED") || (carrels[87].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                    width="17.278"
                    height="9.217"
                    x="-270.425"
                    y="-120.922"
                    fill="#c6e48b"
                    fillOpacity="0.985"
                    stroke="#040404"
                    strokeWidth="0.265"
                    opacity="0.5"
                    ry="0.561"
                    transform="rotate(-89.639)"
                ></rect>
                <text
                    xmlSpace="preserve"
                    style={{lineHeight: "0.5"}}
                    id="text5687"
                    x="-264.997"
                    y="-125.217"
                    fill="#000"
                    fillOpacity="1"
                    stroke="none"
                    strokeWidth="0.265"
                    fontFamily="sans-serif"
                    fontSize="10.583"
                    fontStyle="normal"
                    fontWeight="normal"
                    letterSpacing="0"
                    transform="rotate(-90)"
                    wordSpacing="0"
                >
                    <tspan id="tspan5685" x="-264.997" y="-125.217" strokeWidth="0.265">
                        <tspan
                            x="-264.997"
                            y="-125.217"
                            style={{lineHeight: "0.5"}}
                            id="tspan5683"
                            strokeWidth="0.265"
                            fontSize="6.35"
                        >
                            83
                        </tspan>
                    </tspan>
                </text>
                <rect
                    id="83"
                    className={`is-clickable ${((carrels[82].status === "RESERVED") || (carrels[82].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                    width="17.278"
                    height="9.217"
                    x="-270.442"
                    y="-130.195"
                    fill="#c6e48b"
                    fillOpacity="0.985"
                    stroke="#040404"
                    strokeWidth="0.265"
                    opacity="0.5"
                    ry="0.561"
                    transform="rotate(-89.639)"
                ></rect>
                <path
                    id="path6586-6"
                    fill="#c6e48b"
                    fillOpacity="0.985"
                    stroke="#040404"
                    strokeDasharray="none"
                    strokeLinecap="butt"
                    strokeLinejoin="miter"
                    strokeMiterlimit="4"
                    strokeWidth="1.613"
                    d="M-122.572 252.255l-.05 17.555"
                ></path>
            </g>
            <g
                id="g6596-3"
                fill="#c6e48b"
                fillOpacity="0.985"
                stroke="#040404"
                strokeOpacity="0.418"
                transform="matrix(.78828 0 0 .78828 349.717 447.517)"
            >
                <text
                    xmlSpace="preserve"
                    style={{lineHeight: "0.5"}}
                    id="text5693"
                    x="-265.077"
                    y="-115.944"
                    fill="#000"
                    fillOpacity="1"
                    stroke="none"
                    strokeWidth="0.265"
                    fontFamily="sans-serif"
                    fontSize="10.583"
                    fontStyle="normal"
                    fontWeight="normal"
                    letterSpacing="0"
                    transform="rotate(-90)"
                    wordSpacing="0"
                >
                    <tspan id="tspan5691" x="-265.077" y="-115.944" strokeWidth="0.265">
                        <tspan
                            x="-265.077"
                            y="-115.944"
                            style={{lineHeight: "0.5"}}
                            id="tspan5689"
                            strokeWidth="0.265"
                            fontSize="6.35"
                        >
                            87
                        </tspan>
                    </tspan>
                </text>
                <rect
                    id="87"
                    className={`is-clickable ${((carrels[86].status === "RESERVED") || (carrels[86].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                    width="17.278"
                    height="9.217"
                    x="-270.425"
                    y="-120.922"
                    fill="#c6e48b"
                    fillOpacity="0.985"
                    stroke="#040404"
                    strokeWidth="0.265"
                    opacity="0.5"
                    ry="0.561"
                    transform="rotate(-89.639)"
                ></rect>
                <text
                    xmlSpace="preserve"
                    style={{lineHeight: "0.5"}}
                    id="text5699"
                    x="-265.082"
                    y="-125.217"
                    fill="#000"
                    fillOpacity="1"
                    stroke="none"
                    strokeWidth="0.265"
                    fontFamily="sans-serif"
                    fontSize="10.583"
                    fontStyle="normal"
                    fontWeight="normal"
                    letterSpacing="0"
                    transform="rotate(-90)"
                    wordSpacing="0"
                >
                    <tspan id="tspan5697" x="-265.082" y="-125.217" strokeWidth="0.265">
                        <tspan
                            x="-265.082"
                            y="-125.217"
                            style={{lineHeight: "0.5"}}
                            id="tspan5695"
                            strokeWidth="0.265"
                            fontSize="6.35"
                        >
                            84
                        </tspan>
                    </tspan>
                </text>
                <rect
                    id="84"
                    className={`is-clickable ${((carrels[83].status === "RESERVED") || (carrels[83].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                    width="17.278"
                    height="9.217"
                    x="-270.442"
                    y="-130.195"
                    fill="#c6e48b"
                    fillOpacity="0.985"
                    stroke="#040404"
                    strokeWidth="0.265"
                    opacity="0.5"
                    ry="0.561"
                    transform="rotate(-89.639)"
                ></rect>
                <path
                    id="path6594-2"
                    fill="#c6e48b"
                    fillOpacity="0.985"
                    stroke="#040404"
                    strokeDasharray="none"
                    strokeLinecap="butt"
                    strokeLinejoin="miter"
                    strokeMiterlimit="4"
                    strokeWidth="1.613"
                    d="M-122.572 252.255l-.05 17.555"
                ></path>
            </g>
            <g
                id="g6604-4"
                fill="#c6e48b"
                fillOpacity="0.985"
                stroke="#040404"
                strokeOpacity="0.418"
                transform="matrix(.78828 0 0 .78828 349.717 432.847)"
            >
                <text
                    xmlSpace="preserve"
                    style={{lineHeight: "0.5"}}
                    id="text5705"
                    x="-265.105"
                    y="-115.944"
                    fill="#000"
                    fillOpacity="1"
                    stroke="none"
                    strokeWidth="0.265"
                    fontFamily="sans-serif"
                    fontSize="10.583"
                    fontStyle="normal"
                    fontWeight="normal"
                    letterSpacing="0"
                    transform="rotate(-90)"
                    wordSpacing="0"
                >
                    <tspan id="tspan5703" x="-265.105" y="-115.944" strokeWidth="0.265">
                        <tspan
                            x="-265.105"
                            y="-115.944"
                            style={{lineHeight: "0.5"}}
                            id="tspan5701"
                            strokeWidth="0.265"
                            fontSize="6.35"
                        >
                            86
                        </tspan>
                    </tspan>
                </text>
                <rect
                    id="86"
                    className={`is-clickable ${((carrels[85].status === "RESERVED") || (carrels[85].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                    width="17.278"
                    height="9.217"
                    x="-270.425"
                    y="-120.922"
                    fill="#c6e48b"
                    fillOpacity="0.985"
                    stroke="#040404"
                    strokeWidth="0.265"
                    opacity="0.51"
                    ry="0.561"
                    transform="rotate(-89.639)"
                ></rect>
                <text
                    xmlSpace="preserve"
                    style={{lineHeight: "0.5"}}
                    id="text5711"
                    x="-265.011"
                    y="-125.217"
                    fill="#000"
                    fillOpacity="1"
                    stroke="none"
                    strokeWidth="0.265"
                    fontFamily="sans-serif"
                    fontSize="10.583"
                    fontStyle="normal"
                    fontWeight="normal"
                    letterSpacing="0"
                    transform="rotate(-90)"
                    wordSpacing="0"
                >
                    <tspan id="tspan5709" x="-265.011" y="-125.217" strokeWidth="0.265">
                        <tspan
                            x="-265.011"
                            y="-125.217"
                            style={{lineHeight: "0.5"}}
                            id="tspan5707"
                            strokeWidth="0.265"
                            fontSize="6.35"
                        >
                            85
                        </tspan>
                    </tspan>
                </text>
                <rect
                    id="85"
                    className={`is-clickable ${((carrels[84].status === "RESERVED") || (carrels[84].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                    width="17.278"
                    height="9.217"
                    x="-270.442"
                    y="-130.195"
                    fill="#c6e48b"
                    fillOpacity="0.985"
                    stroke="#040404"
                    strokeWidth="0.265"
                    opacity="0.5"
                    ry="0.561"
                    transform="rotate(-89.639)"
                ></rect>
                <path
                    id="path6602-8"
                    fill="#c6e48b"
                    fillOpacity="0.985"
                    stroke="#040404"
                    strokeDasharray="none"
                    strokeLinecap="butt"
                    strokeLinejoin="miter"
                    strokeMiterlimit="4"
                    strokeWidth="1.613"
                    d="M-122.572 252.255l-.05 17.555"
                ></path>
            </g>
            <g
                id="g6612-0"
                fill="#c6e48b"
                fillOpacity="0.985"
                stroke="#040404"
                strokeOpacity="0.418"
                transform="translate(379.58 462.188) scale(.78827)"
            >
                <text
                    xmlSpace="preserve"
                    style={{lineHeight: "0.5"}}
                    id="text5717"
                    x="-265.11"
                    y="-115.943"
                    fill="#000"
                    fillOpacity="1"
                    stroke="none"
                    strokeWidth="0.265"
                    fontFamily="sans-serif"
                    fontSize="10.583"
                    fontStyle="normal"
                    fontWeight="normal"
                    letterSpacing="0"
                    transform="rotate(-90)"
                    wordSpacing="0"
                >
                    <tspan id="tspan5715" x="-265.11" y="-115.943" strokeWidth="0.265">
                        <tspan
                            x="-265.11"
                            y="-115.943"
                            style={{lineHeight: "0.5"}}
                            id="tspan5713"
                            strokeWidth="0.265"
                            fontSize="6.35"
                        >
                            94
                        </tspan>
                    </tspan>
                </text>
                <rect
                    id="94"
                    className={`is-clickable ${((carrels[93].status === "RESERVED") || (carrels[93].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                    width="17.278"
                    height="9.217"
                    x="-270.425"
                    y="-120.922"
                    fill="#c6e48b"
                    fillOpacity="0.985"
                    stroke="#040404"
                    strokeWidth="0.265"
                    opacity="0.5"
                    ry="0.561"
                    transform="rotate(-89.639)"
                ></rect>
                <text
                    xmlSpace="preserve"
                    style={{lineHeight: "0.5"}}
                    id="text5723"
                    x="-265.026"
                    y="-125.217"
                    fill="#000"
                    fillOpacity="1"
                    stroke="none"
                    strokeWidth="0.265"
                    fontFamily="sans-serif"
                    fontSize="10.583"
                    fontStyle="normal"
                    fontWeight="normal"
                    letterSpacing="0"
                    transform="rotate(-90)"
                    wordSpacing="0"
                >
                    <tspan id="tspan5721" x="-265.026" y="-125.217" strokeWidth="0.265">
                        <tspan
                            x="-265.026"
                            y="-125.217"
                            style={{lineHeight: "0.5"}}
                            id="tspan5719"
                            strokeWidth="0.265"
                            fontSize="6.35"
                        >
                            89
                        </tspan>
                    </tspan>
                </text>
                <rect
                    id="89"
                    className={`is-clickable ${((carrels[88].status === "RESERVED") || (carrels[88].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                    width="17.278"
                    height="9.217"
                    x="-270.442"
                    y="-130.195"
                    fill="#c6e48b"
                    fillOpacity="0.985"
                    stroke="#040404"
                    strokeWidth="0.265"
                    opacity="0.5"
                    ry="0.561"
                    transform="rotate(-89.639)"
                ></rect>
                <path
                    id="path6610-8"
                    fill="#c6e48b"
                    fillOpacity="0.985"
                    stroke="#040404"
                    strokeDasharray="none"
                    strokeLinecap="butt"
                    strokeLinejoin="miter"
                    strokeMiterlimit="4"
                    strokeWidth="1.613"
                    d="M-122.572 252.255l-.05 17.555"
                ></path>
            </g>
            <g
                id="g6620-4"
                fill="#c6e48b"
                fillOpacity="0.985"
                stroke="#040404"
                strokeOpacity="0.418"
                transform="matrix(.78828 0 0 .78828 379.581 447.517)"
            >
                <text
                    xmlSpace="preserve"
                    style={{lineHeight: "0.5"}}
                    id="text5729"
                    x="-265.024"
                    y="-115.944"
                    fill="#000"
                    fillOpacity="1"
                    stroke="none"
                    strokeWidth="0.265"
                    fontFamily="sans-serif"
                    fontSize="10.583"
                    fontStyle="normal"
                    fontWeight="normal"
                    letterSpacing="0"
                    transform="rotate(-90)"
                    wordSpacing="0"
                >
                    <tspan id="tspan5727" x="-265.024" y="-115.944" strokeWidth="0.265">
                        <tspan
                            x="-265.024"
                            y="-115.944"
                            style={{lineHeight: "0.5"}}
                            id="tspan5725"
                            strokeWidth="0.265"
                            fontSize="6.35"
                        >
                            93
                        </tspan>
                    </tspan>
                </text>
                <rect
                    id="93"
                    className={`is-clickable ${((carrels[92].status === "RESERVED") || (carrels[92].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                    width="17.278"
                    height="9.217"
                    x="-270.425"
                    y="-120.922"
                    fill="#c6e48b"
                    fillOpacity="0.985"
                    stroke="#040404"
                    strokeWidth="0.265"
                    opacity="0.5"
                    ry="0.561"
                    transform="rotate(-89.639)"
                ></rect>
                <text
                    xmlSpace="preserve"
                    style={{lineHeight: "0.5"}}
                    id="text5735"
                    x="-265.012"
                    y="-125.218"
                    fill="#000"
                    fillOpacity="1"
                    stroke="none"
                    strokeWidth="0.265"
                    fontFamily="sans-serif"
                    fontSize="10.583"
                    fontStyle="normal"
                    fontWeight="normal"
                    letterSpacing="0"
                    transform="rotate(-90)"
                    wordSpacing="0"
                >
                    <tspan id="tspan5733" x="-265.012" y="-125.218" strokeWidth="0.265">
                        <tspan
                            x="-265.012"
                            y="-125.218"
                            style={{lineHeight: "0.5"}}
                            id="tspan5731"
                            strokeWidth="0.265"
                            fontSize="6.35"
                        >
                            90
                        </tspan>
                    </tspan>
                </text>
                <rect
                    id="90"
                    className={`is-clickable ${((carrels[89].status === "RESERVED") || (carrels[89].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                    width="17.278"
                    height="9.217"
                    x="-270.442"
                    y="-130.195"
                    fill="#c6e48b"
                    fillOpacity="0.985"
                    stroke="#040404"
                    strokeWidth="0.265"
                    opacity="0.5"
                    ry="0.561"
                    transform="rotate(-89.639)"
                ></rect>
                <path
                    id="path6618-3"
                    fill="#c6e48b"
                    fillOpacity="0.985"
                    stroke="#040404"
                    strokeDasharray="none"
                    strokeLinecap="butt"
                    strokeLinejoin="miter"
                    strokeMiterlimit="4"
                    strokeWidth="1.613"
                    d="M-122.572 252.255l-.05 17.555"
                ></path>
            </g>
            <g
                id="g6628-0"
                fill="#c6e48b"
                fillOpacity="0.985"
                stroke="#040404"
                strokeOpacity="0.418"
                transform="matrix(.78828 0 0 .78828 379.581 432.847)"
            >
                <text
                    xmlSpace="preserve"
                    style={{lineHeight: "0.5"}}
                    id="text5741"
                    x="-265.057"
                    y="-115.941"
                    fill="#000"
                    fillOpacity="1"
                    stroke="none"
                    strokeWidth="0.265"
                    fontFamily="sans-serif"
                    fontSize="10.583"
                    fontStyle="normal"
                    fontWeight="normal"
                    letterSpacing="0"
                    transform="rotate(-90)"
                    wordSpacing="0"
                >
                    <tspan id="tspan5739" x="-265.057" y="-115.941" strokeWidth="0.265">
                        <tspan
                            x="-265.057"
                            y="-115.941"
                            style={{lineHeight: "0.5"}}
                            id="tspan5737"
                            strokeWidth="0.265"
                            fontSize="6.35"
                        >
                            92
                        </tspan>
                    </tspan>
                </text>
                <rect
                    id="92"
                    className={`is-clickable ${((carrels[91].status === "RESERVED") || (carrels[91].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                    width="17.278"
                    height="9.217"
                    x="-270.425"
                    y="-120.922"
                    fill="#c6e48b"
                    fillOpacity="0.985"
                    stroke="#040404"
                    strokeWidth="0.265"
                    opacity="0.5"
                    ry="0.561"
                    transform="rotate(-89.639)"
                ></rect>
                <text
                    xmlSpace="preserve"
                    style={{lineHeight: "0.5"}}
                    id="text5747"
                    x="-264.883"
                    y="-125.215"
                    fill="#000"
                    fillOpacity="1"
                    stroke="none"
                    strokeWidth="0.265"
                    fontFamily="sans-serif"
                    fontSize="10.583"
                    fontStyle="normal"
                    fontWeight="normal"
                    letterSpacing="0"
                    transform="rotate(-90)"
                    wordSpacing="0"
                >
                    <tspan id="tspan5745" x="-264.883" y="-125.215" strokeWidth="0.265">
                        <tspan
                            x="-264.883"
                            y="-125.215"
                            style={{lineHeight: "0.5"}}
                            id="tspan5743"
                            strokeWidth="0.265"
                            fontSize="6.35"
                        >
                            91
                        </tspan>
                    </tspan>
                </text>
                <rect
                    id="91"
                    className={`is-clickable ${((carrels[90].status === "RESERVED") || (carrels[90].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                    width="17.278"
                    height="9.217"
                    x="-270.442"
                    y="-130.195"
                    fill="#c6e48b"
                    fillOpacity="0.985"
                    stroke="#040404"
                    strokeWidth="0.265"
                    opacity="0.5"
                    ry="0.561"
                    transform="rotate(-89.639)"
                ></rect>
                <path
                    id="path6626-2"
                    fill="#c6e48b"
                    fillOpacity="0.985"
                    stroke="#040404"
                    strokeDasharray="none"
                    strokeLinecap="butt"
                    strokeLinejoin="miter"
                    strokeMiterlimit="4"
                    strokeWidth="1.613"
                    d="M-122.572 252.255l-.05 17.555"
                ></path>
            </g>
            <g
                id="g6636-9"
                fill="#c6e48b"
                fillOpacity="0.985"
                stroke="#040404"
                strokeOpacity="0.418"
                transform="translate(408.877 462.188) scale(.78827)"
            >
                <text
                    xmlSpace="preserve"
                    style={{lineHeight: "0.5"}}
                    id="text5753"
                    x="-267.331"
                    y="-115.946"
                    fill="#000"
                    fillOpacity="1"
                    stroke="none"
                    strokeWidth="0.265"
                    fontFamily="sans-serif"
                    fontSize="10.583"
                    fontStyle="normal"
                    fontWeight="normal"
                    letterSpacing="0"
                    transform="rotate(-90)"
                    wordSpacing="0"
                >
                    <tspan id="tspan5751" x="-267.331" y="-115.946" strokeWidth="0.265">
                        <tspan
                            x="-267.331"
                            y="-115.946"
                            style={{lineHeight: "0.5"}}
                            id="tspan5749"
                            strokeWidth="0.265"
                            fontSize="6.35"
                        >
                            100
                        </tspan>
                    </tspan>
                </text>
                <rect
                    id="100"
                    className={`is-clickable ${((carrels[99].status === "RESERVED") || (carrels[99].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                    width="17.278"
                    height="9.217"
                    x="-270.425"
                    y="-120.922"
                    fill="#c6e48b"
                    fillOpacity="0.985"
                    stroke="#040404"
                    strokeWidth="0.265"
                    opacity="0.5"
                    ry="0.561"
                    transform="rotate(-89.639)"
                ></rect>
                <text
                    xmlSpace="preserve"
                    style={{lineHeight: "0.5"}}
                    id="text5759"
                    x="-264.997"
                    y="-125.218"
                    fill="#000"
                    fillOpacity="1"
                    stroke="none"
                    strokeWidth="0.265"
                    fontFamily="sans-serif"
                    fontSize="10.583"
                    fontStyle="normal"
                    fontWeight="normal"
                    letterSpacing="0"
                    transform="rotate(-90)"
                    wordSpacing="0"
                >
                    <tspan id="tspan5757" x="-264.997" y="-125.218" strokeWidth="0.265">
                        <tspan
                            x="-264.997"
                            y="-125.218"
                            style={{lineHeight: "0.5"}}
                            id="tspan5755"
                            strokeWidth="0.265"
                            fontSize="6.35"
                        >
                            95
                        </tspan>
                    </tspan>
                </text>
                <rect
                    id="95"
                    className={`is-clickable ${((carrels[94].status === "RESERVED") || (carrels[94].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                    width="17.278"
                    height="9.217"
                    x="-270.442"
                    y="-130.195"
                    fill="#c6e48b"
                    fillOpacity="0.985"
                    stroke="#040404"
                    strokeWidth="0.265"
                    opacity="0.5"
                    ry="0.561"
                    transform="rotate(-89.639)"
                ></rect>
                <path
                    id="path6634-7"
                    fill="#c6e48b"
                    fillOpacity="0.985"
                    stroke="#040404"
                    strokeDasharray="none"
                    strokeLinecap="butt"
                    strokeLinejoin="miter"
                    strokeMiterlimit="4"
                    strokeWidth="1.613"
                    d="M-122.572 252.255l-.05 17.555"
                ></path>
            </g>
            <g
                id="g6644-6"
                fill="#c6e48b"
                fillOpacity="0.985"
                stroke="#040404"
                strokeOpacity="0.418"
                transform="matrix(.78828 0 0 .78828 408.877 447.517)"
            >
                <text
                    xmlSpace="preserve"
                    style={{lineHeight: "0.5"}}
                    id="text5765"
                    x="-265.054"
                    y="-115.943"
                    fill="#000"
                    fillOpacity="1"
                    stroke="none"
                    strokeWidth="0.265"
                    fontFamily="sans-serif"
                    fontSize="10.583"
                    fontStyle="normal"
                    fontWeight="normal"
                    letterSpacing="0"
                    transform="rotate(-90)"
                    wordSpacing="0"
                >
                    <tspan id="tspan5763" x="-265.054" y="-115.943" strokeWidth="0.265">
                        <tspan
                            x="-265.054"
                            y="-115.943"
                            style={{lineHeight: "0.5"}}
                            id="tspan5761"
                            strokeWidth="0.265"
                            fontSize="6.35"
                        >
                            99
                        </tspan>
                    </tspan>
                </text>
                <rect
                    id="99"
                    className={`is-clickable ${((carrels[98].status === "RESERVED") || (carrels[98].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                    width="17.278"
                    height="9.217"
                    x="-270.425"
                    y="-120.922"
                    fill="#c6e48b"
                    fillOpacity="0.985"
                    stroke="#040404"
                    strokeWidth="0.265"
                    opacity="0.5"
                    ry="0.561"
                    transform="rotate(-89.639)"
                ></rect>
                <text
                    xmlSpace="preserve"
                    style={{lineHeight: "0.5"}}
                    id="text5771"
                    x="-265.049"
                    y="-125.218"
                    fill="#000"
                    fillOpacity="1"
                    stroke="none"
                    strokeWidth="0.265"
                    fontFamily="sans-serif"
                    fontSize="10.583"
                    fontStyle="normal"
                    fontWeight="normal"
                    letterSpacing="0"
                    transform="rotate(-90)"
                    wordSpacing="0"
                >
                    <tspan id="tspan5769" x="-265.049" y="-125.218" strokeWidth="0.265">
                        <tspan
                            x="-265.049"
                            y="-125.218"
                            style={{lineHeight: "0.5"}}
                            id="tspan5767"
                            strokeWidth="0.265"
                            fontSize="6.35"
                        >
                            96
                        </tspan>
                    </tspan>
                </text>
                <rect
                    id="96"
                    className={`is-clickable ${((carrels[95].status === "RESERVED") || (carrels[95].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                    width="17.278"
                    height="9.217"
                    x="-270.442"
                    y="-130.195"
                    fill="#c6e48b"
                    fillOpacity="0.985"
                    stroke="#040404"
                    strokeWidth="0.265"
                    opacity="0.5"
                    ry="0.561"
                    transform="rotate(-89.639)"
                ></rect>
                <path
                    id="path6642-2"
                    fill="#c6e48b"
                    fillOpacity="0.985"
                    stroke="#040404"
                    strokeDasharray="none"
                    strokeLinecap="butt"
                    strokeLinejoin="miter"
                    strokeMiterlimit="4"
                    strokeWidth="1.613"
                    d="M-122.572 252.255l-.05 17.555"
                ></path>
            </g>
            <g
                id="g6652-3"
                fill="#c6e48b"
                fillOpacity="0.985"
                stroke="#040404"
                strokeOpacity="0.418"
                transform="matrix(.78828 0 0 .78828 408.877 432.847)"
            >
                <text
                    xmlSpace="preserve"
                    style={{lineHeight: "0.5"}}
                    id="text5777"
                    x="-265.074"
                    y="-115.944"
                    fill="#000"
                    fillOpacity="1"
                    stroke="none"
                    strokeWidth="0.265"
                    fontFamily="sans-serif"
                    fontSize="10.583"
                    fontStyle="normal"
                    fontWeight="normal"
                    letterSpacing="0"
                    transform="rotate(-90)"
                    wordSpacing="0"
                >
                    <tspan id="tspan5775" x="-265.074" y="-115.944" strokeWidth="0.265">
                        <tspan
                            x="-265.074"
                            y="-115.944"
                            style={{lineHeight: "0.5"}}
                            id="tspan5773"
                            strokeWidth="0.265"
                            fontSize="6.35"
                        >
                            98
                        </tspan>
                    </tspan>
                </text>
                <rect
                    id="98"
                    className={`is-clickable ${((carrels[97].status === "RESERVED") || (carrels[97].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                    width="17.278"
                    height="9.217"
                    x="-270.425"
                    y="-120.922"
                    fill="#c6e48b"
                    fillOpacity="0.985"
                    stroke="#040404"
                    strokeWidth="0.265"
                    opacity="0.5"
                    ry="0.561"
                    transform="rotate(-89.639)"
                ></rect>
                <text
                    xmlSpace="preserve"
                    style={{lineHeight: "0.5"}}
                    id="text5783"
                    x="-265.021"
                    y="-125.215"
                    fill="#000"
                    fillOpacity="1"
                    stroke="none"
                    strokeWidth="0.265"
                    fontFamily="sans-serif"
                    fontSize="10.583"
                    fontStyle="normal"
                    fontWeight="normal"
                    letterSpacing="0"
                    transform="rotate(-90)"
                    wordSpacing="0"
                >
                    <tspan id="tspan5781" x="-265.021" y="-125.215" strokeWidth="0.265">
                        <tspan
                            x="-265.021"
                            y="-125.215"
                            style={{lineHeight: "0.5"}}
                            id="tspan5779"
                            strokeWidth="0.265"
                            fontSize="6.35"
                        >
                            97
                        </tspan>
                    </tspan>
                </text>
                <rect
                    id="97"
                    className={`is-clickable ${((carrels[96].status === "RESERVED") || (carrels[96].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                    width="17.278"
                    height="9.217"
                    x="-270.442"
                    y="-130.195"
                    fill="#c6e48b"
                    fillOpacity="0.985"
                    stroke="#040404"
                    strokeWidth="0.265"
                    opacity="0.5"
                    ry="0.561"
                    transform="rotate(-89.639)"
                ></rect>
                <path
                    id="path6650-8"
                    fill="#c6e48b"
                    fillOpacity="0.985"
                    stroke="#040404"
                    strokeDasharray="none"
                    strokeLinecap="butt"
                    strokeLinejoin="miter"
                    strokeMiterlimit="4"
                    strokeWidth="1.613"
                    d="M-122.572 252.255l-.05 17.555"
                ></path>
            </g>
            <g
                id="g6668-9"
                fill="#c6e48b"
                fillOpacity="0.985"
                stroke="#040404"
                strokeOpacity="0.418"
                transform="matrix(.78828 0 0 .78828 438.669 447.517)"
            >
                <text
                    xmlSpace="preserve"
                    style={{lineHeight: "0.5"}}
                    id="text5789"
                    x="-267.316"
                    y="-115.946"
                    fill="#000"
                    fillOpacity="1"
                    stroke="none"
                    strokeWidth="0.265"
                    fontFamily="sans-serif"
                    fontSize="10.583"
                    fontStyle="normal"
                    fontWeight="normal"
                    letterSpacing="0"
                    transform="rotate(-90)"
                    wordSpacing="0"
                >
                    <tspan id="tspan5787" x="-267.316" y="-115.946" strokeWidth="0.265">
                        <tspan
                            x="-267.316"
                            y="-115.946"
                            style={{lineHeight: "0.5"}}
                            id="tspan5785"
                            strokeWidth="0.265"
                            fontSize="6.35"
                        >
                            105
                        </tspan>
                    </tspan>
                </text>
                <rect
                    id="105"
                    className={`is-clickable ${((carrels[104].status === "RESERVED") || (carrels[104].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                    width="17.278"
                    height="9.217"
                    x="-270.425"
                    y="-120.922"
                    fill="#c6e48b"
                    fillOpacity="0.985"
                    stroke="#040404"
                    strokeWidth="0.265"
                    opacity="0.5"
                    ry="0.561"
                    transform="rotate(-89.639)"
                ></rect>
                <text
                    xmlSpace="preserve"
                    style={{lineHeight: "0.5"}}
                    id="text5795"
                    x="-267.293"
                    y="-125.217"
                    fill="#000"
                    fillOpacity="1"
                    stroke="none"
                    strokeWidth="0.265"
                    fontFamily="sans-serif"
                    fontSize="10.583"
                    fontStyle="normal"
                    fontWeight="normal"
                    letterSpacing="0"
                    transform="rotate(-90)"
                    wordSpacing="0"
                >
                    <tspan id="tspan5793" x="-267.293" y="-125.217" strokeWidth="0.265">
                        <tspan
                            x="-267.293"
                            y="-125.217"
                            style={{lineHeight: "0.5"}}
                            id="tspan5791"
                            strokeWidth="0.265"
                            fontSize="6.35"
                        >
                            102
                        </tspan>
                    </tspan>
                </text>
                <rect
                    id="102"
                    className={`is-clickable ${((carrels[101].status === "RESERVED") || (carrels[101].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                    width="17.278"
                    height="9.217"
                    x="-270.442"
                    y="-130.195"
                    fill="#c6e48b"
                    fillOpacity="0.985"
                    stroke="#040404"
                    strokeWidth="0.265"
                    opacity="0.5"
                    ry="0.561"
                    transform="rotate(-89.639)"
                ></rect>
                <path
                    id="path6666-2"
                    fill="#c6e48b"
                    fillOpacity="0.985"
                    stroke="#040404"
                    strokeDasharray="none"
                    strokeLinecap="butt"
                    strokeLinejoin="miter"
                    strokeMiterlimit="4"
                    strokeWidth="1.613"
                    d="M-122.572 252.255l-.05 17.555"
                ></path>
            </g>
            <g
                id="g6676-9"
                fill="#c6e48b"
                fillOpacity="0.985"
                stroke="#040404"
                strokeOpacity="0.418"
                transform="matrix(.78828 0 0 .78828 438.669 432.847)"
            >
                <text
                    xmlSpace="preserve"
                    style={{lineHeight: "0.5"}}
                    id="text5803"
                    x="-267.387"
                    y="-115.946"
                    fill="#000"
                    fillOpacity="1"
                    stroke="none"
                    strokeWidth="0.265"
                    fontFamily="sans-serif"
                    fontSize="10.583"
                    fontStyle="normal"
                    fontWeight="normal"
                    letterSpacing="0"
                    transform="rotate(-90)"
                    wordSpacing="0"
                >
                    <tspan id="tspan5801" x="-267.387" y="-115.946" strokeWidth="0.265">
                        <tspan
                            x="-267.387"
                            y="-115.946"
                            style={{lineHeight: "0.5"}}
                            id="tspan5797"
                            strokeWidth="0.265"
                            fontSize="6.35"
                        >
                            104
                        </tspan>
                    </tspan>
                </text>
                <rect
                    id="104"
                    className={`is-clickable ${((carrels[103].status === "RESERVED") || (carrels[103].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                    width="17.278"
                    height="9.217"
                    x="-270.425"
                    y="-120.922"
                    fill="#c6e48b"
                    fillOpacity="0.985"
                    stroke="#040404"
                    strokeWidth="0.265"
                    opacity="0.5"
                    ry="0.561"
                    transform="rotate(-89.639)"
                ></rect>
                <text
                    xmlSpace="preserve"
                    style={{lineHeight: "0.5"}}
                    id="text5809"
                    x="-267.26"
                    y="-125.217"
                    fill="#000"
                    fillOpacity="1"
                    stroke="none"
                    strokeWidth="0.265"
                    fontFamily="sans-serif"
                    fontSize="10.583"
                    fontStyle="normal"
                    fontWeight="normal"
                    letterSpacing="0"
                    transform="rotate(-90)"
                    wordSpacing="0"
                >
                    <tspan id="tspan5807" x="-267.26" y="-125.217" strokeWidth="0.265">
                        <tspan
                            x="-267.26"
                            y="-125.217"
                            style={{lineHeight: "0.5"}}
                            id="tspan5805"
                            strokeWidth="0.265"
                            fontSize="6.35"
                        >
                            103
                        </tspan>
                    </tspan>
                </text>
                <rect
                    id="103"
                    className={`is-clickable ${((carrels[102].status === "RESERVED") || (carrels[102].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                    width="17.278"
                    height="9.217"
                    x="-270.442"
                    y="-130.195"
                    fill="#c6e48b"
                    fillOpacity="0.985"
                    stroke="#040404"
                    strokeWidth="0.265"
                    opacity="0.5"
                    ry="0.561"
                    transform="rotate(-89.639)"
                ></rect>
                <path
                    id="path6674-3"
                    fill="#c6e48b"
                    fillOpacity="0.985"
                    stroke="#040404"
                    strokeDasharray="none"
                    strokeLinecap="butt"
                    strokeLinejoin="miter"
                    strokeMiterlimit="4"
                    strokeWidth="1.613"
                    d="M-122.572 252.255l-.05 17.555"
                ></path>
            </g>
            <g
                id="g6855-5"
                fill="#c6e48b"
                fillOpacity="0.985"
                stroke="#040404"
                strokeOpacity="0.418"
                transform="translate(438.668 462.188) scale(.78827)"
            >
                <text
                    xmlSpace="preserve"
                    style={{lineHeight: "0.5"}}
                    id="text5815"
                    x="-267.368"
                    y="-115.946"
                    fill="#000"
                    fillOpacity="1"
                    stroke="none"
                    strokeWidth="0.265"
                    fontFamily="sans-serif"
                    fontSize="10.583"
                    fontStyle="normal"
                    fontWeight="normal"
                    letterSpacing="0"
                    transform="rotate(-90)"
                    wordSpacing="0"
                >
                    <tspan id="tspan5813" x="-267.368" y="-115.946" strokeWidth="0.265">
                        <tspan
                            x="-267.368"
                            y="-115.946"
                            style={{lineHeight: "0.5"}}
                            id="tspan5811"
                            strokeWidth="0.265"
                            fontSize="6.35"
                        >
                            106
                        </tspan>
                    </tspan>
                </text>
                <rect
                    id="106"
                    className={`is-clickable ${((carrels[105].status === "RESERVED") || (carrels[105].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                    width="17.278"
                    height="9.217"
                    x="-270.425"
                    y="-120.922"
                    fill="#c6e48b"
                    fillOpacity="0.985"
                    stroke="#040404"
                    strokeWidth="0.265"
                    opacity="0.5"
                    ry="0.561"
                    transform="rotate(-89.639)"
                ></rect>
                <text
                    xmlSpace="preserve"
                    style={{lineHeight: "0.5"}}
                    id="text5821"
                    x="-267.161"
                    y="-125.218"
                    fill="#000"
                    fillOpacity="1"
                    stroke="none"
                    strokeWidth="0.265"
                    fontFamily="sans-serif"
                    fontSize="10.583"
                    fontStyle="normal"
                    fontWeight="normal"
                    letterSpacing="0"
                    transform="rotate(-90)"
                    wordSpacing="0"
                >
                    <tspan id="tspan5819" x="-267.161" y="-125.218" strokeWidth="0.265">
                        <tspan
                            x="-267.161"
                            y="-125.218"
                            style={{lineHeight: "0.5"}}
                            id="tspan5817"
                            strokeWidth="0.265"
                            fontSize="6.35"
                        >
                            101
                        </tspan>
                    </tspan>
                </text>
                <rect
                    id="101"
                    className={`is-clickable ${((carrels[100].status === "RESERVED") || (carrels[100].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                    width="17.278"
                    height="9.217"
                    x="-270.442"
                    y="-130.195"
                    fill="#c6e48b"
                    fillOpacity="0.985"
                    stroke="#040404"
                    strokeWidth="0.265"
                    opacity="0.5"
                    ry="0.561"
                    transform="rotate(-89.639)"
                ></rect>
                <path
                    id="path6853-9"
                    fill="#c6e48b"
                    fillOpacity="0.985"
                    stroke="#040404"
                    strokeDasharray="none"
                    strokeLinecap="butt"
                    strokeLinejoin="miter"
                    strokeMiterlimit="4"
                    strokeWidth="1.613"
                    d="M-122.572 252.255l-.05 17.555"
                ></path>
            </g>
            <g
                id="g7817-7"
                fill="#c6e48b"
                fillOpacity="0.985"
                strokeOpacity="0.418"
                transform="translate(-1767.474 -858.537) scale(1.25234)"
            >
                <g
                    id="g6708-1"
                    stroke="#040404"
                    transform="matrix(.62944 0 0 .62944 1795.218 1052.598)"
                >
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text5477"
                        x="-267.202"
                        y="-115.896"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan5475"
                            x="-267.202"
                            y="-115.896"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.202"
                                y="-115.896"
                                style={{lineHeight: "0.5"}}
                                id="tspan5473"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                121
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="121"
                        className={`is-clickable ${((carrels[120].status === "RESERVED") || (carrels[120].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text5483"
                        x="-267.299"
                        y="-125.218"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan5481"
                            x="-267.299"
                            y="-125.218"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.299"
                                y="-125.218"
                                style={{lineHeight: "0.5"}}
                                id="tspan5479"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                107
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="107"
                        className={`is-clickable ${((carrels[106].status === "RESERVED") || (carrels[106].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path6706-6"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g
                    id="g6716-0"
                    stroke="#040404"
                    transform="translate(1795.218 1040.883) scale(.62944)"
                >
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text5489"
                        x="-267.331"
                        y="-115.944"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan5487"
                            x="-267.331"
                            y="-115.944"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.331"
                                y="-115.944"
                                style={{lineHeight: "0.5"}}
                                id="tspan5485"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                120
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="120"
                        className={`is-clickable ${((carrels[119].status === "RESERVED") || (carrels[119].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text5495"
                        x="-267.31"
                        y="-125.217"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan5493"
                            x="-267.31"
                            y="-125.217"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.31"
                                y="-125.217"
                                style={{lineHeight: "0.5"}}
                                id="tspan5491"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                108
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="108"
                        className={`is-clickable ${((carrels[107].status === "RESERVED") || (carrels[107].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path6714-6"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g
                    id="g6724-3"
                    stroke="#040404"
                    transform="translate(1795.218 1029.17) scale(.62944)"
                >
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text5501"
                        x="-267.331"
                        y="-115.943"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan5499"
                            x="-267.331"
                            y="-115.943"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.331"
                                y="-115.943"
                                style={{lineHeight: "0.5"}}
                                id="tspan5497"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                119
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="119"
                        className={`is-clickable ${((carrels[118].status === "RESERVED") || (carrels[118].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text5507"
                        x="-267.289"
                        y="-125.218"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan5505"
                            x="-267.289"
                            y="-125.218"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.289"
                                y="-125.218"
                                style={{lineHeight: "0.5"}}
                                id="tspan5503"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                109
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="109"
                        className={`is-clickable ${((carrels[108].status === "RESERVED") || (carrels[108].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path6722-6"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g
                    id="g6732-6"
                    stroke="#040404"
                    transform="matrix(.62944 0 0 .62944 1795.386 1013.539)"
                >
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text5513"
                        x="-267.351"
                        y="-115.944"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan5511"
                            x="-267.351"
                            y="-115.944"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.351"
                                y="-115.944"
                                style={{lineHeight: "0.5"}}
                                id="tspan5509"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                118
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="118"
                        className={`is-clickable ${((carrels[117].status === "RESERVED") || (carrels[117].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text5519"
                        x="-267.289"
                        y="-125.218"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan5517"
                            x="-267.289"
                            y="-125.218"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.289"
                                y="-125.218"
                                style={{lineHeight: "0.5"}}
                                id="tspan5515"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                110
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="110"
                        className={`is-clickable ${((carrels[109].status === "RESERVED") || (carrels[109].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path6730-7"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g
                    id="g6740-4"
                    stroke="#040404"
                    transform="translate(1795.386 1001.905) scale(.62944)"
                >
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text5525"
                        x="-267.34"
                        y="-115.937"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan5523"
                            x="-267.34"
                            y="-115.937"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.34"
                                y="-115.937"
                                style={{lineHeight: "0.5"}}
                                id="tspan5521"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                117
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="117"
                        className={`is-clickable ${((carrels[116].status === "RESERVED") || (carrels[116].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text5531"
                        x="-267.161"
                        y="-125.209"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan5529"
                            x="-267.161"
                            y="-125.209"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.161"
                                y="-125.209"
                                style={{lineHeight: "0.5"}}
                                id="tspan5527"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                111
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="111"
                        className={`is-clickable ${((carrels[110].status === "RESERVED") || (carrels[110].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path6738-3"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g id="g6880-2" transform="translate(.039 .075)">
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text5537"
                        x="-1169.943"
                        y="1699.484"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.168"
                        fontFamily="sans-serif"
                        fontSize="6.725"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="matrix(0 -.99004 1.01006 0 0 0)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan5535"
                            x="-1169.943"
                            y="1699.484"
                            strokeWidth="0.168"
                        >
                            <tspan
                                x="-1169.943"
                                y="1699.484"
                                style={{lineHeight: "0.5"}}
                                id="tspan5533"
                                strokeWidth="0.168"
                                fontSize="4.035"
                            >
                                112
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="112"
                        className={`is-clickable ${((carrels[111].status === "RESERVED") || (carrels[111].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="10.875"
                        height="5.802"
                        x="-1149.059"
                        y="1719.6"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.167"
                        opacity="0.5"
                        ry="0.353"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path6746-6"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.015"
                        d="M1718.195 1148.93l-.032 11.049"
                    ></path>
                </g>
                <g
                    id="g6756-1"
                    stroke="#040404"
                    transform="translate(1795.386 978.638) scale(.62944)"
                >
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text5543"
                        x="-267.368"
                        y="-115.949"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan5541"
                            x="-267.368"
                            y="-115.949"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.368"
                                y="-115.949"
                                style={{lineHeight: "0.5"}}
                                id="tspan5539"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                116
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="116"
                        className={`is-clickable ${((carrels[115].status === "RESERVED") || (carrels[115].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text5549"
                        x="-267.26"
                        y="-125.217"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan5547"
                            x="-267.26"
                            y="-125.217"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.26"
                                y="-125.217"
                                style={{lineHeight: "0.5"}}
                                id="tspan5545"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                113
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="113"
                        className={`is-clickable ${((carrels[112].status === "RESERVED") || (carrels[112].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path6754-6"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g
                    id="g6764-0"
                    stroke="#040404"
                    transform="translate(1795.386 967.005) scale(.62944)"
                >
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text5555"
                        x="-267.316"
                        y="-115.985"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan5553"
                            x="-267.316"
                            y="-115.985"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.316"
                                y="-115.985"
                                style={{lineHeight: "0.5"}}
                                id="tspan5551"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                115
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="115"
                        className={`is-clickable ${((carrels[114].status === "RESERVED") || (carrels[114].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text5561"
                        x="-267.345"
                        y="-125.209"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan5559"
                            x="-267.345"
                            y="-125.209"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.345"
                                y="-125.209"
                                style={{lineHeight: "0.5"}}
                                id="tspan5557"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                114
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="114"
                        className={`is-clickable ${((carrels[113].status === "RESERVED") || (carrels[113].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path6762-7"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g
                    id="g6791-2"
                    stroke="#040404"
                    transform="matrix(.62944 0 0 .62944 1819.163 1052.466)"
                >
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text5567"
                        x="-267.34"
                        y="-115.944"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan5565"
                            x="-267.34"
                            y="-115.944"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.34"
                                y="-115.944"
                                style={{lineHeight: "0.5"}}
                                id="tspan5563"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                137
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="137"
                        className={`is-clickable ${((carrels[136].status === "RESERVED") || (carrels[136].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text5573"
                        x="-267.293"
                        y="-125.169"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan5571"
                            x="-267.293"
                            y="-125.169"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.293"
                                y="-125.169"
                                style={{lineHeight: "0.5"}}
                                id="tspan5569"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                122
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="122"
                        className={`is-clickable ${((carrels[121].status === "RESERVED") || (carrels[121].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path6789-3"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g
                    id="g6799-4"
                    stroke="#040404"
                    transform="translate(1819.163 1040.751) scale(.62944)"
                >
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text5579"
                        x="-267.368"
                        y="-115.944"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan5577"
                            x="-267.368"
                            y="-115.944"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.368"
                                y="-115.944"
                                style={{lineHeight: "0.5"}}
                                id="tspan5575"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                136
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="136"
                        className={`is-clickable ${((carrels[135].status === "RESERVED") || (carrels[135].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text5585"
                        x="-267.26"
                        y="-125.217"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan5583"
                            x="-267.26"
                            y="-125.217"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.26"
                                y="-125.217"
                                style={{lineHeight: "0.5"}}
                                id="tspan5581"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                123
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="123"
                        className={`is-clickable ${((carrels[122].status === "RESERVED") || (carrels[122].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path6797-8"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g
                    id="g6807-4"
                    stroke="#040404"
                    transform="translate(1819.163 1029.037) scale(.62944)"
                >
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text5591"
                        x="-267.316"
                        y="-115.944"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan5589"
                            x="-267.316"
                            y="-115.944"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.316"
                                y="-115.944"
                                style={{lineHeight: "0.5"}}
                                id="tspan5587"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                135
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="135"
                        className={`is-clickable ${((carrels[134].status === "RESERVED") || (carrels[134].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text5597"
                        x="-267.345"
                        y="-125.169"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan5595"
                            x="-267.345"
                            y="-125.169"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.345"
                                y="-125.169"
                                style={{lineHeight: "0.5"}}
                                id="tspan5593"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                124
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="124"
                        className={`is-clickable ${((carrels[123].status === "RESERVED") || (carrels[123].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path6805-0"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g
                    id="g6823-0"
                    stroke="#040404"
                    transform="matrix(.62944 0 0 .62944 1819.198 1013.539)"
                >
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text5603"
                        x="-267.387"
                        y="-115.944"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan5601"
                            x="-267.387"
                            y="-115.944"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.387"
                                y="-115.944"
                                style={{lineHeight: "0.5"}}
                                id="tspan5599"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                134
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="134"
                        className={`is-clickable ${((carrels[133].status === "RESERVED") || (carrels[133].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text5609"
                        x="-267.274"
                        y="-125.217"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan5607"
                            x="-267.274"
                            y="-125.217"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.274"
                                y="-125.217"
                                style={{lineHeight: "0.5"}}
                                id="tspan5605"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                125
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="125"
                        className={`is-clickable ${((carrels[124].status === "RESERVED") || (carrels[124].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path6821-3"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g
                    id="g6831-5"
                    stroke="#040404"
                    transform="translate(1819.198 1001.824) scale(.62944)"
                >
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text5615"
                        x="-267.302"
                        y="-115.944"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan5613"
                            x="-267.302"
                            y="-115.944"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.302"
                                y="-115.944"
                                style={{lineHeight: "0.5"}}
                                id="tspan5611"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                133
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="133"
                        className={`is-clickable ${((carrels[132].status === "RESERVED") || (carrels[132].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text5621"
                        x="-267.327"
                        y="-125.217"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan5619"
                            x="-267.327"
                            y="-125.217"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.327"
                                y="-125.217"
                                style={{lineHeight: "0.5"}}
                                id="tspan5617"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                126
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="126"
                        className={`is-clickable ${((carrels[125].status === "RESERVED") || (carrels[125].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path6829-3"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g
                    id="g6839-6"
                    stroke="#040404"
                    transform="matrix(.62944 0 0 .62944 1819.198 978.879)"
                >
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text5627"
                        x="-267.334"
                        y="-115.944"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan5625"
                            x="-267.334"
                            y="-115.944"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.334"
                                y="-115.944"
                                style={{lineHeight: "0.5"}}
                                id="tspan5623"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                132
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="132"
                        className={`is-clickable ${((carrels[131].status === "RESERVED") || (carrels[131].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text5633"
                        x="-267.299"
                        y="-125.169"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan5631"
                            x="-267.299"
                            y="-125.169"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.299"
                                y="-125.169"
                                style={{lineHeight: "0.5"}}
                                id="tspan5629"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                127
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="127"
                        className={`is-clickable ${((carrels[126].status === "RESERVED") || (carrels[126].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path6837-5"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g
                    id="g6847-1"
                    stroke="#040404"
                    transform="translate(1819.198 967.164) scale(.62944)"
                >
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text5639"
                        x="-267.297"
                        y="-115.944"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan5637"
                            x="-267.297"
                            y="-115.944"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.297"
                                y="-115.944"
                                style={{lineHeight: "0.5"}}
                                id="tspan5635"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                131
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="131"
                        className={`is-clickable ${((carrels[130].status === "RESERVED") || (carrels[130].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.52"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text5645"
                        x="-267.31"
                        y="-125.217"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan5643"
                            x="-267.31"
                            y="-125.217"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.31"
                                y="-125.217"
                                style={{lineHeight: "0.5"}}
                                id="tspan5641"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                128
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="128"
                        className={`is-clickable ${((carrels[127].status === "RESERVED") || (carrels[127].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path6845-2"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g id="g6870-3" transform="translate(.167 -11.642)">
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text5651"
                        x="-1119.378"
                        y="1745.57"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.167"
                        fontFamily="sans-serif"
                        fontSize="6.663"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="matrix(0 -.99972 1.00028 0 0 0)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan5649"
                            x="-1119.378"
                            y="1745.57"
                            strokeWidth="0.167"
                        >
                            <tspan
                                x="-1119.378"
                                y="1745.57"
                                style={{lineHeight: "0.5"}}
                                id="tspan5647"
                                strokeWidth="0.167"
                                fontSize="3.998"
                            >
                                129
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="129"
                        className={`is-clickable ${((carrels[128].status === "RESERVED") || (carrels[128].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="10.875"
                        height="5.802"
                        x="-1109.534"
                        y="1748.873"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.167"
                        opacity="0.5"
                        ry="0.353"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path6861-7"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.015"
                        d="M1741.879 1109.536l-.032 11.05"
                    ></path>
                </g>
                <g id="g6876-6" transform="translate(.167 .265)">
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text5657"
                        x="-1118.512"
                        y="1746.657"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.166"
                        fontFamily="sans-serif"
                        fontSize="6.659"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="matrix(0 -1.00037 .99963 0 0 0)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan5655"
                            x="-1118.512"
                            y="1746.657"
                            strokeWidth="0.166"
                        >
                            <tspan
                                x="-1118.512"
                                y="1746.657"
                                style={{lineHeight: "0.5"}}
                                id="tspan5653"
                                strokeWidth="0.166"
                                fontSize="3.995"
                            >
                                130
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="130"
                        className={`is-clickable ${((carrels[129].status === "RESERVED") || (carrels[129].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="10.875"
                        height="5.802"
                        x="-1109.441"
                        y="1748.836"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.167"
                        opacity="0.5"
                        ry="0.353"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path6874-4"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.015"
                        d="M1741.879 1109.496l-.032 11.05"
                    ></path>
                </g>
            </g>
            <g
                id="g7898-7"
                fill="#c6e48b"
                fillOpacity="0.985"
                strokeOpacity="0.418"
                transform="translate(-1767.474 -858.537) scale(1.25234)"
            >
                <g
                    id="g6888-5"
                    stroke="#040404"
                    transform="matrix(.62944 0 0 .62944 1842.843 1052.333)"
                >
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text5243"
                        x="-267.34"
                        y="-115.985"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan5241"
                            x="-267.34"
                            y="-115.985"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.34"
                                y="-115.985"
                                style={{lineHeight: "0.5"}}
                                id="tspan5239"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                157
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="157"
                        className={`is-clickable ${((carrels[156].status === "RESERVED") || (carrels[156].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text5249"
                        x="-267.31"
                        y="-125.217"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan5247"
                            x="-267.31"
                            y="-125.217"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.31"
                                y="-125.217"
                                style={{lineHeight: "0.5"}}
                                id="tspan5245"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                138
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="138"
                        className={`is-clickable ${((carrels[137].status === "RESERVED") || (carrels[137].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path6886-2"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g
                    id="g6896-0"
                    stroke="#040404"
                    transform="translate(1842.843 1040.619) scale(.62944)"
                >
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text5255"
                        x="-267.368"
                        y="-115.949"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan5253"
                            x="-267.368"
                            y="-115.949"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.368"
                                y="-115.949"
                                style={{lineHeight: "0.5"}}
                                id="tspan5251"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                156
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="156"
                        className={`is-clickable ${((carrels[155].status === "RESERVED") || (carrels[155].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text5261"
                        x="-267.289"
                        y="-125.217"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan5259"
                            x="-267.289"
                            y="-125.217"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.289"
                                y="-125.217"
                                style={{lineHeight: "0.5"}}
                                id="tspan5257"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                139
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="139"
                        className={`is-clickable ${((carrels[138].status === "RESERVED") || (carrels[138].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path6894-2"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g
                    id="g6904-8"
                    stroke="#040404"
                    transform="translate(1842.843 1028.905) scale(.62944)"
                >
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text5267"
                        x="-267.316"
                        y="-115.985"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan5265"
                            x="-267.316"
                            y="-115.985"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.316"
                                y="-115.985"
                                style={{lineHeight: "0.5"}}
                                id="tspan5263"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                155
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="155"
                        className={`is-clickable ${((carrels[154].status === "RESERVED") || (carrels[154].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text5273"
                        x="-267.289"
                        y="-125.218"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan5271"
                            x="-267.289"
                            y="-125.218"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.289"
                                y="-125.218"
                                style={{lineHeight: "0.5"}}
                                id="tspan5269"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                140
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="140"
                        className={`is-clickable ${((carrels[139].status === "RESERVED") || (carrels[139].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path6902-6"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g
                    id="g6912-9"
                    stroke="#040404"
                    transform="matrix(.62944 0 0 .62944 1842.843 1013.704)"
                >
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text5279"
                        x="-267.387"
                        y="-115.985"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan5277"
                            x="-267.387"
                            y="-115.985"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.387"
                                y="-115.985"
                                style={{lineHeight: "0.5"}}
                                id="tspan5275"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                154
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="154"
                        className={`is-clickable ${((carrels[153].status === "RESERVED") || (carrels[153].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text5285"
                        x="-267.161"
                        y="-125.209"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan5283"
                            x="-267.161"
                            y="-125.209"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.161"
                                y="-125.209"
                                style={{lineHeight: "0.5"}}
                                id="tspan5281"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                141
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="141"
                        className={`is-clickable ${((carrels[140].status === "RESERVED") || (carrels[140].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path6910-7"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g
                    id="g6920-8"
                    stroke="#040404"
                    transform="translate(1842.843 1002.026) scale(.62944)"
                >
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text5291"
                        x="-267.302"
                        y="-115.944"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan5289"
                            x="-267.302"
                            y="-115.944"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.302"
                                y="-115.944"
                                style={{lineHeight: "0.5"}}
                                id="tspan5287"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                153
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="153"
                        className={`is-clickable ${((carrels[152].status === "RESERVED") || (carrels[152].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text5297"
                        x="-267.293"
                        y="-125.169"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan5295"
                            x="-267.293"
                            y="-125.169"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.293"
                                y="-125.169"
                                style={{lineHeight: "0.5"}}
                                id="tspan5293"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                142
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="142"
                        className={`is-clickable ${((carrels[141].status === "RESERVED") || (carrels[141].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path6918-5"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g
                    id="g6928-2"
                    stroke="#040404"
                    transform="translate(1842.843 990.348) scale(.62944)"
                >
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text5303"
                        x="-267.334"
                        y="-115.944"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan5301"
                            x="-267.334"
                            y="-115.944"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.334"
                                y="-115.944"
                                style={{lineHeight: "0.5"}}
                                id="tspan5299"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                152
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="152"
                        className={`is-clickable ${((carrels[151].status === "RESERVED") || (carrels[151].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text5309"
                        x="-267.26"
                        y="-125.217"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan5307"
                            x="-267.26"
                            y="-125.217"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.26"
                                y="-125.217"
                                style={{lineHeight: "0.5"}}
                                id="tspan5305"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                143
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="143"
                        className={`is-clickable ${((carrels[142].status === "RESERVED") || (carrels[142].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path6926-1"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g
                    id="g6936-1"
                    stroke="#040404"
                    transform="translate(1842.843 978.67) scale(.62944)"
                >
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text5315"
                        x="-267.202"
                        y="-115.985"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan5313"
                            x="-267.202"
                            y="-115.985"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.202"
                                y="-115.985"
                                style={{lineHeight: "0.5"}}
                                id="tspan5311"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                151
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="151"
                        className={`is-clickable ${((carrels[150].status === "RESERVED") || (carrels[150].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text5321"
                        x="-267.345"
                        y="-125.209"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan5319"
                            x="-267.345"
                            y="-125.209"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.345"
                                y="-125.209"
                                style={{lineHeight: "0.5"}}
                                id="tspan5317"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                144
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="144"
                        className={`is-clickable ${((carrels[143].status === "RESERVED") || (carrels[143].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path6934-2"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g
                    id="g6944-7"
                    stroke="#040404"
                    transform="translate(1842.843 966.992) scale(.62944)"
                >
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text5327"
                        x="-267.331"
                        y="-115.946"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan5325"
                            x="-267.331"
                            y="-115.946"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.331"
                                y="-115.946"
                                style={{lineHeight: "0.5"}}
                                id="tspan5323"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                150
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="150"
                        className={`is-clickable ${((carrels[149].status === "RESERVED") || (carrels[149].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text5333"
                        x="-267.274"
                        y="-125.257"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan5331"
                            x="-267.274"
                            y="-125.257"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.274"
                                y="-125.257"
                                style={{lineHeight: "0.5"}}
                                id="tspan5329"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                145
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="145"
                        className={`is-clickable ${((carrels[144].status === "RESERVED") || (carrels[144].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path6942-2"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g
                    id="g6952-7"
                    stroke="#040404"
                    transform="translate(1842.843 951.454) scale(.62944)"
                >
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text5339"
                        x="-267.331"
                        y="-115.943"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan5337"
                            x="-267.331"
                            y="-115.943"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.331"
                                y="-115.943"
                                style={{lineHeight: "0.5"}}
                                id="tspan5335"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                149
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="149"
                        className={`is-clickable ${((carrels[148].status === "RESERVED") || (carrels[148].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text5345"
                        x="-267.327"
                        y="-125.222"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan5343"
                            x="-267.327"
                            y="-125.222"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.327"
                                y="-125.222"
                                style={{lineHeight: "0.5"}}
                                id="tspan5341"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                146
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="146"
                        className={`is-clickable ${((carrels[145].status === "RESERVED") || (carrels[145].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path6950-8"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g
                    id="g6960-3"
                    stroke="#040404"
                    transform="translate(1842.843 939.74) scale(.62944)"
                >
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text5351"
                        x="-267.351"
                        y="-115.944"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan5349"
                            x="-267.351"
                            y="-115.944"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.351"
                                y="-115.944"
                                style={{lineHeight: "0.5"}}
                                id="tspan5347"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                148
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="148"
                        className={`is-clickable ${((carrels[147].status === "RESERVED") || (carrels[147].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text5357"
                        x="-267.299"
                        y="-125.209"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan5355"
                            x="-267.299"
                            y="-125.209"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.299"
                                y="-125.209"
                                style={{lineHeight: "0.5"}}
                                id="tspan5353"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                147
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="147"
                        className={`is-clickable ${((carrels[146].status === "RESERVED") || (carrels[146].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path6958-6"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g
                    id="g6968-9"
                    stroke="#040404"
                    transform="matrix(.62944 0 0 .62944 1865.068 1052.069)"
                >
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text5363"
                        x="-267.368"
                        y="-115.949"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan5361"
                            x="-267.368"
                            y="-115.949"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.368"
                                y="-115.949"
                                style={{lineHeight: "0.5"}}
                                id="tspan5359"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                176
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="176"
                        className={`is-clickable ${((carrels[175].status === "RESERVED") || (carrels[175].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text5369"
                        x="-267.31"
                        y="-125.217"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan5367"
                            x="-267.31"
                            y="-125.217"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.31"
                                y="-125.217"
                                style={{lineHeight: "0.5"}}
                                id="tspan5365"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                158
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="158"
                        className={`is-clickable ${((carrels[157].status === "RESERVED") || (carrels[157].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path6966-1"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g
                    id="g6976-5"
                    stroke="#040404"
                    transform="translate(1865.068 1040.354) scale(.62944)"
                >
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text5375"
                        x="-267.316"
                        y="-115.985"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan5373"
                            x="-267.316"
                            y="-115.985"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.316"
                                y="-115.985"
                                style={{lineHeight: "0.5"}}
                                id="tspan5371"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                175
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="175"
                        className={`is-clickable ${((carrels[174].status === "RESERVED") || (carrels[174].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text5381"
                        x="-267.289"
                        y="-125.218"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan5379"
                            x="-267.289"
                            y="-125.218"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.289"
                                y="-125.218"
                                style={{lineHeight: "0.5"}}
                                id="tspan5377"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                159
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="159"
                        className={`is-clickable ${((carrels[158].status === "RESERVED") || (carrels[158].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path6974-6"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g
                    id="g6984-2"
                    stroke="#040404"
                    transform="translate(1865.068 1028.64) scale(.62944)"
                >
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text5387"
                        x="-267.387"
                        y="-115.937"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan5385"
                            x="-267.387"
                            y="-115.937"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.387"
                                y="-115.937"
                                style={{lineHeight: "0.5"}}
                                id="tspan5383"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                174
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="174"
                        className={`is-clickable ${((carrels[173].status === "RESERVED") || (carrels[173].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text5393"
                        x="-267.289"
                        y="-125.218"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan5391"
                            x="-267.289"
                            y="-125.218"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.289"
                                y="-125.218"
                                style={{lineHeight: "0.5"}}
                                id="tspan5389"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                160
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="160"
                        className={`is-clickable ${((carrels[159].status === "RESERVED") || (carrels[159].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path6982-0"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g
                    id="g6992-1"
                    stroke="#040404"
                    transform="matrix(.62944 0 0 .62944 1865.068 1013.704)"
                >
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text5399"
                        x="-267.302"
                        y="-115.944"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan5397"
                            x="-267.302"
                            y="-115.944"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.302"
                                y="-115.944"
                                style={{lineHeight: "0.5"}}
                                id="tspan5395"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                173
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="173"
                        className={`is-clickable ${((carrels[172].status === "RESERVED") || (carrels[172].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text5405"
                        x="-267.161"
                        y="-125.222"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan5403"
                            x="-267.161"
                            y="-125.222"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.161"
                                y="-125.222"
                                style={{lineHeight: "0.5"}}
                                id="tspan5401"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                161
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="161"
                        className={`is-clickable ${((carrels[160].status === "RESERVED") || (carrels[160].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path6990-9"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g
                    id="g7000-4"
                    stroke="#040404"
                    transform="translate(1865.068 1001.978) scale(.62944)"
                >
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text5411"
                        x="-267.334"
                        y="-115.896"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan5409"
                            x="-267.334"
                            y="-115.896"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.334"
                                y="-115.896"
                                style={{lineHeight: "0.5"}}
                                id="tspan5407"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                172
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="172"
                        className={`is-clickable ${((carrels[171].status === "RESERVED") || (carrels[171].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text5417"
                        x="-267.293"
                        y="-125.217"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan5415"
                            x="-267.293"
                            y="-125.217"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.293"
                                y="-125.217"
                                style={{lineHeight: "0.5"}}
                                id="tspan5413"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                162
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="162"
                        className={`is-clickable ${((carrels[161].status === "RESERVED") || (carrels[161].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path6998-0"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g id="g7015-0" transform="translate(0 -.037)">
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text5423"
                        x="-1165.93"
                        y="1780.81"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.172"
                        fontFamily="sans-serif"
                        fontSize="6.867"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="matrix(0 -.99368 1.00636 0 0 0)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan5421"
                            x="-1165.93"
                            y="1780.81"
                            strokeWidth="0.172"
                        >
                            <tspan
                                x="-1165.93"
                                y="1780.81"
                                style={{lineHeight: "0.5"}}
                                id="tspan5419"
                                strokeWidth="0.172"
                                fontSize="4.12"
                            >
                                171
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="171"
                        className={`is-clickable ${((carrels[170].status === "RESERVED") || (carrels[170].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="10.875"
                        height="5.802"
                        x="-1148.72"
                        y="1795.158"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.167"
                        opacity="0.5"
                        ry="0.353"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path7006-1"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.015"
                        d="M1787.916 1149.056l-.032 11.05"
                    ></path>
                </g>
                <g
                    id="g7055-9"
                    stroke="#040404"
                    transform="matrix(.62944 0 0 .62944 1865.068 978.527)"
                >
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text5429"
                        x="-267.331"
                        y="-115.946"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan5427"
                            x="-267.331"
                            y="-115.946"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.331"
                                y="-115.946"
                                style={{lineHeight: "0.5"}}
                                id="tspan5425"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                170
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="170"
                        className={`is-clickable ${((carrels[169].status === "RESERVED") || (carrels[169].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text5435"
                        x="-267.26"
                        y="-125.217"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan5433"
                            x="-267.26"
                            y="-125.217"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.26"
                                y="-125.217"
                                style={{lineHeight: "0.5"}}
                                id="tspan5431"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                163
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="163"
                        className={`is-clickable ${((carrels[162].status === "RESERVED") || (carrels[162].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path7053-3"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g
                    id="g7063-2"
                    stroke="#040404"
                    transform="translate(1865.068 966.8) scale(.62944)"
                >
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text5441"
                        x="-267.331"
                        y="-115.946"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan5439"
                            x="-267.331"
                            y="-115.946"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.331"
                                y="-115.946"
                                style={{lineHeight: "0.5"}}
                                id="tspan5437"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                169
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="169"
                        className={`is-clickable ${((carrels[168].status === "RESERVED") || (carrels[168].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text5447"
                        x="-267.345"
                        y="-125.222"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan5445"
                            x="-267.345"
                            y="-125.222"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.345"
                                y="-125.222"
                                style={{lineHeight: "0.5"}}
                                id="tspan5443"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                164
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="164"
                        className={`is-clickable ${((carrels[163].status === "RESERVED") || (carrels[163].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path7061-9"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g
                    id="g7071-6"
                    stroke="#040404"
                    transform="matrix(.62944 0 0 .62944 1865.068 950.998)"
                >
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text5453"
                        x="-267.351"
                        y="-115.944"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan5451"
                            x="-267.351"
                            y="-115.944"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.351"
                                y="-115.944"
                                style={{lineHeight: "0.5"}}
                                id="tspan5449"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                168
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="168"
                        className={`is-clickable ${((carrels[167].status === "RESERVED") || (carrels[167].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text5459"
                        x="-267.274"
                        y="-125.222"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan5457"
                            x="-267.274"
                            y="-125.222"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.274"
                                y="-125.222"
                                style={{lineHeight: "0.5"}}
                                id="tspan5455"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                165
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="165"
                        className={`is-clickable ${((carrels[164].status === "RESERVED") || (carrels[164].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path7069-7"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g
                    id="g7079-8"
                    stroke="#040404"
                    transform="translate(1865.068 939.283) scale(.62944)"
                >
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text5465"
                        x="-267.428"
                        y="-115.59"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan5463"
                            x="-267.428"
                            y="-115.59"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.428"
                                y="-115.59"
                                style={{lineHeight: "0.5"}}
                                id="tspan5461"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                167
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="167"
                        className={`is-clickable ${((carrels[166].status === "RESERVED") || (carrels[166].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="16.799"
                        height="9.34"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text5471"
                        x="-267.327"
                        y="-125.222"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan5469"
                            x="-267.327"
                            y="-125.222"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.327"
                                y="-125.222"
                                style={{lineHeight: "0.5"}}
                                id="tspan5467"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                166
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="166"
                        className={`is-clickable ${((carrels[165].status === "RESERVED") || (carrels[165].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="16.901"
                        height="9.22"
                        x="-270.426"
                        y="-130.158"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.262"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.631) skewX(.016)"
                    ></rect>
                    <path
                        id="path7077-1"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.57 252.77l-.053 17.035"
                    ></path>
                </g>
            </g>
            <g
                id="g7984-7"
                fill="#c6e48b"
                fillOpacity="0.985"
                stroke="#040404"
                strokeOpacity="0.418"
                transform="translate(-1767.474 -858.537) scale(1.25234)"
            >
                <g
                    id="g7087-3"
                    transform="matrix(.62944 0 0 .62944 1890.733 1052.069)"
                >
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4791"
                        x="-267.368"
                        y="-115.946"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4789"
                            x="-267.368"
                            y="-115.946"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.368"
                                y="-115.946"
                                style={{lineHeight: "0.5"}}
                                id="tspan4787"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                196
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="196"
                        className={`is-clickable ${((carrels[195].status === "RESERVED") || (carrels[195].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4797"
                        x="-267.299"
                        y="-125.209"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4795"
                            x="-267.299"
                            y="-125.209"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.299"
                                y="-125.209"
                                style={{lineHeight: "0.5"}}
                                id="tspan4793"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                177
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="177"
                        className={`is-clickable ${((carrels[176].status === "RESERVED") || (carrels[176].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path7085-2"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g
                    id="g7095-8"
                    transform="translate(1890.733 1040.354) scale(.62944)"
                >
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4803"
                        x="-267.316"
                        y="-115.946"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4801"
                            x="-267.316"
                            y="-115.946"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.316"
                                y="-115.946"
                                style={{lineHeight: "0.5"}}
                                id="tspan4799"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                195
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="195"
                        className={`is-clickable ${((carrels[194].status === "RESERVED") || (carrels[194].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4809"
                        x="-267.31"
                        y="-125.217"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4807"
                            x="-267.31"
                            y="-125.217"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.31"
                                y="-125.217"
                                style={{lineHeight: "0.5"}}
                                id="tspan4805"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                178
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="178"
                        className={`is-clickable ${((carrels[177].status === "RESERVED") || (carrels[177].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path7093-2"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g id="g7103-8" transform="translate(1890.733 1028.64) scale(.62944)">
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4815"
                        x="-267.387"
                        y="-115.943"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4813"
                            x="-267.387"
                            y="-115.943"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.387"
                                y="-115.943"
                                style={{lineHeight: "0.5"}}
                                id="tspan4811"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                194
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="194"
                        className={`is-clickable ${((carrels[193].status === "RESERVED") || (carrels[193].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4821"
                        x="-267.289"
                        y="-125.215"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4819"
                            x="-267.289"
                            y="-125.215"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.289"
                                y="-125.215"
                                style={{lineHeight: "0.5"}}
                                id="tspan4817"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                179
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="179"
                        className={`is-clickable ${((carrels[178].status === "RESERVED") || (carrels[178].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path7101-8"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g
                    id="g7111-1"
                    transform="matrix(.62944 0 0 .62944 1890.733 1013.704)"
                >
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4827"
                        x="-267.302"
                        y="-115.944"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4825"
                            x="-267.302"
                            y="-115.944"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.302"
                                y="-115.944"
                                style={{lineHeight: "0.5"}}
                                id="tspan4823"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                193
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="193"
                        className={`is-clickable ${((carrels[192].status === "RESERVED") || (carrels[192].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4833"
                        x="-267.289"
                        y="-125.217"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4831"
                            x="-267.289"
                            y="-125.217"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.289"
                                y="-125.217"
                                style={{lineHeight: "0.5"}}
                                id="tspan4829"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                180
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="180"
                        className={`is-clickable ${((carrels[179].status === "RESERVED") || (carrels[179].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path7109-6"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g
                    id="g7119-3"
                    transform="translate(1890.733 1002.044) scale(.62944)"
                >
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4839"
                        x="-267.54"
                        y="-115.481"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4837"
                            x="-267.54"
                            y="-115.481"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.54"
                                y="-115.481"
                                style={{lineHeight: "0.5"}}
                                id="tspan4835"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                192
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="192"
                        className={`is-clickable ${((carrels[191].status === "RESERVED") || (carrels[191].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4845"
                        x="-267.161"
                        y="-125.217"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4843"
                            x="-267.161"
                            y="-125.217"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.161"
                                y="-125.217"
                                style={{lineHeight: "0.5"}}
                                id="tspan4841"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                181
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="181"
                        className={`is-clickable ${((carrels[180].status === "RESERVED") || (carrels[180].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path7117-9"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g id="g7127-3" transform="translate(1890.733 990.384) scale(.62944)">
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4851"
                        x="-267.202"
                        y="-115.943"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        opacity="1"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4849"
                            x="-267.202"
                            y="-115.943"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.202"
                                y="-115.943"
                                style={{lineHeight: "0.5"}}
                                id="tspan4847"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                191
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="191"
                        className={`is-clickable ${((carrels[190].status === "RESERVED") || (carrels[190].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4857"
                        x="-267.293"
                        y="-125.217"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4855"
                            x="-267.293"
                            y="-125.217"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.293"
                                y="-125.217"
                                style={{lineHeight: "0.5"}}
                                id="tspan4853"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                182
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="182"
                        className={`is-clickable ${((carrels[181].status === "RESERVED") || (carrels[181].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path7125-1"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g
                    id="g7135-5"
                    transform="matrix(.62944 0 0 .62944 1890.733 978.725)"
                >
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4863"
                        x="-267.331"
                        y="-115.946"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4861"
                            x="-267.331"
                            y="-115.946"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.331"
                                y="-115.946"
                                style={{lineHeight: "0.5"}}
                                id="tspan4859"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                190
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="190"
                        className={`is-clickable ${((carrels[189].status === "RESERVED") || (carrels[189].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4869"
                        x="-267.26"
                        y="-125.217"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4867"
                            x="-267.26"
                            y="-125.217"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.26"
                                y="-125.217"
                                style={{lineHeight: "0.5"}}
                                id="tspan4865"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                183
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="183"
                        className={`is-clickable ${((carrels[182].status === "RESERVED") || (carrels[182].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path7133-7"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g id="g7143-6" transform="translate(1890.733 967.065) scale(.62944)">
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4875"
                        x="-267.331"
                        y="-115.944"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4873"
                            x="-267.331"
                            y="-115.944"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.331"
                                y="-115.944"
                                style={{lineHeight: "0.5"}}
                                id="tspan4871"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                189
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="189"
                        className={`is-clickable ${((carrels[188].status === "RESERVED") || (carrels[188].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4881"
                        x="-267.345"
                        y="-125.217"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4879"
                            x="-267.345"
                            y="-125.217"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.345"
                                y="-125.217"
                                style={{lineHeight: "0.5"}}
                                id="tspan4877"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                184
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="184"
                        className={`is-clickable ${((carrels[183].status === "RESERVED") || (carrels[183].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path7141-4"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g
                    id="g7159-7"
                    transform="matrix(.62944 0 0 .62944 1890.733 950.469)"
                >
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4887"
                        x="-267.351"
                        y="-115.944"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4885"
                            x="-267.351"
                            y="-115.944"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.351"
                                y="-115.944"
                                style={{lineHeight: "0.5"}}
                                id="tspan4883"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                188
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="188"
                        className={`is-clickable ${((carrels[187].status === "RESERVED") || (carrels[187].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4893"
                        x="-267.274"
                        y="-125.217"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4891"
                            x="-267.274"
                            y="-125.217"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.274"
                                y="-125.217"
                                style={{lineHeight: "0.5"}}
                                id="tspan4889"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                185
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="185"
                        className={`is-clickable ${((carrels[184].status === "RESERVED") || (carrels[184].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path7157-9"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g
                    id="g7175-2"
                    transform="matrix(.62944 0 0 .62944 1915.603 1052.333)"
                >
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4899"
                        x="-267.153"
                        y="-115.944"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4897"
                            x="-267.153"
                            y="-115.944"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.153"
                                y="-115.944"
                                style={{lineHeight: "0.5"}}
                                id="tspan4895"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                232
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="232"
                        className={`is-clickable ${((carrels[231].status === "RESERVED") || (carrels[231].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4905"
                        x="-267.299"
                        y="-125.215"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        opacity="1"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4903"
                            x="-267.299"
                            y="-125.215"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.299"
                                y="-125.215"
                                style={{lineHeight: "0.5"}}
                                id="tspan4901"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                197
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="197"
                        className={`is-clickable ${((carrels[196].status === "RESERVED") || (carrels[196].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path7173-6"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g
                    id="g7183-8"
                    transform="translate(1915.604 1040.619) scale(.62944)"
                >
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4911"
                        x="-267.021"
                        y="-115.944"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4909"
                            x="-267.021"
                            y="-115.944"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.021"
                                y="-115.944"
                                style={{lineHeight: "0.5"}}
                                id="tspan4907"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                231
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="231"
                        className={`is-clickable ${((carrels[230].status === "RESERVED") || (carrels[230].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4917"
                        x="-267.31"
                        y="-125.217"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4915"
                            x="-267.31"
                            y="-125.217"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.31"
                                y="-125.217"
                                style={{lineHeight: "0.5"}}
                                id="tspan4913"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                198
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="198"
                        className={`is-clickable ${((carrels[197].status === "RESERVED") || (carrels[197].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path7181-9"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g
                    id="g7191-7"
                    transform="translate(1915.604 1028.905) scale(.62944)"
                >
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4923"
                        x="-267.15"
                        y="-115.944"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4921"
                            x="-267.15"
                            y="-115.944"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.15"
                                y="-115.944"
                                style={{lineHeight: "0.5"}}
                                id="tspan4919"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                230
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="230"
                        className={`is-clickable ${((carrels[229].status === "RESERVED") || (carrels[229].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4929"
                        x="-267.289"
                        y="-125.215"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4927"
                            x="-267.289"
                            y="-125.215"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.289"
                                y="-125.215"
                                style={{lineHeight: "0.5"}}
                                id="tspan4925"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                199
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="199"
                        className={`is-clickable ${((carrels[198].status === "RESERVED") || (carrels[198].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path7189-5"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g
                    id="g7199-4"
                    transform="matrix(.62944 0 0 .62944 1915.603 1013.44)"
                >
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4935"
                        x="-267.15"
                        y="-115.941"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4933"
                            x="-267.15"
                            y="-115.941"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.15"
                                y="-115.941"
                                style={{lineHeight: "0.5"}}
                                id="tspan4931"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                229
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="229"
                        className={`is-clickable ${((carrels[228].status === "RESERVED") || (carrels[228].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4941"
                        x="-267.108"
                        y="-125.217"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4939"
                            x="-267.108"
                            y="-125.217"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.108"
                                y="-125.217"
                                style={{lineHeight: "0.5"}}
                                id="tspan4937"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                200
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="200"
                        className={`is-clickable ${((carrels[199].status === "RESERVED") || (carrels[199].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path7197-2"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g
                    id="g7207-5"
                    transform="translate(1915.604 1001.713) scale(.62944)"
                >
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4947"
                        x="-267.17"
                        y="-115.944"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4945"
                            x="-267.17"
                            y="-115.944"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.17"
                                y="-115.944"
                                style={{lineHeight: "0.5"}}
                                id="tspan4943"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                228
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="228"
                        className={`is-clickable ${((carrels[227].status === "RESERVED") || (carrels[227].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4953"
                        x="-266.979"
                        y="-125.217"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4951"
                            x="-266.979"
                            y="-125.217"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-266.979"
                                y="-125.217"
                                style={{lineHeight: "0.5"}}
                                id="tspan4949"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                201
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="201"
                        className={`is-clickable ${((carrels[200].status === "RESERVED") || (carrels[200].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path7205-3"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g id="g7215-3" transform="translate(1915.604 989.987) scale(.62944)">
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4959"
                        x="-267.111"
                        y="-125.217"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4957"
                            x="-267.111"
                            y="-125.217"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.111"
                                y="-125.217"
                                style={{lineHeight: "0.5"}}
                                id="tspan4955"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                202
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="202"
                        className={`is-clickable ${((carrels[201].status === "RESERVED") || (carrels[201].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path7213-7"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g
                    id="g7223-1"
                    transform="matrix(.62944 0 0 .62944 1915.603 978.262)"
                >
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4965"
                        x="-267.159"
                        y="-115.896"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        opacity="1"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4963"
                            x="-267.159"
                            y="-115.896"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.159"
                                y="-115.896"
                                style={{lineHeight: "0.5"}}
                                id="tspan4961"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                227
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="227"
                        className={`is-clickable ${((carrels[226].status === "RESERVED") || (carrels[226].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4971"
                        x="-267.079"
                        y="-125.217"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4969"
                            x="-267.079"
                            y="-125.217"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.079"
                                y="-125.217"
                                style={{lineHeight: "0.5"}}
                                id="tspan4967"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                203
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="203"
                        className={`is-clickable ${((carrels[202].status === "RESERVED") || (carrels[202].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path7221-0"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g id="g7231-5" transform="translate(1915.604 966.536) scale(.62944)">
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4977"
                        x="-267.187"
                        y="-115.944"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4975"
                            x="-267.187"
                            y="-115.944"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.187"
                                y="-115.944"
                                style={{lineHeight: "0.5"}}
                                id="tspan4973"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                226
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="226"
                        className={`is-clickable ${((carrels[225].status === "RESERVED") || (carrels[225].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4983"
                        x="-267.164"
                        y="-125.217"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4981"
                            x="-267.164"
                            y="-125.217"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.164"
                                y="-125.217"
                                style={{lineHeight: "0.5"}}
                                id="tspan4979"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                204
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="204"
                        className={`is-clickable ${((carrels[203].status === "RESERVED") || (carrels[203].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path7229-3"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g
                    id="g7247-0"
                    transform="matrix(.62944 0 0 .62944 1915.603 950.733)"
                >
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4989"
                        x="-267.134"
                        y="-115.944"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4987"
                            x="-267.134"
                            y="-115.944"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.134"
                                y="-115.944"
                                style={{lineHeight: "0.5"}}
                                id="tspan4985"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                225
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="225"
                        className={`is-clickable ${((carrels[224].status === "RESERVED") || (carrels[224].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4995"
                        x="-267.093"
                        y="-125.217"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4993"
                            x="-267.093"
                            y="-125.217"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.093"
                                y="-125.217"
                                style={{lineHeight: "0.5"}}
                                id="tspan4991"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                205
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="205"
                        className={`is-clickable ${((carrels[204].status === "RESERVED") || (carrels[204].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path7245-6"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g id="g7255-7" transform="translate(1915.604 939.019) scale(.62944)">
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text5001"
                        x="-267.206"
                        y="-115.896"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4999"
                            x="-267.206"
                            y="-115.896"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.206"
                                y="-115.896"
                                style={{lineHeight: "0.5"}}
                                id="tspan4997"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                224
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="224"
                        className={`is-clickable ${((carrels[223].status === "RESERVED") || (carrels[223].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text5007"
                        x="-267.145"
                        y="-125.217"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan5005"
                            x="-267.145"
                            y="-125.217"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.145"
                                y="-125.217"
                                style={{lineHeight: "0.5"}}
                                id="tspan5003"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                206
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="206"
                        className={`is-clickable ${((carrels[205].status === "RESERVED") || (carrels[205].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path7253-9"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g id="g7463-2" transform="translate(1890.733 938.754) scale(.62944)">
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text5013"
                        x="-267.34"
                        y="-115.944"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan5011"
                            x="-267.34"
                            y="-115.944"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.34"
                                y="-115.944"
                                style={{lineHeight: "0.5"}}
                                id="tspan5009"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                187
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="187"
                        className={`is-clickable ${((carrels[186].status === "RESERVED") || (carrels[186].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text5019"
                        x="-267.54"
                        y="-125.551"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan5017"
                            x="-267.54"
                            y="-125.551"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.54"
                                y="-125.551"
                                style={{lineHeight: "0.5"}}
                                id="tspan5015"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                186
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="186"
                        className={`is-clickable ${((carrels[185].status === "RESERVED") || (carrels[185].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path7461-1"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
            </g>
            <g
                id="g8058-8"
                fill="#c6e48b"
                fillOpacity="0.985"
                stroke="#040404"
                strokeOpacity="0.418"
                transform="translate(-1767.474 -858.537) scale(1.25234)"
            >
                <g
                    id="g7263-6"
                    transform="matrix(.62944 0 0 .62944 1941.983 1052.146)"
                >
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text5025"
                        x="-267.15"
                        y="-115.944"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan5023"
                            x="-267.15"
                            y="-115.944"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.15"
                                y="-115.944"
                                style={{lineHeight: "0.5"}}
                                id="tspan5021"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                270
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="270"
                        className={`is-clickable ${((carrels[269].status === "RESERVED") || (carrels[269].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text5031"
                        x="-267.079"
                        y="-125.217"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan5029"
                            x="-267.079"
                            y="-125.217"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.079"
                                y="-125.217"
                                style={{lineHeight: "0.5"}}
                                id="tspan5027"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                233
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="233"
                        className={`is-clickable ${((carrels[232].status === "RESERVED") || (carrels[232].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path7261-5"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g
                    id="g7271-9"
                    transform="translate(1941.983 1040.432) scale(.62944)"
                >
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text5037"
                        x="-267.15"
                        y="-115.944"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan5035"
                            x="-267.15"
                            y="-115.944"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.15"
                                y="-115.944"
                                style={{lineHeight: "0.5"}}
                                id="tspan5033"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                269
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="269"
                        className={`is-clickable ${((carrels[268].status === "RESERVED") || (carrels[268].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text5043"
                        x="-267.164"
                        y="-125.217"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan5041"
                            x="-267.164"
                            y="-125.217"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.164"
                                y="-125.217"
                                style={{lineHeight: "0.5"}}
                                id="tspan5039"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                234
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="234"
                        className={`is-clickable ${((carrels[233].status === "RESERVED") || (carrels[233].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path7269-5"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g
                    id="g7279-9"
                    transform="translate(1941.983 1028.718) scale(.62944)"
                >
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text5049"
                        x="-267.17"
                        y="-115.944"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan5047"
                            x="-267.17"
                            y="-115.944"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.17"
                                y="-115.944"
                                style={{lineHeight: "0.5"}}
                                id="tspan5045"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                268
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="268"
                        className={`is-clickable ${((carrels[267].status === "RESERVED") || (carrels[267].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text5055"
                        x="-267.093"
                        y="-125.217"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan5053"
                            x="-267.093"
                            y="-125.217"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.093"
                                y="-125.217"
                                style={{lineHeight: "0.5"}}
                                id="tspan5051"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                235
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="235"
                        className={`is-clickable ${((carrels[234].status === "RESERVED") || (carrels[234].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path7277-6"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g
                    id="g7287-4"
                    transform="matrix(.62944 0 0 .62944 1941.983 1013.606)"
                >
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text5061"
                        x="-267.159"
                        y="-115.944"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan5059"
                            x="-267.159"
                            y="-115.944"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.159"
                                y="-115.944"
                                style={{lineHeight: "0.5"}}
                                id="tspan5057"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                267
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="267"
                        className={`is-clickable ${((carrels[266].status === "RESERVED") || (carrels[266].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text5067"
                        x="-267.145"
                        y="-125.217"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan5065"
                            x="-267.145"
                            y="-125.217"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.145"
                                y="-125.217"
                                style={{lineHeight: "0.5"}}
                                id="tspan5063"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                236
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="236"
                        className={`is-clickable ${((carrels[235].status === "RESERVED") || (carrels[235].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path7285-3"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g
                    id="g7295-5"
                    transform="translate(1941.983 1001.884) scale(.62944)"
                >
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text5073"
                        x="-267.187"
                        y="-115.944"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan5071"
                            x="-267.187"
                            y="-115.944"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.187"
                                y="-115.944"
                                style={{lineHeight: "0.5"}}
                                id="tspan5069"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                266
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="266"
                        className={`is-clickable ${((carrels[265].status === "RESERVED") || (carrels[265].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text5079"
                        x="-267.117"
                        y="-125.217"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan5077"
                            x="-267.117"
                            y="-125.217"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.117"
                                y="-125.217"
                                style={{lineHeight: "0.5"}}
                                id="tspan5075"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                237
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="237"
                        className={`is-clickable ${((carrels[236].status === "RESERVED") || (carrels[236].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path7293-2"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g id="g7303-1" transform="translate(1941.983 990.162) scale(.62944)">
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text5085"
                        x="-267.134"
                        y="-115.944"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan5083"
                            x="-267.134"
                            y="-115.944"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.134"
                                y="-115.944"
                                style={{lineHeight: "0.5"}}
                                id="tspan5081"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                265
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="265"
                        className={`is-clickable ${((carrels[264].status === "RESERVED") || (carrels[264].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text5091"
                        x="-267.128"
                        y="-125.217"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan5089"
                            x="-267.128"
                            y="-125.217"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.128"
                                y="-125.217"
                                style={{lineHeight: "0.5"}}
                                id="tspan5087"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                238
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="238"
                        className={`is-clickable ${((carrels[237].status === "RESERVED") || (carrels[237].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path7301-7"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g
                    id="g7311-0"
                    transform="matrix(.62944 0 0 .62944 1941.983 978.441)"
                >
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text5097"
                        x="-267.206"
                        y="-115.944"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan5095"
                            x="-267.206"
                            y="-115.944"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.206"
                                y="-115.944"
                                style={{lineHeight: "0.5"}}
                                id="tspan5093"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                264
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="264"
                        className={`is-clickable ${((carrels[263].status === "RESERVED") || (carrels[263].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text5103"
                        x="-267.108"
                        y="-125.217"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan5101"
                            x="-267.108"
                            y="-125.217"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.108"
                                y="-125.217"
                                style={{lineHeight: "0.5"}}
                                id="tspan5099"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                239
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="239"
                        className={`is-clickable ${((carrels[238].status === "RESERVED") || (carrels[238].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path7309-5"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g id="g7319-9" transform="translate(1941.983 966.719) scale(.62944)">
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text5109"
                        x="-267.12"
                        y="-115.944"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan5107"
                            x="-267.12"
                            y="-115.944"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.12"
                                y="-115.944"
                                style={{lineHeight: "0.5"}}
                                id="tspan5105"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                263
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="263"
                        className={`is-clickable ${((carrels[262].status === "RESERVED") || (carrels[262].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text5115"
                        x="-267.108"
                        y="-125.217"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan5113"
                            x="-267.108"
                            y="-125.217"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.108"
                                y="-125.217"
                                style={{lineHeight: "0.5"}}
                                id="tspan5111"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                240
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="240"
                        className={`is-clickable ${((carrels[239].status === "RESERVED") || (carrels[239].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path7317-2"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g
                    id="g7335-0"
                    transform="matrix(.62944 0 0 .62944 1941.983 950.557)"
                >
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text5121"
                        x="-267.153"
                        y="-115.944"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan5119"
                            x="-267.153"
                            y="-115.944"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.153"
                                y="-115.944"
                                style={{lineHeight: "0.5"}}
                                id="tspan5117"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                262
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="262"
                        className={`is-clickable ${((carrels[261].status === "RESERVED") || (carrels[261].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text5127"
                        x="-266.979"
                        y="-125.169"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan5125"
                            x="-266.979"
                            y="-125.169"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-266.979"
                                y="-125.169"
                                style={{lineHeight: "0.5"}}
                                id="tspan5123"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                241
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="241"
                        className={`is-clickable ${((carrels[240].status === "RESERVED") || (carrels[240].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path7333-9"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g id="g7343-3" transform="translate(1941.983 938.843) scale(.62944)">
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text5135"
                        x="-267.021"
                        y="-115.944"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan5133"
                            x="-267.021"
                            y="-115.944"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.021"
                                y="-115.944"
                                style={{lineHeight: "0.5"}}
                                id="tspan5129"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                261
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="261"
                        className={`is-clickable ${((carrels[260].status === "RESERVED") || (carrels[260].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text5141"
                        x="-267.111"
                        y="-125.169"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan5139"
                            x="-267.111"
                            y="-125.169"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.111"
                                y="-125.169"
                                style={{lineHeight: "0.5"}}
                                id="tspan5137"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                242
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="242"
                        className={`is-clickable ${((carrels[241].status === "RESERVED") || (carrels[241].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path7341-6"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g id="g7351-4" transform="rotate(90 395.127 1661.583) scale(.62944)">
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text5147"
                        x="-267.021"
                        y="-115.896"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan5145"
                            x="-267.021"
                            y="-115.896"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.021"
                                y="-115.896"
                                style={{lineHeight: "0.5"}}
                                id="tspan5143"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                271
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="271"
                        className={`is-clickable ${((carrels[270].status === "RESERVED") || (carrels[270].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text5153"
                        x="-267.164"
                        y="-125.169"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan5151"
                            x="-267.164"
                            y="-125.169"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.164"
                                y="-125.169"
                                style={{lineHeight: "0.5"}}
                                id="tspan5149"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                274
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="274"
                        className={`is-clickable ${((carrels[273].status === "RESERVED") || (carrels[273].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path7349-0"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g id="g7359-7" transform="rotate(90 401.02 1667.476) scale(.62944)">
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text5159"
                        x="-267.153"
                        y="-115.896"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan5157"
                            x="-267.153"
                            y="-115.896"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.153"
                                y="-115.896"
                                style={{lineHeight: "0.5"}}
                                id="tspan5155"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                272
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="272"
                        className={`is-clickable ${((carrels[271].status === "RESERVED") || (carrels[271].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text5165"
                        x="-267.079"
                        y="-125.217"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan5163"
                            x="-267.079"
                            y="-125.217"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.079"
                                y="-125.217"
                                style={{lineHeight: "0.5"}}
                                id="tspan5161"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                273
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="273"
                        className={`is-clickable ${((carrels[272].status === "RESERVED") || (carrels[272].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path7357-1"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g id="g7383-2" transform="rotate(90 407.568 1649.142) scale(.62944)">
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text5171"
                        x="-267.134"
                        y="-115.944"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan5169"
                            x="-267.134"
                            y="-115.944"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.134"
                                y="-115.944"
                                style={{lineHeight: "0.5"}}
                                id="tspan5167"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                275
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="275"
                        className={`is-clickable ${((carrels[274].status === "RESERVED") || (carrels[274].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text5177"
                        x="-267.128"
                        y="-125.217"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan5175"
                            x="-267.128"
                            y="-125.217"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.128"
                                y="-125.217"
                                style={{lineHeight: "0.5"}}
                                id="tspan5173"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                278
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="278"
                        className={`is-clickable ${((carrels[277].status === "RESERVED") || (carrels[277].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path7381-4"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g id="g7391-3" transform="rotate(90 413.462 1655.035) scale(.62944)">
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text5183"
                        x="-267.187"
                        y="-115.944"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan5181"
                            x="-267.187"
                            y="-115.944"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.187"
                                y="-115.944"
                                style={{lineHeight: "0.5"}}
                                id="tspan5179"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                276
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="276"
                        className={`is-clickable ${((carrels[275].status === "RESERVED") || (carrels[275].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text5189"
                        x="-267.117"
                        y="-125.169"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan5187"
                            x="-267.117"
                            y="-125.169"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.117"
                                y="-125.169"
                                style={{lineHeight: "0.5"}}
                                id="tspan5185"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                277
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="277"
                        className={`is-clickable ${((carrels[276].status === "RESERVED") || (carrels[276].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path7389-6"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g id="g7399-7" transform="rotate(90 419.823 1636.887) scale(.62944)">
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text5195"
                        x="-267.15"
                        y="-115.941"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan5193"
                            x="-267.15"
                            y="-115.941"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.15"
                                y="-115.941"
                                style={{lineHeight: "0.5"}}
                                id="tspan5191"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                279
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="279"
                        className={`is-clickable ${((carrels[278].status === "RESERVED") || (carrels[278].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text5201"
                        x="-267.111"
                        y="-125.217"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan5199"
                            x="-267.111"
                            y="-125.217"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.111"
                                y="-125.217"
                                style={{lineHeight: "0.5"}}
                                id="tspan5197"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                282
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="282"
                        className={`is-clickable ${((carrels[281].status === "RESERVED") || (carrels[281].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path7397-8"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g id="g7407-0" transform="rotate(90 425.716 1642.78) scale(.62944)">
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text5207"
                        x="-267.15"
                        y="-115.944"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan5205"
                            x="-267.15"
                            y="-115.944"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.15"
                                y="-115.944"
                                style={{lineHeight: "0.5"}}
                                id="tspan5203"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                280
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="280"
                        className={`is-clickable ${((carrels[279].status === "RESERVED") || (carrels[279].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text5213"
                        x="-266.979"
                        y="-125.217"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan5211"
                            x="-266.979"
                            y="-125.217"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-266.979"
                                y="-125.217"
                                style={{lineHeight: "0.5"}}
                                id="tspan5209"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                281
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="281"
                        className={`is-clickable ${((carrels[280].status === "RESERVED") || (carrels[280].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path7405-4"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g id="g7415-6" transform="rotate(90 433.948 1622.762) scale(.62944)">
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text5219"
                        x="-267.12"
                        y="-115.944"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan5217"
                            x="-267.12"
                            y="-115.944"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.12"
                                y="-115.944"
                                style={{lineHeight: "0.5"}}
                                id="tspan5215"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                283
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="283"
                        className={`is-clickable ${((carrels[282].status === "RESERVED") || (carrels[282].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text5225"
                        x="-267.145"
                        y="-125.217"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan5223"
                            x="-267.145"
                            y="-125.217"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.145"
                                y="-125.217"
                                style={{lineHeight: "0.5"}}
                                id="tspan5221"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                286
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="286"
                        className={`is-clickable ${((carrels[285].status === "RESERVED") || (carrels[285].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path7413-4"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g id="g7423-2" transform="rotate(90 439.841 1628.656) scale(.62944)">
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text5231"
                        x="-267.206"
                        y="-115.944"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan5229"
                            x="-267.206"
                            y="-115.944"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.206"
                                y="-115.944"
                                style={{lineHeight: "0.5"}}
                                id="tspan5227"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                284
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="284"
                        className={`is-clickable ${((carrels[283].status === "RESERVED") || (carrels[283].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text5237"
                        x="-267.093"
                        y="-125.217"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan5235"
                            x="-267.093"
                            y="-125.217"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.093"
                                y="-125.217"
                                style={{lineHeight: "0.5"}}
                                id="tspan5233"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                285
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="285"
                        className={`is-clickable ${((carrels[284].status === "RESERVED") || (carrels[284].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path7421-2"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
            </g>
            <g
                id="g3196-7"
                fill="#c6e48b"
                fillOpacity="0.985"
                stroke="#040404"
                strokeOpacity="0.418"
                transform="translate(-85.58 -112.438) scale(1.25234)"
            >
                <g id="g7471-2" transform="matrix(.62944 0 0 .62944 572.523 326.737)">
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4185"
                        x="-267.12"
                        y="-115.944"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4183"
                            x="-267.12"
                            y="-115.944"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.12"
                                y="-115.944"
                                style={{lineHeight: "0.5"}}
                                id="tspan4181"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                223
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="223"
                        className={`is-clickable ${((carrels[222].status === "RESERVED") || (carrels[222].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4191"
                        x="-267.117"
                        y="-125.217"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4189"
                            x="-267.117"
                            y="-125.217"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.117"
                                y="-125.217"
                                style={{lineHeight: "0.5"}}
                                id="tspan4187"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                207
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="207"
                        className={`is-clickable ${((carrels[206].status === "RESERVED") || (carrels[206].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path7469-4"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g id="g7479-9" transform="translate(572.524 315.023) scale(.62944)">
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4197"
                        x="-267.153"
                        y="-115.896"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4195"
                            x="-267.153"
                            y="-115.896"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.153"
                                y="-115.896"
                                style={{lineHeight: "0.5"}}
                                id="tspan4193"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                222
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="222"
                        className={`is-clickable ${((carrels[221].status === "RESERVED") || (carrels[221].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4203"
                        x="-267.128"
                        y="-125.217"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4201"
                            x="-267.128"
                            y="-125.217"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.128"
                                y="-125.217"
                                style={{lineHeight: "0.5"}}
                                id="tspan4199"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                208
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="208"
                        className={`is-clickable ${((carrels[207].status === "RESERVED") || (carrels[207].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path7477-8"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g id="g7487-9" transform="translate(572.524 303.309) scale(.62944)">
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4209"
                        x="-267.021"
                        y="-115.896"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4207"
                            x="-267.021"
                            y="-115.896"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.021"
                                y="-115.896"
                                style={{lineHeight: "0.5"}}
                                id="tspan4205"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                221
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="221"
                        className={`is-clickable ${((carrels[220].status === "RESERVED") || (carrels[220].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4215"
                        x="-267.108"
                        y="-125.217"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4213"
                            x="-267.108"
                            y="-125.217"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.108"
                                y="-125.217"
                                style={{lineHeight: "0.5"}}
                                id="tspan4211"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                209
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="209"
                        className={`is-clickable ${((carrels[208].status === "RESERVED") || (carrels[208].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path7485-1"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g id="g7495-3" transform="matrix(.62944 0 0 .62944 572.788 288.373)">
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4221"
                        x="-267.15"
                        y="-115.944"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4219"
                            x="-267.15"
                            y="-115.944"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.15"
                                y="-115.944"
                                style={{lineHeight: "0.5"}}
                                id="tspan4217"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                220
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="220"
                        className={`is-clickable ${((carrels[219].status === "RESERVED") || (carrels[219].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4227"
                        x="-267.108"
                        y="-125.217"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4225"
                            x="-267.108"
                            y="-125.217"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.108"
                                y="-125.217"
                                style={{lineHeight: "0.5"}}
                                id="tspan4223"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                210
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="210"
                        className={`is-clickable ${((carrels[209].status === "RESERVED") || (carrels[209].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path7493-3"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g id="g7503-8" transform="translate(572.788 276.658) scale(.62944)">
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4233"
                        x="-267.15"
                        y="-115.941"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4231"
                            x="-267.15"
                            y="-115.941"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.15"
                                y="-115.941"
                                style={{lineHeight: "0.5"}}
                                id="tspan4229"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                219
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="219"
                        className={`is-clickable ${((carrels[218].status === "RESERVED") || (carrels[218].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4239"
                        x="-266.979"
                        y="-125.169"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4237"
                            x="-266.979"
                            y="-125.169"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-266.979"
                                y="-125.169"
                                style={{lineHeight: "0.5"}}
                                id="tspan4235"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                211
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="211"
                        className={`is-clickable ${((carrels[210].status === "RESERVED") || (carrels[210].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path7501-6"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g id="g7511-8" transform="translate(572.788 264.944) scale(.62944)">
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4245"
                        x="-267.111"
                        y="-125.169"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4243"
                            x="-267.111"
                            y="-125.169"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.111"
                                y="-125.169"
                                style={{lineHeight: "0.5"}}
                                id="tspan4241"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                212
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="212"
                        className={`is-clickable ${((carrels[211].status === "RESERVED") || (carrels[211].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path7509-4"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g id="g7519-8" transform="matrix(.62944 0 0 .62944 573.053 250.273)">
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4251"
                        x="-267.17"
                        y="-115.944"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4249"
                            x="-267.17"
                            y="-115.944"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.17"
                                y="-115.944"
                                style={{lineHeight: "0.5"}}
                                id="tspan4247"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                218
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="218"
                        className={`is-clickable ${((carrels[217].status === "RESERVED") || (carrels[217].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4257"
                        x="-267.079"
                        y="-125.217"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4255"
                            x="-267.079"
                            y="-125.217"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.079"
                                y="-125.217"
                                style={{lineHeight: "0.5"}}
                                id="tspan4253"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                213
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="213"
                        className={`is-clickable ${((carrels[212].status === "RESERVED") || (carrels[212].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path7517-3"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g id="g7527-5" transform="translate(573.053 238.558) scale(.62944)">
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4263"
                        x="-267.159"
                        y="-115.896"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4261"
                            x="-267.159"
                            y="-115.896"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.159"
                                y="-115.896"
                                style={{lineHeight: "0.5"}}
                                id="tspan4259"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                217
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="217"
                        className={`is-clickable ${((carrels[216].status === "RESERVED") || (carrels[216].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4269"
                        x="-267.164"
                        y="-125.169"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4267"
                            x="-267.164"
                            y="-125.169"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.164"
                                y="-125.169"
                                style={{lineHeight: "0.5"}}
                                id="tspan4265"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                214
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="214"
                        className={`is-clickable ${((carrels[213].status === "RESERVED") || (carrels[213].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path7525-1"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g id="g7535-2" transform="translate(573.053 226.844) scale(.62944)">
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4275"
                        x="-267.832"
                        y="-115.944"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4273"
                            x="-267.832"
                            y="-115.944"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.832"
                                y="-115.944"
                                style={{lineHeight: "0.5"}}
                                id="tspan4271"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                216
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="216"
                        className={`is-clickable ${((carrels[215].status === "RESERVED") || (carrels[215].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-271.07"
                        y="-120.918"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4281"
                        x="-267.78"
                        y="-125.108"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4279"
                            x="-267.78"
                            y="-125.108"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.78"
                                y="-125.108"
                                style={{lineHeight: "0.5"}}
                                id="tspan4277"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                215
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="215"
                        className={`is-clickable ${((carrels[214].status === "RESERVED") || (carrels[214].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-271.128"
                        y="-130.082"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path7533-0"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.618"
                        d="M-122.515 253.025l-.11 17.278"
                    ></path>
                </g>
                <g id="g7543-1" transform="matrix(.62944 0 0 .62944 598.717 326.473)">
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4287"
                        x="-267.15"
                        y="-115.944"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4285"
                            x="-267.15"
                            y="-115.944"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.15"
                                y="-115.944"
                                style={{lineHeight: "0.5"}}
                                id="tspan4283"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                260
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="260"
                        className={`is-clickable ${((carrels[259].status === "RESERVED") || (carrels[259].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4293"
                        x="-267.079"
                        y="-125.217"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4291"
                            x="-267.079"
                            y="-125.217"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.079"
                                y="-125.217"
                                style={{lineHeight: "0.5"}}
                                id="tspan4289"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                243
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="243"
                        className={`is-clickable ${((carrels[242].status === "RESERVED") || (carrels[242].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path7541-1"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g id="g7551-4" transform="translate(598.717 314.758) scale(.62944)">
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4575"
                        x="-267.15"
                        y="-115.944"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4573"
                            x="-267.15"
                            y="-115.944"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.15"
                                y="-115.944"
                                style={{lineHeight: "0.5"}}
                                id="tspan4571"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                259
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="259"
                        className={`is-clickable ${((carrels[258].status === "RESERVED") || (carrels[258].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4581"
                        x="-267.164"
                        y="-125.169"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4579"
                            x="-267.164"
                            y="-125.169"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.164"
                                y="-125.169"
                                style={{lineHeight: "0.5"}}
                                id="tspan4577"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                244
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="244"
                        className={`is-clickable ${((carrels[243].status === "RESERVED") || (carrels[243].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path7549-0"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g id="g7559-7" transform="translate(598.717 303.044) scale(.62944)">
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4587"
                        x="-267.17"
                        y="-115.944"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4585"
                            x="-267.17"
                            y="-115.944"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.17"
                                y="-115.944"
                                style={{lineHeight: "0.5"}}
                                id="tspan4583"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                258
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="258"
                        className={`is-clickable ${((carrels[257].status === "RESERVED") || (carrels[257].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4593"
                        x="-267.133"
                        y="-125.217"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4591"
                            x="-267.133"
                            y="-125.217"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.133"
                                y="-125.217"
                                style={{lineHeight: "0.5"}}
                                id="tspan4589"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                245
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="245"
                        className={`is-clickable ${((carrels[244].status === "RESERVED") || (carrels[244].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.482"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path7557-3"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g id="g7567-3" transform="matrix(.62944 0 0 .62944 598.717 288.373)">
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4599"
                        x="-267.159"
                        y="-115.944"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4597"
                            x="-267.159"
                            y="-115.944"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.159"
                                y="-115.944"
                                style={{lineHeight: "0.5"}}
                                id="tspan4595"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                257
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="257"
                        className={`is-clickable ${((carrels[256].status === "RESERVED") || (carrels[256].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4605"
                        x="-267.145"
                        y="-125.217"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4603"
                            x="-267.145"
                            y="-125.217"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.145"
                                y="-125.217"
                                style={{lineHeight: "0.5"}}
                                id="tspan4601"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                246
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="246"
                        className={`is-clickable ${((carrels[245].status === "RESERVED") || (carrels[245].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path7565-7"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g id="g7575-5" transform="translate(598.717 276.658) scale(.62944)">
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4611"
                        x="-267.187"
                        y="-115.944"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4609"
                            x="-267.187"
                            y="-115.944"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.187"
                                y="-115.944"
                                style={{lineHeight: "0.5"}}
                                id="tspan4607"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                256
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="256"
                        className={`is-clickable ${((carrels[255].status === "RESERVED") || (carrels[255].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4617"
                        x="-267.117"
                        y="-125.169"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4615"
                            x="-267.117"
                            y="-125.169"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.117"
                                y="-125.169"
                                style={{lineHeight: "0.5"}}
                                id="tspan4613"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                247
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="247"
                        className={`is-clickable ${((carrels[246].status === "RESERVED") || (carrels[246].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path7573-5"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g id="g7583-8" transform="translate(598.717 264.944) scale(.62944)">
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4623"
                        x="-267.134"
                        y="-115.944"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4621"
                            x="-267.134"
                            y="-115.944"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.134"
                                y="-115.944"
                                style={{lineHeight: "0.5"}}
                                id="tspan4619"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                255
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="255"
                        className={`is-clickable ${((carrels[254].status === "RESERVED") || (carrels[254].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4629"
                        x="-267.128"
                        y="-125.217"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4627"
                            x="-267.128"
                            y="-125.217"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.128"
                                y="-125.217"
                                style={{lineHeight: "0.5"}}
                                id="tspan4625"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                248
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="248"
                        className={`is-clickable ${((carrels[247].status === "RESERVED") || (carrels[247].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path7581-6"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g id="g7591-6" transform="matrix(.62944 0 0 .62944 598.717 250.537)">
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4635"
                        x="-267.206"
                        y="-115.944"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4633"
                            x="-267.206"
                            y="-115.944"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.206"
                                y="-115.944"
                                style={{lineHeight: "0.5"}}
                                id="tspan4631"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                254
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="254"
                        className={`is-clickable ${((carrels[253].status === "RESERVED") || (carrels[253].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4641"
                        x="-267.108"
                        y="-125.214"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4639"
                            x="-267.108"
                            y="-125.214"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.108"
                                y="-125.214"
                                style={{lineHeight: "0.5"}}
                                id="tspan4637"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                249
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="249"
                        className={`is-clickable ${((carrels[248].status === "RESERVED") || (carrels[248].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path7589-5"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g id="g7599-1" transform="translate(598.717 238.823) scale(.62944)">
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4647"
                        x="-267.12"
                        y="-115.944"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4645"
                            x="-267.12"
                            y="-115.944"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.12"
                                y="-115.944"
                                style={{lineHeight: "0.5"}}
                                id="tspan4643"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                253
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="253"
                        className={`is-clickable ${((carrels[252].status === "RESERVED") || (carrels[252].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4653"
                        x="-267.108"
                        y="-125.217"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4651"
                            x="-267.108"
                            y="-125.217"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.108"
                                y="-125.217"
                                style={{lineHeight: "0.5"}}
                                id="tspan4649"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                250
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="250"
                        className={`is-clickable ${((carrels[249].status === "RESERVED") || (carrels[249].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path7597-3"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g id="g7607-3" transform="translate(598.717 227.109) scale(.62944)">
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4659"
                        x="-267.153"
                        y="-115.944"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4657"
                            x="-267.153"
                            y="-115.944"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.153"
                                y="-115.944"
                                style={{lineHeight: "0.5"}}
                                id="tspan4655"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                252
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="252"
                        className={`is-clickable ${((carrels[251].status === "RESERVED") || (carrels[251].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4665"
                        x="-266.979"
                        y="-125.217"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4663"
                            x="-266.979"
                            y="-125.217"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-266.979"
                                y="-125.217"
                                style={{lineHeight: "0.5"}}
                                id="tspan4661"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                251
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="251"
                        className={`is-clickable ${((carrels[250].status === "RESERVED") || (carrels[250].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path7605-2"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g id="g7615-0" transform="rotate(90 72.767 640.946) scale(.62944)">
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4671"
                        x="-267.159"
                        y="-115.944"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4669"
                            x="-267.159"
                            y="-115.944"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.159"
                                y="-115.944"
                                style={{lineHeight: "0.5"}}
                                id="tspan4667"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                287
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="287"
                        className={`is-clickable ${((carrels[286].status === "RESERVED") || (carrels[286].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4677"
                        x="-267.108"
                        y="-125.217"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4675"
                            x="-267.108"
                            y="-125.217"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.108"
                                y="-125.217"
                                style={{lineHeight: "0.5"}}
                                id="tspan4673"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                290
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="290"
                        className={`is-clickable ${((carrels[289].status === "RESERVED") || (carrels[289].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path7613-2"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g id="g7623-7" transform="rotate(90 78.66 646.839) scale(.62944)">
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4683"
                        x="-267.872"
                        y="-115.944"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4681"
                            x="-267.872"
                            y="-115.944"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.872"
                                y="-115.944"
                                style={{lineHeight: "0.5"}}
                                id="tspan4679"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                288
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="288"
                        className={`is-clickable ${((carrels[287].status === "RESERVED") || (carrels[287].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-271.127"
                        y="-120.918"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4689"
                        x="-267.794"
                        y="-125.162"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4687"
                            x="-267.794"
                            y="-125.162"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.794"
                                y="-125.162"
                                style={{lineHeight: "0.5"}}
                                id="tspan4685"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                289
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="289"
                        className={`is-clickable ${((carrels[288].status === "RESERVED") || (carrels[288].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-271.127"
                        y="-130.135"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path7621-6"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.517 252.941l-.05 17.555"
                    ></path>
                </g>
                <g id="g7631-3" transform="rotate(90 85.07 628.643) scale(.62944)">
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4695"
                        x="-267.021"
                        y="-115.941"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4693"
                            x="-267.021"
                            y="-115.941"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.021"
                                y="-115.941"
                                style={{lineHeight: "0.5"}}
                                id="tspan4691"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                291
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="291"
                        className={`is-clickable ${((carrels[290].status === "RESERVED") || (carrels[290].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4701"
                        x="-267.164"
                        y="-125.214"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4699"
                            x="-267.164"
                            y="-125.214"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.164"
                                y="-125.214"
                                style={{lineHeight: "0.5"}}
                                id="tspan4697"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                294
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="294"
                        className={`is-clickable ${((carrels[293].status === "RESERVED") || (carrels[293].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path7629-1"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g id="g7639-3" transform="rotate(90 90.963 634.536) scale(.62944)">
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4707"
                        x="-267.153"
                        y="-115.941"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4705"
                            x="-267.153"
                            y="-115.941"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.153"
                                y="-115.941"
                                style={{lineHeight: "0.5"}}
                                id="tspan4703"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                292
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="292"
                        className={`is-clickable ${((carrels[291].status === "RESERVED") || (carrels[291].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4713"
                        x="-267.079"
                        y="-125.217"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4711"
                            x="-267.079"
                            y="-125.217"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.079"
                                y="-125.217"
                                style={{lineHeight: "0.5"}}
                                id="tspan4709"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                293
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="293"
                        className={`is-clickable ${((carrels[292].status === "RESERVED") || (carrels[292].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path7637-5"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g id="g7647-7" transform="rotate(90 99.886 613.826) scale(.62944)">
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4719"
                        x="-267.134"
                        y="-115.944"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4717"
                            x="-267.134"
                            y="-115.944"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.134"
                                y="-115.944"
                                style={{lineHeight: "0.5"}}
                                id="tspan4715"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                295
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="295"
                        className={`is-clickable ${((carrels[294].status === "RESERVED") || (carrels[294].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4725"
                        x="-267.128"
                        y="-125.217"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4723"
                            x="-267.128"
                            y="-125.217"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.128"
                                y="-125.217"
                                style={{lineHeight: "0.5"}}
                                id="tspan4721"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                298
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="298"
                        className={`is-clickable ${((carrels[297].status === "RESERVED") || (carrels[297].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path7645-0"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g id="g7655-2" transform="rotate(90 105.78 619.72) scale(.62944)">
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4731"
                        x="-267.187"
                        y="-115.944"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4729"
                            x="-267.187"
                            y="-115.944"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.187"
                                y="-115.944"
                                style={{lineHeight: "0.5"}}
                                id="tspan4727"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                296
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="296"
                        className={`is-clickable ${((carrels[295].status === "RESERVED") || (carrels[295].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4737"
                        x="-267.117"
                        y="-125.214"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4735"
                            x="-267.117"
                            y="-125.214"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.117"
                                y="-125.214"
                                style={{lineHeight: "0.5"}}
                                id="tspan4733"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                297
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="297"
                        className={`is-clickable ${((carrels[296].status === "RESERVED") || (carrels[296].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path7653-0"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g id="g7663-9" transform="rotate(90 112.19 601.523) scale(.62944)">
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4743"
                        x="-267.15"
                        y="-115.941"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4741"
                            x="-267.15"
                            y="-115.941"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.15"
                                y="-115.941"
                                style={{lineHeight: "0.5"}}
                                id="tspan4739"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                299
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="299"
                        className={`is-clickable ${((carrels[298].status === "RESERVED") || (carrels[298].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4749"
                        x="-267.12"
                        y="-125.217"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4747"
                            x="-267.12"
                            y="-125.217"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.12"
                                y="-125.217"
                                style={{lineHeight: "0.5"}}
                                id="tspan4745"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                302
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="302"
                        className={`is-clickable ${((carrels[301].status === "RESERVED") || (carrels[301].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path7661-3"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g id="g7671-0" transform="rotate(90 118.083 607.416) scale(.62944)">
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4755"
                        x="-267.151"
                        y="-116"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan id="tspan4753" x="-267.151" y="-116" strokeWidth="0.265">
                            <tspan
                                x="-267.151"
                                y="-116"
                                style={{lineHeight: "0.5"}}
                                id="tspan4751"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                300
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="300"
                        className={`is-clickable ${((carrels[299].status === "RESERVED") || (carrels[299].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.434"
                        y="-120.978"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4761"
                        x="-266.989"
                        y="-125.217"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4759"
                            x="-266.989"
                            y="-125.217"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-266.989"
                                y="-125.217"
                                style={{lineHeight: "0.5"}}
                                id="tspan4757"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                301
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="301"
                        className={`is-clickable ${((carrels[300].status === "RESERVED") || (carrels[300].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path7669-6"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.28l-.05 17.554"
                    ></path>
                </g>
                <g id="g7679-4" transform="rotate(90 124.625 589.088) scale(.62944)">
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4767"
                        x="-267.13"
                        y="-115.944"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4765"
                            x="-267.13"
                            y="-115.944"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.13"
                                y="-115.944"
                                style={{lineHeight: "0.5"}}
                                id="tspan4763"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                303
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="303"
                        className={`is-clickable ${((carrels[302].status === "RESERVED") || (carrels[302].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4773"
                        x="-267.155"
                        y="-125.217"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4771"
                            x="-267.155"
                            y="-125.217"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.155"
                                y="-125.217"
                                style={{lineHeight: "0.5"}}
                                id="tspan4769"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                306
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="306"
                        className={`is-clickable ${((carrels[305].status === "RESERVED") || (carrels[305].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path7677-2"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g id="g7687-7" transform="rotate(90 130.518 594.98) scale(.62944)">
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4779"
                        x="-267.215"
                        y="-115.944"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4777"
                            x="-267.215"
                            y="-115.944"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.215"
                                y="-115.944"
                                style={{lineHeight: "0.5"}}
                                id="tspan4775"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                304
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="304"
                        className={`is-clickable ${((carrels[303].status === "RESERVED") || (carrels[303].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4785"
                        x="-267.102"
                        y="-125.217"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4783"
                            x="-267.102"
                            y="-125.217"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.102"
                                y="-125.217"
                                style={{lineHeight: "0.5"}}
                                id="tspan4781"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                305
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="305"
                        className={`is-clickable ${((carrels[304].status === "RESERVED") || (carrels[304].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path7685-9"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
            </g>
            <g
                id="g3254-0"
                fill="#c6e48b"
                fillOpacity="0.985"
                stroke="#040404"
                strokeOpacity="0.418"
                transform="translate(-85.58 -112.438) scale(1.25234)"
            >
                <g id="g8292-5" transform="rotate(90 138.912 574.8) scale(.62944)">
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4299"
                        x="-267.168"
                        y="-115.944"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4297"
                            x="-267.168"
                            y="-115.944"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.168"
                                y="-115.944"
                                style={{lineHeight: "0.5"}}
                                id="tspan4295"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                307
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="307"
                        className={`is-clickable ${((carrels[306].status === "RESERVED") || (carrels[306].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4305"
                        x="-267.117"
                        y="-125.217"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4303"
                            x="-267.117"
                            y="-125.217"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.117"
                                y="-125.217"
                                style={{lineHeight: "0.5"}}
                                id="tspan4301"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                310
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="310"
                        className={`is-clickable ${((carrels[309].status === "RESERVED") || (carrels[309].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path8290-6"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g id="g8300-2" transform="rotate(90 144.806 580.693) scale(.62944)">
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4311"
                        x="-267.179"
                        y="-115.944"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4309"
                            x="-267.179"
                            y="-115.944"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.179"
                                y="-115.944"
                                style={{lineHeight: "0.5"}}
                                id="tspan4307"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                308
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="308"
                        className={`is-clickable ${((carrels[307].status === "RESERVED") || (carrels[307].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4317"
                        x="-267.117"
                        y="-125.217"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4315"
                            x="-267.117"
                            y="-125.217"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.117"
                                y="-125.217"
                                style={{lineHeight: "0.5"}}
                                id="tspan4313"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                309
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="309"
                        className={`is-clickable ${((carrels[308].status === "RESERVED") || (carrels[308].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path8298-7"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g id="g8308-1" transform="rotate(90 151.083 562.63) scale(.62944)">
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4323"
                        x="-267.03"
                        y="-115.944"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4321"
                            x="-267.03"
                            y="-115.944"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.03"
                                y="-115.944"
                                style={{lineHeight: "0.5"}}
                                id="tspan4319"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                311
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="311"
                        className={`is-clickable ${((carrels[310].status === "RESERVED") || (carrels[310].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4329"
                        x="-267.173"
                        y="-125.217"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4327"
                            x="-267.173"
                            y="-125.217"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.173"
                                y="-125.217"
                                style={{lineHeight: "0.5"}}
                                id="tspan4325"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                314
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="314"
                        className={`is-clickable ${((carrels[313].status === "RESERVED") || (carrels[313].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path8306-5"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g id="g8316-2" transform="rotate(90 156.976 568.523) scale(.62944)">
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4335"
                        x="-267.162"
                        y="-115.944"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4333"
                            x="-267.162"
                            y="-115.944"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.162"
                                y="-115.944"
                                style={{lineHeight: "0.5"}}
                                id="tspan4331"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                312
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="312"
                        className={`is-clickable ${((carrels[311].status === "RESERVED") || (carrels[311].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4341"
                        x="-267.088"
                        y="-125.217"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4339"
                            x="-267.088"
                            y="-125.217"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.088"
                                y="-125.217"
                                style={{lineHeight: "0.5"}}
                                id="tspan4337"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                313
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="313"
                        className={`is-clickable ${((carrels[312].status === "RESERVED") || (carrels[312].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path8314-8"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g id="g8324-8" transform="rotate(90 163.254 550.459) scale(.62944)">
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4347"
                        x="-267.143"
                        y="-115.944"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4345"
                            x="-267.143"
                            y="-115.944"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.143"
                                y="-115.944"
                                style={{lineHeight: "0.5"}}
                                id="tspan4343"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                315
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="315"
                        className={`is-clickable ${((carrels[314].status === "RESERVED") || (carrels[314].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4353"
                        x="-267.138"
                        y="-125.217"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4351"
                            x="-267.138"
                            y="-125.217"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.138"
                                y="-125.217"
                                style={{lineHeight: "0.5"}}
                                id="tspan4349"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                318
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="318"
                        className={`is-clickable ${((carrels[317].status === "RESERVED") || (carrels[317].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path8322-8"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g id="g8332-2" transform="rotate(90 169.147 556.352) scale(.62944)">
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4359"
                        x="-267.196"
                        y="-115.944"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4357"
                            x="-267.196"
                            y="-115.944"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.196"
                                y="-115.944"
                                style={{lineHeight: "0.5"}}
                                id="tspan4355"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                316
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="316"
                        className={`is-clickable ${((carrels[315].status === "RESERVED") || (carrels[315].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4365"
                        x="-267.127"
                        y="-125.217"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4363"
                            x="-267.127"
                            y="-125.217"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.127"
                                y="-125.217"
                                style={{lineHeight: "0.5"}}
                                id="tspan4361"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                317
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="317"
                        className={`is-clickable ${((carrels[316].status === "RESERVED") || (carrels[316].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path8330-5"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g id="g8340-5" transform="rotate(90 178.335 535.377) scale(.62944)">
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4371"
                        x="-267.159"
                        y="-115.944"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4369"
                            x="-267.159"
                            y="-115.944"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.159"
                                y="-115.944"
                                style={{lineHeight: "0.5"}}
                                id="tspan4367"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                319
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="319"
                        className={`is-clickable ${((carrels[318].status === "RESERVED") || (carrels[318].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4377"
                        x="-266.989"
                        y="-125.217"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4375"
                            x="-266.989"
                            y="-125.217"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-266.989"
                                y="-125.217"
                                style={{lineHeight: "0.5"}}
                                id="tspan4373"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                321
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="321"
                        className={`is-clickable ${((carrels[320].status === "RESERVED") || (carrels[320].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path8338-5"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g id="g8348-7" transform="rotate(90 184.228 541.27) scale(.62944)">
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4383"
                        x="-267.159"
                        y="-115.944"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4381"
                            x="-267.159"
                            y="-115.944"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.159"
                                y="-115.944"
                                style={{lineHeight: "0.5"}}
                                id="tspan4379"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                320
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="320"
                        className={`is-clickable ${((carrels[319].status === "RESERVED") || (carrels[319].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4389"
                        x="-267.12"
                        y="-125.217"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4387"
                            x="-267.12"
                            y="-125.217"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.12"
                                y="-125.217"
                                style={{lineHeight: "0.5"}}
                                id="tspan4385"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                322
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="322"
                        className={`is-clickable ${((carrels[321].status === "RESERVED") || (carrels[321].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path8346-2"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g id="g8356-7" transform="rotate(90 190.506 523.207) scale(.62944)">
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4395"
                        x="-268.495"
                        y="-115.365"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.263"
                        fontFamily="sans-serif"
                        fontSize="10.531"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="matrix(0 -.995 1.00502 0 0 0)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4393"
                            x="-268.495"
                            y="-115.365"
                            strokeWidth="0.263"
                        >
                            <tspan
                                x="-268.495"
                                y="-115.365"
                                style={{lineHeight: "0.5"}}
                                id="tspan4391"
                                strokeWidth="0.263"
                                fontSize="6.319"
                            >
                                324
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="324"
                        className={`is-clickable ${((carrels[323].status === "RESERVED") || (carrels[323].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4401"
                        x="-266.76"
                        y="-125.895"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.598"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="matrix(0 -1.00134 .99866 0 0 0)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4399"
                            x="-266.76"
                            y="-125.895"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-266.76"
                                y="-125.895"
                                style={{lineHeight: "0.5"}}
                                id="tspan4397"
                                strokeWidth="0.265"
                                fontSize="6.359"
                            >
                                325
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="325"
                        className={`is-clickable ${((carrels[324].status === "RESERVED") || (carrels[324].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path8354-0"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g id="g8364-0" transform="rotate(90 196.4 529.1) scale(.62944)">
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4407"
                        x="-266.469"
                        y="-116.246"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.611"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        opacity="1"
                        transform="matrix(0 -1.0026 .9974 0 0 0)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4405"
                            x="-266.469"
                            y="-116.246"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-266.469"
                                y="-116.246"
                                style={{lineHeight: "0.5"}}
                                id="tspan4403"
                                strokeWidth="0.265"
                                fontSize="6.367"
                            >
                                323
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="323"
                        className={`is-clickable ${((carrels[322].status === "RESERVED") || (carrels[322].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4413"
                        x="-268.013"
                        y="-124.797"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.264"
                        fontFamily="sans-serif"
                        fontSize="10.548"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="matrix(0 -.99665 1.00336 0 0 0)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4411"
                            x="-268.013"
                            y="-124.797"
                            strokeWidth="0.264"
                        >
                            <tspan
                                x="-268.013"
                                y="-124.797"
                                style={{lineHeight: "0.5"}}
                                id="tspan4409"
                                strokeWidth="0.264"
                                fontSize="6.329"
                            >
                                326
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="326"
                        className={`is-clickable ${((carrels[325].status === "RESERVED") || (carrels[325].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path8362-7"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g id="g8372-7" transform="rotate(90 202.941 510.771) scale(.62944)">
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4419"
                        x="-267.133"
                        y="-115.962"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.264"
                        fontFamily="sans-serif"
                        fontSize="10.544"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="matrix(0 -1.00009 .99991 0 0 0)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4417"
                            x="-267.133"
                            y="-115.962"
                            strokeWidth="0.264"
                        >
                            <tspan
                                x="-267.133"
                                y="-115.962"
                                style={{lineHeight: "0.5"}}
                                id="tspan4415"
                                strokeWidth="0.264"
                                fontSize="6.326"
                            >
                                328
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="328"
                        className={`is-clickable ${((carrels[327].status === "RESERVED") || (carrels[327].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4425"
                        x="-267.129"
                        y="-125.211"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="matrix(0 -.99996 1.00004 0 0 0)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4423"
                            x="-267.129"
                            y="-125.211"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.129"
                                y="-125.211"
                                style={{lineHeight: "0.5"}}
                                id="tspan4421"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                329
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="329"
                        className={`is-clickable ${((carrels[328].status === "RESERVED") || (carrels[328].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path8370-7"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g id="g8380-8" transform="rotate(90 208.835 516.664) scale(.62944)">
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4431"
                        x="-267.392"
                        y="-115.843"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.264"
                        fontFamily="sans-serif"
                        fontSize="10.575"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="matrix(0 -.99913 1.00088 0 0 0)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4429"
                            x="-267.392"
                            y="-115.843"
                            strokeWidth="0.264"
                        >
                            <tspan
                                x="-267.392"
                                y="-115.843"
                                style={{lineHeight: "0.5"}}
                                id="tspan4427"
                                strokeWidth="0.264"
                                fontSize="6.345"
                            >
                                327
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="327"
                        className={`is-clickable ${((carrels[326].status === "RESERVED") || (carrels[326].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4437"
                        x="-267.129"
                        y="-125.72"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="matrix(0 -.99996 1.00004 0 0 0)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4435"
                            x="-267.129"
                            y="-125.72"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.129"
                                y="-125.72"
                                style={{lineHeight: "0.5"}}
                                id="tspan4433"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                330
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="330"
                        className={`is-clickable ${((carrels[329].status === "RESERVED") || (carrels[329].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path8378-5"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g id="g8388-4" transform="translate(606.623 58.337) scale(.62944)">
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4443"
                        x="-267.03"
                        y="-115.944"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4441"
                            x="-267.03"
                            y="-115.944"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.03"
                                y="-115.944"
                                style={{lineHeight: "0.5"}}
                                id="tspan4439"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                331
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="331"
                        className={`is-clickable ${((carrels[330].status === "RESERVED") || (carrels[330].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4449"
                        x="-267.12"
                        y="-125.217"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4447"
                            x="-267.12"
                            y="-125.217"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.12"
                                y="-125.217"
                                style={{lineHeight: "0.5"}}
                                id="tspan4445"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                332
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="332"
                        className={`is-clickable ${((carrels[331].status === "RESERVED") || (carrels[331].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path8386-5"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g id="g8396-7" transform="translate(584.172 58.337) scale(.62944)">
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4455"
                        x="-267.13"
                        y="-115.944"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4453"
                            x="-267.13"
                            y="-115.944"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.13"
                                y="-115.944"
                                style={{lineHeight: "0.5"}}
                                id="tspan4451"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                333
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="333"
                        className={`is-clickable ${((carrels[332].status === "RESERVED") || (carrels[332].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4461"
                        x="-267.173"
                        y="-125.217"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4459"
                            x="-267.173"
                            y="-125.217"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.173"
                                y="-125.217"
                                style={{lineHeight: "0.5"}}
                                id="tspan4457"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                334
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="334"
                        className={`is-clickable ${((carrels[333].status === "RESERVED") || (carrels[333].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path8394-8"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
            </g>
            <g
                id="g3268-9"
                fill="#c6e48b"
                fillOpacity="0.985"
                stroke="#040404"
                strokeOpacity="0.418"
                transform="translate(-85.58 -112.438) scale(1.25234)"
            >
                <g id="g8404-3" transform="rotate(90 136.2 526.064) scale(.62944)">
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4563"
                        x="-267.196"
                        y="-115.944"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4561"
                            x="-267.196"
                            y="-115.944"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.196"
                                y="-115.944"
                                style={{lineHeight: "0.5"}}
                                id="tspan4559"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                336
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="336"
                        className={`is-clickable ${((carrels[335].status === "RESERVED") || (carrels[335].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4569"
                        x="-267.102"
                        y="-125.217"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4567"
                            x="-267.102"
                            y="-125.217"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.102"
                                y="-125.217"
                                style={{lineHeight: "0.5"}}
                                id="tspan4565"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                335
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="335"
                        className={`is-clickable ${((carrels[334].status === "RESERVED") || (carrels[334].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path8402-4"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g id="g8412-9" transform="rotate(90 124.226 538.037) scale(.62944)">
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4551"
                        x="-267.179"
                        y="-115.944"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4549"
                            x="-267.179"
                            y="-115.944"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.179"
                                y="-115.944"
                                style={{lineHeight: "0.5"}}
                                id="tspan4547"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                338
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="338"
                        className={`is-clickable ${((carrels[337].status === "RESERVED") || (carrels[337].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4557"
                        x="-267.127"
                        y="-125.217"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4555"
                            x="-267.127"
                            y="-125.217"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.127"
                                y="-125.217"
                                style={{lineHeight: "0.5"}}
                                id="tspan4553"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                337
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="337"
                        className={`is-clickable ${((carrels[336].status === "RESERVED") || (carrels[336].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path8410-0"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g id="g8420-5" transform="rotate(90 112.252 550.01) scale(.62944)">
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4539"
                        x="-267.159"
                        y="-115.944"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4537"
                            x="-267.159"
                            y="-115.944"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.159"
                                y="-115.944"
                                style={{lineHeight: "0.5"}}
                                id="tspan4535"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                340
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="340"
                        className={`is-clickable ${((carrels[339].status === "RESERVED") || (carrels[339].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4545"
                        x="-267.117"
                        y="-125.217"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4543"
                            x="-267.117"
                            y="-125.217"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.117"
                                y="-125.217"
                                style={{lineHeight: "0.5"}}
                                id="tspan4541"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                339
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="339"
                        className={`is-clickable ${((carrels[338].status === "RESERVED") || (carrels[338].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path8418-6"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
            </g>
            <g
                id="g3294-4"
                fill="#c6e48b"
                fillOpacity="0.985"
                stroke="#040404"
                strokeOpacity="0.418"
                transform="translate(-85.58 -112.438) scale(1.25234)"
            >
                <g id="g8452-2" transform="rotate(90 55.565 494.071) scale(.62944)">
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4467"
                        x="-267.03"
                        y="-115.944"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4465"
                            x="-267.03"
                            y="-115.944"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.03"
                                y="-115.944"
                                style={{lineHeight: "0.5"}}
                                id="tspan4463"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                341
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="341"
                        className={`is-clickable ${((carrels[340].status === "RESERVED") || (carrels[340].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4473"
                        x="-267.12"
                        y="-125.217"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4471"
                            x="-267.12"
                            y="-125.217"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.12"
                                y="-125.217"
                                style={{lineHeight: "0.5"}}
                                id="tspan4469"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                342
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="342"
                        className={`is-clickable ${((carrels[341].status === "RESERVED") || (carrels[341].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path8450-2"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g id="g8460-4" transform="rotate(90 67.398 482.238) scale(.62944)">
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4479"
                        x="-267.13"
                        y="-115.944"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4477"
                            x="-267.13"
                            y="-115.944"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.13"
                                y="-115.944"
                                style={{lineHeight: "0.5"}}
                                id="tspan4475"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                343
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="343"
                        className={`is-clickable ${((carrels[342].status === "RESERVED") || (carrels[342].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4485"
                        x="-267.173"
                        y="-125.217"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4483"
                            x="-267.173"
                            y="-125.217"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.173"
                                y="-125.217"
                                style={{lineHeight: "0.5"}}
                                id="tspan4481"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                344
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="344"
                        className={`is-clickable ${((carrels[343].status === "RESERVED") || (carrels[343].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path8458-0"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g id="g8468-0" transform="rotate(90 79.231 470.405) scale(.62944)">
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4491"
                        x="-267.143"
                        y="-115.944"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4489"
                            x="-267.143"
                            y="-115.944"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.143"
                                y="-115.944"
                                style={{lineHeight: "0.5"}}
                                id="tspan4487"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                345
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="345"
                        className={`is-clickable ${((carrels[344].status === "RESERVED") || (carrels[344].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4497"
                        x="-267.155"
                        y="-125.217"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4495"
                            x="-267.155"
                            y="-125.217"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.155"
                                y="-125.217"
                                style={{lineHeight: "0.5"}}
                                id="tspan4493"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                346
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="346"
                        className={`is-clickable ${((carrels[345].status === "RESERVED") || (carrels[345].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path8466-1"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g id="g8476-4" transform="rotate(90 70.719 461.892) scale(.62944)">
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4503"
                        x="-267.179"
                        y="-115.944"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4501"
                            x="-267.179"
                            y="-115.944"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.179"
                                y="-115.944"
                                style={{lineHeight: "0.5"}}
                                id="tspan4499"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                348
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="348"
                        className={`is-clickable ${((carrels[347].status === "RESERVED") || (carrels[347].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4509"
                        x="-267.127"
                        y="-125.217"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4507"
                            x="-267.127"
                            y="-125.217"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.127"
                                y="-125.217"
                                style={{lineHeight: "0.5"}}
                                id="tspan4505"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                347
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="347"
                        className={`is-clickable ${((carrels[346].status === "RESERVED") || (carrels[346].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path8474-4"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g id="g8484-9" transform="rotate(90 58.885 473.725) scale(.62944)">
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4515"
                        x="-267.175"
                        y="-116"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan id="tspan4513" x="-267.175" y="-116" strokeWidth="0.265">
                            <tspan
                                x="-267.175"
                                y="-116"
                                style={{lineHeight: "0.5"}}
                                id="tspan4511"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                350
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="350"
                        className={`is-clickable ${((carrels[349].status === "RESERVED") || (carrels[349].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-120.978"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4521"
                        x="-267.117"
                        y="-125.217"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4519"
                            x="-267.117"
                            y="-125.217"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.117"
                                y="-125.217"
                                style={{lineHeight: "0.5"}}
                                id="tspan4517"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                349
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="349"
                        className={`is-clickable ${((carrels[348].status === "RESERVED") || (carrels[348].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path8482-8"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g id="g8492-4" transform="rotate(90 47.052 485.559) scale(.62944)">
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4527"
                        x="-267.162"
                        y="-116.232"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4525"
                            x="-267.162"
                            y="-116.232"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.162"
                                y="-116.232"
                                style={{lineHeight: "0.5"}}
                                id="tspan4523"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                352
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="352"
                        className={`is-clickable ${((carrels[351].status === "RESERVED") || (carrels[351].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4533"
                        x="-266.989"
                        y="-125.217"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4531"
                            x="-266.989"
                            y="-125.217"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-266.989"
                                y="-125.217"
                                style={{lineHeight: "0.5"}}
                                id="tspan4529"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                351
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="351"
                        className={`is-clickable ${((carrels[350].status === "RESERVED") || (carrels[350].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path8490-4"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
            </g>
            <g
                id="g3462-3"
                fill="#c6e48b"
                fillOpacity="0.985"
                stroke="#040404"
                strokeOpacity="0.418"
                transform="translate(-85.58 -112.438) scale(1.25234)"
            >
                <g id="g8772-8" transform="translate(194.011 59.742) scale(.62944)">
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text3583"
                        x="-267.159"
                        y="-115.944"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan3581"
                            x="-267.159"
                            y="-115.944"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.159"
                                y="-115.944"
                                style={{lineHeight: "0.5"}}
                                id="tspan3579"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                399
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="399"
                        className={`is-clickable ${((carrels[398].status === "RESERVED") || (carrels[398].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text3589"
                        x="-266.961"
                        y="-125.163"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan3587"
                            x="-266.961"
                            y="-125.163"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-266.961"
                                y="-125.163"
                                style={{lineHeight: "0.5"}}
                                id="tspan3585"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                400
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="400"
                        className={`is-clickable ${((carrels[399].status === "RESERVED") || (carrels[399].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-130.14"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path8770-2"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g id="g8780-9" transform="translate(171.186 59.742) scale(.62944)">
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text3595"
                        x="-266.891"
                        y="-115.946"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan3593"
                            x="-266.891"
                            y="-115.946"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-266.891"
                                y="-115.946"
                                style={{lineHeight: "0.5"}}
                                id="tspan3591"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                401
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="401"
                        className={`is-clickable ${((carrels[400].status === "RESERVED") || (carrels[400].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text3603"
                        x="-266.981"
                        y="-125.217"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        opacity="1"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan3601"
                            x="-266.981"
                            y="-125.217"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-266.981"
                                y="-125.217"
                                style={{lineHeight: "0.5"}}
                                id="tspan3597"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                402
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="402"
                        className={`is-clickable ${((carrels[401].status === "RESERVED") || (carrels[401].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path8778-8"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g id="g8788-3" transform="rotate(90 -43.754 268.044) scale(.62944)">
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text3609"
                        x="-267.057"
                        y="-115.946"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeOpacity="0.418"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan3607"
                            x="-267.057"
                            y="-115.946"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.057"
                                y="-115.946"
                                style={{lineHeight: "0.5"}}
                                id="tspan3605"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                406
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="406"
                        className={`is-clickable ${((carrels[405].status === "RESERVED") || (carrels[405].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeOpacity="0.418"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text3615"
                        x="-266.948"
                        y="-125.217"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan3613"
                            x="-266.948"
                            y="-125.217"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-266.948"
                                y="-125.217"
                                style={{lineHeight: "0.5"}}
                                id="tspan3611"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                403
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="403"
                        className={`is-clickable ${((carrels[402].status === "RESERVED") || (carrels[402].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeOpacity="0.418"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path8786-5"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeOpacity="0.418"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g id="g8796-3" transform="rotate(90 -37.86 273.937) scale(.62944)">
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text3621"
                        x="-267.004"
                        y="-115.946"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan3619"
                            x="-267.004"
                            y="-115.946"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.004"
                                y="-115.946"
                                style={{lineHeight: "0.5"}}
                                id="tspan3617"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                405
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="405"
                        className={`is-clickable ${((carrels[404].status === "RESERVED") || (carrels[404].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text3627"
                        x="-267.034"
                        y="-125.218"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan3625"
                            x="-267.034"
                            y="-125.218"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.034"
                                y="-125.218"
                                style={{lineHeight: "0.5"}}
                                id="tspan3623"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                404
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="404"
                        className={`is-clickable ${((carrels[403].status === "RESERVED") || (carrels[403].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path8794-6"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g id="g8804-3" transform="rotate(90 -56.476 280.766) scale(.62944)">
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text3633"
                        x="-267.019"
                        y="-115.946"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan3631"
                            x="-267.019"
                            y="-115.946"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.019"
                                y="-115.946"
                                style={{lineHeight: "0.5"}}
                                id="tspan3629"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                410
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="410"
                        className={`is-clickable ${((carrels[409].status === "RESERVED") || (carrels[409].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text3639"
                        x="-266.987"
                        y="-125.218"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan3637"
                            x="-266.987"
                            y="-125.218"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-266.987"
                                y="-125.218"
                                style={{lineHeight: "0.5"}}
                                id="tspan3635"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                407
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="407"
                        className={`is-clickable ${((carrels[406].status === "RESERVED") || (carrels[406].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path8802-0"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g id="g8812-5" transform="rotate(90 -50.583 286.659) scale(.62944)">
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text3645"
                        x="-267.019"
                        y="-115.946"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan3643"
                            x="-267.019"
                            y="-115.946"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.019"
                                y="-115.946"
                                style={{lineHeight: "0.5"}}
                                id="tspan3641"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                409
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="409"
                        className={`is-clickable ${((carrels[408].status === "RESERVED") || (carrels[408].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text3651"
                        x="-266.998"
                        y="-125.217"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan3649"
                            x="-266.998"
                            y="-125.217"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-266.998"
                                y="-125.217"
                                style={{lineHeight: "0.5"}}
                                id="tspan3647"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                408
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="408"
                        className={`is-clickable ${((carrels[407].status === "RESERVED") || (carrels[407].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path8810-9"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g id="g8820-7" transform="rotate(90 -68.637 292.926) scale(.62944)">
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text3657"
                        x="-267.075"
                        y="-115.937"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan3655"
                            x="-267.075"
                            y="-115.937"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.075"
                                y="-115.937"
                                style={{lineHeight: "0.5"}}
                                id="tspan3653"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                414
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="414"
                        className={`is-clickable ${((carrels[413].status === "RESERVED") || (carrels[413].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text3663"
                        x="-266.849"
                        y="-125.209"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan3661"
                            x="-266.849"
                            y="-125.209"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-266.849"
                                y="-125.209"
                                style={{lineHeight: "0.5"}}
                                id="tspan3659"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                411
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="411"
                        className={`is-clickable ${((carrels[410].status === "RESERVED") || (carrels[410].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path8818-9"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g id="g8828-1" transform="rotate(90 -62.744 298.82) scale(.62944)">
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text3669"
                        x="-266.99"
                        y="-115.944"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan3667"
                            x="-266.99"
                            y="-115.944"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-266.99"
                                y="-115.944"
                                style={{lineHeight: "0.5"}}
                                id="tspan3665"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                413
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="413"
                        className={`is-clickable ${((carrels[412].status === "RESERVED") || (carrels[412].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text3675"
                        x="-266.981"
                        y="-125.169"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan3673"
                            x="-266.981"
                            y="-125.169"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-266.981"
                                y="-125.169"
                                style={{lineHeight: "0.5"}}
                                id="tspan3671"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                412
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="412"
                        className={`is-clickable ${((carrels[411].status === "RESERVED") || (carrels[411].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path8826-3"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g id="g8836-2" transform="rotate(90 -80.236 310.139) scale(.62944)">
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text3681"
                        x="-267.04"
                        y="-115.944"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan3679"
                            x="-267.04"
                            y="-115.944"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.04"
                                y="-115.944"
                                style={{lineHeight: "0.5"}}
                                id="tspan3677"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                418
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="418"
                        className={`is-clickable ${((carrels[417].status === "RESERVED") || (carrels[417].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text3687"
                        x="-266.962"
                        y="-125.257"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan3685"
                            x="-266.962"
                            y="-125.257"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-266.962"
                                y="-125.257"
                                style={{lineHeight: "0.5"}}
                                id="tspan3683"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                415
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="415"
                        className={`is-clickable ${((carrels[414].status === "RESERVED") || (carrels[414].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path8834-2"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g id="g8844-5" transform="rotate(90 -74.343 316.032) scale(.62944)">
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text3693"
                        x="-267.029"
                        y="-115.937"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan3691"
                            x="-267.029"
                            y="-115.937"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.029"
                                y="-115.937"
                                style={{lineHeight: "0.5"}}
                                id="tspan3689"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                417
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="417"
                        className={`is-clickable ${((carrels[416].status === "RESERVED") || (carrels[416].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text3699"
                        x="-267.015"
                        y="-125.222"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan3697"
                            x="-267.015"
                            y="-125.222"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.015"
                                y="-125.222"
                                style={{lineHeight: "0.5"}}
                                id="tspan3695"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                416
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="416"
                        className={`is-clickable ${((carrels[415].status === "RESERVED") || (carrels[415].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path8842-6"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g id="g8852-3" transform="rotate(90 -92.21 322.112) scale(.62944)">
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text3705"
                        x="-267.023"
                        y="-115.896"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan3703"
                            x="-267.023"
                            y="-115.896"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.023"
                                y="-115.896"
                                style={{lineHeight: "0.5"}}
                                id="tspan3701"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                422
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="422"
                        className={`is-clickable ${((carrels[421].status === "RESERVED") || (carrels[421].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text3711"
                        x="-266.978"
                        y="-125.215"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan3709"
                            x="-266.978"
                            y="-125.215"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-266.978"
                                y="-125.215"
                                style={{lineHeight: "0.5"}}
                                id="tspan3707"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                419
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="419"
                        className={`is-clickable ${((carrels[418].status === "RESERVED") || (carrels[418].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path8850-5"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g id="g8860-1" transform="rotate(90 -86.317 328.005) scale(.62944)">
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text3717"
                        x="-266.891"
                        y="-115.896"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan3715"
                            x="-266.891"
                            y="-115.896"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-266.891"
                                y="-115.896"
                                style={{lineHeight: "0.5"}}
                                id="tspan3713"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                421
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="421"
                        className={`is-clickable ${((carrels[420].status === "RESERVED") || (carrels[420].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text3723"
                        x="-266.978"
                        y="-125.217"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan3721"
                            x="-266.978"
                            y="-125.217"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-266.978"
                                y="-125.217"
                                style={{lineHeight: "0.5"}}
                                id="tspan3719"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                420
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="420"
                        className={`is-clickable ${((carrels[419].status === "RESERVED") || (carrels[419].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path8858-8"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g id="g8868-4" transform="rotate(90 -104.745 334.647) scale(.62944)">
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text3729"
                        x="-267.057"
                        y="-115.944"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan3727"
                            x="-267.057"
                            y="-115.944"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.057"
                                y="-115.944"
                                style={{lineHeight: "0.5"}}
                                id="tspan3725"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                426
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="426"
                        className={`is-clickable ${((carrels[425].status === "RESERVED") || (carrels[425].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text3735"
                        x="-266.948"
                        y="-125.217"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan3733"
                            x="-266.948"
                            y="-125.217"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-266.948"
                                y="-125.217"
                                style={{lineHeight: "0.5"}}
                                id="tspan3731"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                423
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="423"
                        className={`is-clickable ${((carrels[422].status === "RESERVED") || (carrels[422].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path8866-0"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g id="g8876-9" transform="rotate(90 -98.852 340.54) scale(.62944)">
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text3741"
                        x="-267.004"
                        y="-115.944"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        opacity="1"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan3739"
                            x="-267.004"
                            y="-115.944"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.004"
                                y="-115.944"
                                style={{lineHeight: "0.5"}}
                                id="tspan3737"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                425
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="425"
                        className={`is-clickable ${((carrels[424].status === "RESERVED") || (carrels[424].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text3747"
                        x="-267.034"
                        y="-125.169"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan3745"
                            x="-267.034"
                            y="-125.169"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.034"
                                y="-125.169"
                                style={{lineHeight: "0.5"}}
                                id="tspan3743"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                424
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="424"
                        className={`is-clickable ${((carrels[423].status === "RESERVED") || (carrels[423].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path8874-6"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
            </g>
            <g
                id="g3404-6"
                fill="#c6e48b"
                fillOpacity="0.985"
                stroke="#040404"
                strokeOpacity="0.418"
                transform="translate(-85.58 -112.438) scale(1.25234)"
            >
                <g id="g8506-8" transform="translate(262.86 168.76) scale(.62944)">
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text3753"
                        x="-267.196"
                        y="-115.944"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan3751"
                            x="-267.196"
                            y="-115.944"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.196"
                                y="-115.944"
                                style={{lineHeight: "0.5"}}
                                id="tspan3749"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                356
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="356"
                        className={`is-clickable ${((carrels[355].status === "RESERVED") || (carrels[355].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text3759"
                        x="-267.117"
                        y="-125.217"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan3757"
                            x="-267.117"
                            y="-125.217"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.117"
                                y="-125.217"
                                style={{lineHeight: "0.5"}}
                                id="tspan3755"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                359
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="359"
                        className={`is-clickable ${((carrels[358].status === "RESERVED") || (carrels[358].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path8504-9"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g id="g8514-9" transform="translate(262.86 156.873) scale(.62944)">
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text3765"
                        x="-267.168"
                        y="-115.944"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan3763"
                            x="-267.168"
                            y="-115.944"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.168"
                                y="-115.944"
                                style={{lineHeight: "0.5"}}
                                id="tspan3761"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                357
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="357"
                        className={`is-clickable ${((carrels[356].status === "RESERVED") || (carrels[356].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text3771"
                        x="-267.138"
                        y="-125.217"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan3769"
                            x="-267.138"
                            y="-125.217"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.138"
                                y="-125.217"
                                style={{lineHeight: "0.5"}}
                                id="tspan3767"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                358
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="358"
                        className={`is-clickable ${((carrels[357].status === "RESERVED") || (carrels[357].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path8512-2"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g id="g8522-1" transform="translate(262.86 192.534) scale(.62944)">
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text3777"
                        x="-267.215"
                        y="-115.944"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan3775"
                            x="-267.215"
                            y="-115.944"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.215"
                                y="-115.944"
                                style={{lineHeight: "0.5"}}
                                id="tspan3773"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                354
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="354"
                        className={`is-clickable ${((carrels[353].status === "RESERVED") || (carrels[353].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text3783"
                        x="-266.989"
                        y="-125.217"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan3781"
                            x="-266.989"
                            y="-125.217"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-266.989"
                                y="-125.217"
                                style={{lineHeight: "0.5"}}
                                id="tspan3779"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                361
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="361"
                        className={`is-clickable ${((carrels[360].status === "RESERVED") || (carrels[360].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path8520-5"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g id="g8530-6" transform="translate(262.86 180.647) scale(.62944)">
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text3789"
                        x="-267.143"
                        y="-115.944"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan3787"
                            x="-267.143"
                            y="-115.944"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.143"
                                y="-115.944"
                                style={{lineHeight: "0.5"}}
                                id="tspan3785"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                355
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="355"
                        className={`is-clickable ${((carrels[354].status === "RESERVED") || (carrels[354].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text3795"
                        x="-267.117"
                        y="-125.217"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan3793"
                            x="-267.117"
                            y="-125.217"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.117"
                                y="-125.217"
                                style={{lineHeight: "0.5"}}
                                id="tspan3791"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                360
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="360"
                        className={`is-clickable ${((carrels[359].status === "RESERVED") || (carrels[359].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path8528-1"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g id="g8562-5" transform="matrix(.62944 0 0 .62944 233.3 167.368)">
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text3801"
                        x="-267.196"
                        y="-115.944"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan3799"
                            x="-267.196"
                            y="-115.944"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.196"
                                y="-115.944"
                                style={{lineHeight: "0.5"}}
                                id="tspan3797"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                366
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="366"
                        className={`is-clickable ${((carrels[365].status === "RESERVED") || (carrels[365].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text3807"
                        x="-266.989"
                        y="-125.217"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan3805"
                            x="-266.989"
                            y="-125.217"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-266.989"
                                y="-125.217"
                                style={{lineHeight: "0.5"}}
                                id="tspan3803"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                371
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="371"
                        className={`is-clickable ${((carrels[370].status === "RESERVED") || (carrels[370].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path8560-9"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g id="g8570-1" transform="translate(233.3 155.572) scale(.62944)">
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text3813"
                        x="-267.168"
                        y="-115.944"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan3811"
                            x="-267.168"
                            y="-115.944"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.168"
                                y="-115.944"
                                style={{lineHeight: "0.5"}}
                                id="tspan3809"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                367
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="367"
                        className={`is-clickable ${((carrels[366].status === "RESERVED") || (carrels[366].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text3819"
                        x="-267.117"
                        y="-125.217"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan3817"
                            x="-267.117"
                            y="-125.217"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.117"
                                y="-125.217"
                                style={{lineHeight: "0.5"}}
                                id="tspan3815"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                370
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="370"
                        className={`is-clickable ${((carrels[369].status === "RESERVED") || (carrels[369].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path8568-9"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g id="g8578-8" transform="translate(233.3 143.777) scale(.62944)">
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text3825"
                        x="-267.179"
                        y="-115.944"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan3823"
                            x="-267.179"
                            y="-115.944"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.179"
                                y="-115.944"
                                style={{lineHeight: "0.5"}}
                                id="tspan3821"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                368
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="368"
                        className={`is-clickable ${((carrels[367].status === "RESERVED") || (carrels[367].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text3831"
                        x="-267.117"
                        y="-125.217"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan3829"
                            x="-267.117"
                            y="-125.217"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.117"
                                y="-125.217"
                                style={{lineHeight: "0.5"}}
                                id="tspan3827"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                369
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="369"
                        className={`is-clickable ${((carrels[368].status === "RESERVED") || (carrels[368].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path8576-1"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g id="g8626-3" transform="rotate(90 -75.106 363.754) scale(.62944)">
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text3837"
                        x="-267.196"
                        y="-115.944"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan3835"
                            x="-267.196"
                            y="-115.944"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.196"
                                y="-115.944"
                                style={{lineHeight: "0.5"}}
                                id="tspan3833"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                376
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="376"
                        className={`is-clickable ${((carrels[375].status === "RESERVED") || (carrels[375].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text3843"
                        x="-266.989"
                        y="-125.217"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        opacity="1"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan3841"
                            x="-266.989"
                            y="-125.217"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-266.989"
                                y="-125.217"
                                style={{lineHeight: "0.5"}}
                                id="tspan3839"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                381
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="381"
                        className={`is-clickable ${((carrels[380].status === "RESERVED") || (carrels[380].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path8624-6"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g id="g8634-5" transform="rotate(90 -69.213 369.647) scale(.62944)">
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text3849"
                        x="-267.143"
                        y="-115.944"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan3847"
                            x="-267.143"
                            y="-115.944"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.143"
                                y="-115.944"
                                style={{lineHeight: "0.5"}}
                                id="tspan3845"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                375
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="375"
                        className={`is-clickable ${((carrels[374].status === "RESERVED") || (carrels[374].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text3855"
                        x="-267.12"
                        y="-125.217"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan3853"
                            x="-267.12"
                            y="-125.217"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.12"
                                y="-125.217"
                                style={{lineHeight: "0.5"}}
                                id="tspan3851"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                382
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="382"
                        className={`is-clickable ${((carrels[381].status === "RESERVED") || (carrels[381].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path8632-6"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g id="g8674-9" transform="rotate(90 -89.138 349.722) scale(.62944)">
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text3861"
                        x="-267.179"
                        y="-115.944"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan3859"
                            x="-267.179"
                            y="-115.944"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.179"
                                y="-115.944"
                                style={{lineHeight: "0.5"}}
                                id="tspan3857"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                378
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="378"
                        className={`is-clickable ${((carrels[377].status === "RESERVED") || (carrels[377].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text3867"
                        x="-267.117"
                        y="-125.217"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan3865"
                            x="-267.117"
                            y="-125.217"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.117"
                                y="-125.217"
                                style={{lineHeight: "0.5"}}
                                id="tspan3863"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                379
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="379"
                        className={`is-clickable ${((carrels[378].status === "RESERVED") || (carrels[378].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path8672-4"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g id="g8682-2" transform="rotate(90 -83.245 355.616) scale(.62944)">
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text3873"
                        x="-267.168"
                        y="-115.944"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan3871"
                            x="-267.168"
                            y="-115.944"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.168"
                                y="-115.944"
                                style={{lineHeight: "0.5"}}
                                id="tspan3869"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                377
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="377"
                        className={`is-clickable ${((carrels[376].status === "RESERVED") || (carrels[376].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text3879"
                        x="-267.117"
                        y="-125.217"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan3877"
                            x="-267.117"
                            y="-125.217"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.117"
                                y="-125.217"
                                style={{lineHeight: "0.5"}}
                                id="tspan3875"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                380
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="380"
                        className={`is-clickable ${((carrels[379].status === "RESERVED") || (carrels[379].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path8680-2"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g id="g8690-3" transform="rotate(90 -77.164 337.749) scale(.62944)">
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text3885"
                        x="-267.196"
                        y="-115.944"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan3883"
                            x="-267.196"
                            y="-115.944"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.196"
                                y="-115.944"
                                style={{lineHeight: "0.5"}}
                                id="tspan3881"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                386
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="386"
                        className={`is-clickable ${((carrels[385].status === "RESERVED") || (carrels[385].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text3891"
                        x="-267.127"
                        y="-125.217"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan3889"
                            x="-267.127"
                            y="-125.217"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.127"
                                y="-125.217"
                                style={{lineHeight: "0.5"}}
                                id="tspan3887"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                387
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="387"
                        className={`is-clickable ${((carrels[386].status === "RESERVED") || (carrels[386].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path8688-9"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g id="g8698-9" transform="rotate(90 -71.27 343.642) scale(.62944)">
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text3897"
                        x="-267.143"
                        y="-115.944"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan3895"
                            x="-267.143"
                            y="-115.944"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.143"
                                y="-115.944"
                                style={{lineHeight: "0.5"}}
                                id="tspan3893"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                385
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="385"
                        className={`is-clickable ${((carrels[384].status === "RESERVED") || (carrels[384].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text3903"
                        x="-267.138"
                        y="-125.217"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan3901"
                            x="-267.138"
                            y="-125.217"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.138"
                                y="-125.217"
                                style={{lineHeight: "0.5"}}
                                id="tspan3899"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                388
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="388"
                        className={`is-clickable ${((carrels[387].status === "RESERVED") || (carrels[387].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path8696-7"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g id="g8706-6" transform="rotate(90 -63.133 351.78) scale(.62944)">
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text3909"
                        x="-267.215"
                        y="-115.944"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan3907"
                            x="-267.215"
                            y="-115.944"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.215"
                                y="-115.944"
                                style={{lineHeight: "0.5"}}
                                id="tspan3905"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                384
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="384"
                        className={`is-clickable ${((carrels[383].status === "RESERVED") || (carrels[383].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text3915"
                        x="-267.117"
                        y="-125.217"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan3913"
                            x="-267.117"
                            y="-125.217"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.117"
                                y="-125.217"
                                style={{lineHeight: "0.5"}}
                                id="tspan3911"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                389
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="389"
                        className={`is-clickable ${((carrels[388].status === "RESERVED") || (carrels[388].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path8704-4"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g id="g8714-1" transform="rotate(90 -57.24 357.674) scale(.62944)">
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text3921"
                        x="-267.13"
                        y="-115.944"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan3919"
                            x="-267.13"
                            y="-115.944"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.13"
                                y="-115.944"
                                style={{lineHeight: "0.5"}}
                                id="tspan3917"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                383
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="383"
                        className={`is-clickable ${((carrels[382].status === "RESERVED") || (carrels[382].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text3927"
                        x="-267.117"
                        y="-125.217"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan3925"
                            x="-267.117"
                            y="-125.217"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.117"
                                y="-125.217"
                                style={{lineHeight: "0.5"}}
                                id="tspan3923"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                390
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="390"
                        className={`is-clickable ${((carrels[389].status === "RESERVED") || (carrels[389].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path8712-8"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g id="g8722-7" transform="rotate(90 -65.003 325.588) scale(.62944)">
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text3933"
                        x="-267.215"
                        y="-115.944"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan3931"
                            x="-267.215"
                            y="-115.944"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.215"
                                y="-115.944"
                                style={{lineHeight: "0.5"}}
                                id="tspan3929"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                394
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="394"
                        className={`is-clickable ${((carrels[393].status === "RESERVED") || (carrels[393].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text3939"
                        x="-267.102"
                        y="-125.217"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan3937"
                            x="-267.102"
                            y="-125.217"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.102"
                                y="-125.217"
                                style={{lineHeight: "0.5"}}
                                id="tspan3935"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                395
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="395"
                        className={`is-clickable ${((carrels[394].status === "RESERVED") || (carrels[394].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path8720-7"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g id="g8730-7" transform="rotate(90 -59.11 331.481) scale(.62944)">
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text3945"
                        x="-267.13"
                        y="-115.944"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan3943"
                            x="-267.13"
                            y="-115.944"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.13"
                                y="-115.944"
                                style={{lineHeight: "0.5"}}
                                id="tspan3941"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                393
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="393"
                        className={`is-clickable ${((carrels[392].status === "RESERVED") || (carrels[392].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text3951"
                        x="-267.155"
                        y="-125.217"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan3949"
                            x="-267.155"
                            y="-125.217"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.155"
                                y="-125.217"
                                style={{lineHeight: "0.5"}}
                                id="tspan3947"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                396
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="396"
                        className={`is-clickable ${((carrels[395].status === "RESERVED") || (carrels[395].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path8728-6"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g id="g8738-9" transform="rotate(90 -50.972 339.62) scale(.62944)">
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text3957"
                        x="-267.162"
                        y="-115.944"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan3955"
                            x="-267.162"
                            y="-115.944"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.162"
                                y="-115.944"
                                style={{lineHeight: "0.5"}}
                                id="tspan3953"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                392
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="392"
                        className={`is-clickable ${((carrels[391].status === "RESERVED") || (carrels[391].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text3963"
                        x="-267.127"
                        y="-125.217"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan3961"
                            x="-267.127"
                            y="-125.217"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.127"
                                y="-125.217"
                                style={{lineHeight: "0.5"}}
                                id="tspan3959"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                397
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="397"
                        className={`is-clickable ${((carrels[396].status === "RESERVED") || (carrels[396].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path8736-9"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g id="g8746-0" transform="rotate(90 -45.079 345.513) scale(.62944)">
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text3969"
                        x="-267.03"
                        y="-115.944"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan3967"
                            x="-267.03"
                            y="-115.944"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.03"
                                y="-115.944"
                                style={{lineHeight: "0.5"}}
                                id="tspan3965"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                391
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="391"
                        className={`is-clickable ${((carrels[390].status === "RESERVED") || (carrels[390].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text3975"
                        x="-267.138"
                        y="-125.217"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan3973"
                            x="-267.138"
                            y="-125.217"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.138"
                                y="-125.217"
                                style={{lineHeight: "0.5"}}
                                id="tspan3971"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                398
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="398"
                        className={`is-clickable ${((carrels[397].status === "RESERVED") || (carrels[397].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path8744-6"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g id="g8988-6" transform="matrix(.62944 0 0 .62944 233.3 202.752)">
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text3981"
                        x="-267.13"
                        y="-115.944"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan3979"
                            x="-267.13"
                            y="-115.944"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.13"
                                y="-115.944"
                                style={{lineHeight: "0.5"}}
                                id="tspan3977"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                363
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="363"
                        className={`is-clickable ${((carrels[362].status === "RESERVED") || (carrels[362].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text3987"
                        x="-267.173"
                        y="-125.217"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan3985"
                            x="-267.173"
                            y="-125.217"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.173"
                                y="-125.217"
                                style={{lineHeight: "0.5"}}
                                id="tspan3983"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                374
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="374"
                        className={`is-clickable ${((carrels[373].status === "RESERVED") || (carrels[373].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path8986-9"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g id="g8996-4" transform="translate(233.3 190.957) scale(.62944)">
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text3993"
                        x="-267.215"
                        y="-115.944"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan3991"
                            x="-267.215"
                            y="-115.944"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.215"
                                y="-115.944"
                                style={{lineHeight: "0.5"}}
                                id="tspan3989"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                364
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="364"
                        className={`is-clickable ${((carrels[363].status === "RESERVED") || (carrels[363].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text3999"
                        x="-267.088"
                        y="-125.217"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan3997"
                            x="-267.088"
                            y="-125.217"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.088"
                                y="-125.217"
                                style={{lineHeight: "0.5"}}
                                id="tspan3995"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                373
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="373"
                        className={`is-clickable ${((carrels[372].status === "RESERVED") || (carrels[372].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path8994-3"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g id="g9004-6" transform="translate(233.3 179.162) scale(.62944)">
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4005"
                        x="-267.143"
                        y="-115.944"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4003"
                            x="-267.143"
                            y="-115.944"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.143"
                                y="-115.944"
                                style={{lineHeight: "0.5"}}
                                id="tspan4001"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                365
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="365"
                        className={`is-clickable ${((carrels[364].status === "RESERVED") || (carrels[364].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4011"
                        x="-267.12"
                        y="-125.217"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4009"
                            x="-267.12"
                            y="-125.217"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.12"
                                y="-125.217"
                                style={{lineHeight: "0.5"}}
                                id="tspan4007"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                372
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="372"
                        className={`is-clickable ${((carrels[371].status === "RESERVED") || (carrels[371].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path9002-7"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
            </g>
            <g id="g3998-6" transform="translate(-85.58 -112.438) scale(1.25234)">
                <g
                    id="g8900-4"
                    fill="#c6e48b"
                    fillOpacity="0.985"
                    stroke="#040404"
                    strokeOpacity="0.418"
                    transform="rotate(90 -120.835 350.737) scale(.62944)"
                >
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4017"
                        x="-267.019"
                        y="-115.944"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4015"
                            x="-267.019"
                            y="-115.944"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.019"
                                y="-115.944"
                                style={{lineHeight: "0.5"}}
                                id="tspan4013"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                430
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="430"
                        className={`is-clickable ${((carrels[429].status === "RESERVED") || (carrels[429].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeOpacity="0.418"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4023"
                        x="-266.987"
                        y="-125.169"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4021"
                            x="-266.987"
                            y="-125.169"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-266.987"
                                y="-125.169"
                                style={{lineHeight: "0.5"}}
                                id="tspan4019"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                427
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="427"
                        className={`is-clickable ${((carrels[426].status === "RESERVED") || (carrels[426].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeOpacity="0.418"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path8898-1"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeOpacity="0.418"
                        strokeWidth="1.613"
                        d="M-122.515 252.38l-.051 17.554"
                    ></path>
                </g>
                <g
                    id="g8908-6"
                    fill="#c6e48b"
                    fillOpacity="0.985"
                    stroke="#040404"
                    strokeOpacity="0.418"
                    transform="rotate(90 -114.941 356.63) scale(.62944)"
                >
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4029"
                        x="-267.019"
                        y="-115.941"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4027"
                            x="-267.019"
                            y="-115.941"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.019"
                                y="-115.941"
                                style={{lineHeight: "0.5"}}
                                id="tspan4025"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                429
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="429"
                        className={`is-clickable ${((carrels[428].status === "RESERVED") || (carrels[428].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeOpacity="0.418"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4035"
                        x="-266.982"
                        y="-125.162"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        opacity="1"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4033"
                            x="-266.982"
                            y="-125.162"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-266.982"
                                y="-125.162"
                                style={{lineHeight: "0.5"}}
                                id="tspan4031"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                428
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="428"
                        className={`is-clickable ${((carrels[427].status === "RESERVED") || (carrels[427].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-130.14"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeOpacity="0.418"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path8906-9"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeOpacity="0.418"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g
                    id="g8916-3"
                    fill="#c6e48b"
                    fillOpacity="0.985"
                    stroke="#040404"
                    strokeOpacity="0.418"
                    transform="rotate(90 -133.183 363.084) scale(.62944)"
                >
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4041"
                        x="-267.075"
                        y="-115.944"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4039"
                            x="-267.075"
                            y="-115.944"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.075"
                                y="-115.944"
                                style={{lineHeight: "0.5"}}
                                id="tspan4037"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                434
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="434"
                        className={`is-clickable ${((carrels[433].status === "RESERVED") || (carrels[433].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeOpacity="0.418"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4047"
                        x="-266.849"
                        y="-125.217"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4045"
                            x="-266.849"
                            y="-125.217"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-266.849"
                                y="-125.217"
                                style={{lineHeight: "0.5"}}
                                id="tspan4043"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                431
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="431"
                        className={`is-clickable ${((carrels[430].status === "RESERVED") || (carrels[430].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeOpacity="0.418"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path8914-0"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeOpacity="0.418"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g
                    id="g8924-1"
                    fill="#c6e48b"
                    fillOpacity="0.985"
                    stroke="#040404"
                    strokeOpacity="0.418"
                    transform="rotate(90 -127.29 368.978) scale(.62944)"
                >
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4053"
                        x="-266.99"
                        y="-115.944"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4051"
                            x="-266.99"
                            y="-115.944"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-266.99"
                                y="-115.944"
                                style={{lineHeight: "0.5"}}
                                id="tspan4049"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                433
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="433"
                        className={`is-clickable ${((carrels[432].status === "RESERVED") || (carrels[432].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeOpacity="0.418"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4059"
                        x="-266.981"
                        y="-125.217"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4057"
                            x="-266.981"
                            y="-125.217"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-266.981"
                                y="-125.217"
                                style={{lineHeight: "0.5"}}
                                id="tspan4055"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                432
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="432"
                        className={`is-clickable ${((carrels[431].status === "RESERVED") || (carrels[431].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeOpacity="0.418"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path8922-8"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeOpacity="0.418"
                        strokeWidth="1.613"
                        d="M-122.515 252.38l-.051 17.554"
                    ></path>
                </g>
                <g
                    id="g8932-5"
                    fill="#c6e48b"
                    fillOpacity="0.985"
                    stroke="#040404"
                    strokeOpacity="0.418"
                    transform="rotate(90 -145.53 375.432) scale(.62944)"
                >
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4065"
                        x="-267.04"
                        y="-115.944"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        opacity="1"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4063"
                            x="-267.04"
                            y="-115.944"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.04"
                                y="-115.944"
                                style={{lineHeight: "0.5"}}
                                id="tspan4061"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                438
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="438"
                        className={`is-clickable ${((carrels[437].status === "RESERVED") || (carrels[437].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeOpacity="0.418"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4071"
                        x="-266.962"
                        y="-125.217"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4069"
                            x="-266.962"
                            y="-125.217"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-266.962"
                                y="-125.217"
                                style={{lineHeight: "0.5"}}
                                id="tspan4067"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                435
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="435"
                        className={`is-clickable ${((carrels[434].status === "RESERVED") || (carrels[434].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeOpacity="0.418"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path8930-8"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeOpacity="0.418"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g
                    id="g8940-9"
                    fill="#c6e48b"
                    fillOpacity="0.985"
                    stroke="#040404"
                    strokeOpacity="0.418"
                    transform="rotate(90 -139.637 381.326) scale(.62944)"
                >
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4077"
                        x="-267.209"
                        y="-116.033"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4075"
                            x="-267.209"
                            y="-116.033"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.209"
                                y="-116.033"
                                style={{lineHeight: "0.5"}}
                                id="tspan4073"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                437
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="437"
                        className={`is-clickable ${((carrels[436].status === "RESERVED") || (carrels[436].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeOpacity="0.418"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4083"
                        x="-266.978"
                        y="-125.708"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4081"
                            x="-266.978"
                            y="-125.708"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-266.978"
                                y="-125.708"
                                style={{lineHeight: "0.5"}}
                                id="tspan4079"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                436
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="436"
                        className={`is-clickable ${((carrels[435].status === "RESERVED") || (carrels[435].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeOpacity="0.418"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path8938-2"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeOpacity="0.418"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g
                    id="g8948-6"
                    fill="#c6e48b"
                    fillOpacity="0.985"
                    stroke="#040404"
                    strokeOpacity="0.418"
                    transform="rotate(90 -157.878 387.78) scale(.62944)"
                >
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4089"
                        x="-267.023"
                        y="-115.896"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        opacity="1"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4087"
                            x="-267.023"
                            y="-115.896"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.023"
                                y="-115.896"
                                style={{lineHeight: "0.5"}}
                                id="tspan4085"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                442
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="442"
                        className={`is-clickable ${((carrels[441].status === "RESERVED") || (carrels[441].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeOpacity="0.418"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4095"
                        x="-266.978"
                        y="-125.217"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4093"
                            x="-266.978"
                            y="-125.217"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-266.978"
                                y="-125.217"
                                style={{lineHeight: "0.5"}}
                                id="tspan4091"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                439
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="439"
                        className={`is-clickable ${((carrels[438].status === "RESERVED") || (carrels[438].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeOpacity="0.418"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path8946-9"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeOpacity="0.418"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g
                    id="g8956-3"
                    fill="#c6e48b"
                    fillOpacity="0.985"
                    stroke="#040404"
                    strokeOpacity="0.418"
                    transform="rotate(90 -151.985 393.673) scale(.62944)"
                >
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4101"
                        x="-266.598"
                        y="-116.191"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4099"
                            x="-266.598"
                            y="-116.191"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-266.598"
                                y="-116.191"
                                style={{lineHeight: "0.5"}}
                                id="tspan4097"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                441
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="441"
                        className={`is-clickable ${((carrels[440].status === "RESERVED") || (carrels[440].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeOpacity="0.418"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4107"
                        x="-266.551"
                        y="-125.524"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4105"
                            x="-266.551"
                            y="-125.524"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-266.551"
                                y="-125.524"
                                style={{lineHeight: "0.5"}}
                                id="tspan4103"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                440
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="440"
                        className={`is-clickable ${((carrels[439].status === "RESERVED") || (carrels[439].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeOpacity="0.418"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path8954-6"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeOpacity="0.418"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g
                    id="g8964-0"
                    fill="#c6e48b"
                    fillOpacity="0.985"
                    stroke="#040404"
                    strokeOpacity="0.418"
                    transform="rotate(90 -170.226 400.128) scale(.62944)"
                >
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4113"
                        x="-267.057"
                        y="-115.949"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4111"
                            x="-267.057"
                            y="-115.949"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.057"
                                y="-115.949"
                                style={{lineHeight: "0.5"}}
                                id="tspan4109"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                446
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="446"
                        className={`is-clickable ${((carrels[445].status === "RESERVED") || (carrels[445].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeOpacity="0.418"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4119"
                        x="-266.948"
                        y="-125.217"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4117"
                            x="-266.948"
                            y="-125.217"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-266.948"
                                y="-125.217"
                                style={{lineHeight: "0.5"}}
                                id="tspan4115"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                443
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="443"
                        className={`is-clickable ${((carrels[442].status === "RESERVED") || (carrels[442].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeOpacity="0.418"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path8962-3"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeOpacity="0.418"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g
                    id="g8972-8"
                    fill="#c6e48b"
                    fillOpacity="0.985"
                    stroke="#040404"
                    strokeOpacity="0.418"
                    transform="rotate(90 -164.333 406.021) scale(.62944)"
                >
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4125"
                        x="-267.224"
                        y="-115.859"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4123"
                            x="-267.224"
                            y="-115.859"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.224"
                                y="-115.859"
                                style={{lineHeight: "0.5"}}
                                id="tspan4121"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                445
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="445"
                        className={`is-clickable ${((carrels[444].status === "RESERVED") || (carrels[444].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeOpacity="0.418"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4131"
                        x="-267.555"
                        y="-125.619"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4129"
                            x="-267.555"
                            y="-125.619"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.555"
                                y="-125.619"
                                style={{lineHeight: "0.5"}}
                                id="tspan4127"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                444
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="444"
                        className={`is-clickable ${((carrels[443].status === "RESERVED") || (carrels[443].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeOpacity="0.418"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path8970-3"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeOpacity="0.418"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g
                    id="g9012-2"
                    fill="#c6e48b"
                    fillOpacity="0.985"
                    stroke="#040404"
                    strokeOpacity="0.418"
                    transform="translate(173.806 214.87) scale(.62944)"
                >
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4137"
                        x="-267.019"
                        y="-115.946"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4135"
                            x="-267.019"
                            y="-115.946"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.019"
                                y="-115.946"
                                style={{lineHeight: "0.5"}}
                                id="tspan4133"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                459
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="459"
                        className={`is-clickable ${((carrels[458].status === "RESERVED") || (carrels[458].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeOpacity="0.418"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4143"
                        x="-266.998"
                        y="-125.217"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4141"
                            x="-266.998"
                            y="-125.217"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-266.998"
                                y="-125.217"
                                style={{lineHeight: "0.5"}}
                                id="tspan4139"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                458
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="458"
                        className={`is-clickable ${((carrels[457].status === "RESERVED") || (carrels[457].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeOpacity="0.418"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path9010-1"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeOpacity="0.418"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g
                    id="g9020-8"
                    fill="#c6e48b"
                    fillOpacity="0.985"
                    stroke="#040404"
                    strokeOpacity="0.418"
                    transform="translate(173.806 226.47) scale(.62944)"
                >
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4149"
                        x="-267.019"
                        y="-115.946"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        opacity="0.99"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4147"
                            x="-267.019"
                            y="-115.946"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.019"
                                y="-115.946"
                                style={{lineHeight: "0.5"}}
                                id="tspan4145"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                460
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="460"
                        className={`is-clickable ${((carrels[459].status === "RESERVED") || (carrels[459].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeOpacity="0.418"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4155"
                        x="-266.987"
                        y="-125.265"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4153"
                            x="-266.987"
                            y="-125.265"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-266.987"
                                y="-125.265"
                                style={{lineHeight: "0.5"}}
                                id="tspan4151"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                457
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="457"
                        className={`is-clickable ${((carrels[456].status === "RESERVED") || (carrels[456].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeOpacity="0.418"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path9018-6"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeOpacity="0.418"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g
                    id="g9028-5"
                    fill="#c6e48b"
                    fillOpacity="0.985"
                    stroke="#040404"
                    strokeOpacity="0.418"
                    transform="translate(173.806 238.07) scale(.62944)"
                >
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4161"
                        x="-266.891"
                        y="-115.949"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4159"
                            x="-266.891"
                            y="-115.949"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-266.891"
                                y="-115.949"
                                style={{lineHeight: "0.5"}}
                                id="tspan4157"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                461
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="461"
                        className={`is-clickable ${((carrels[460].status === "RESERVED") || (carrels[460].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeOpacity="0.418"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4167"
                        x="-267.015"
                        y="-125.222"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4165"
                            x="-267.015"
                            y="-125.222"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.015"
                                y="-125.222"
                                style={{lineHeight: "0.5"}}
                                id="tspan4163"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                456
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="456"
                        className={`is-clickable ${((carrels[455].status === "RESERVED") || (carrels[455].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeOpacity="0.418"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path9026-7"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeOpacity="0.418"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g
                    id="g9036-0"
                    fill="#c6e48b"
                    fillOpacity="0.985"
                    stroke="#040404"
                    strokeOpacity="0.418"
                    transform="translate(173.806 249.67) scale(.62944)"
                >
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4173"
                        x="-267.023"
                        y="-115.944"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4171"
                            x="-267.023"
                            y="-115.944"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.023"
                                y="-115.944"
                                style={{lineHeight: "0.5"}}
                                id="tspan4169"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                462
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="462"
                        className={`is-clickable ${((carrels[461].status === "RESERVED") || (carrels[461].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeOpacity="0.418"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text4179"
                        x="-266.962"
                        y="-125.265"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan4177"
                            x="-266.962"
                            y="-125.265"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-266.962"
                                y="-125.265"
                                style={{lineHeight: "0.5"}}
                                id="tspan4175"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                455
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="455"
                        className={`is-clickable ${((carrels[454].status === "RESERVED") || (carrels[454].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeOpacity="0.418"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path9034-9"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeOpacity="0.418"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g
                    id="g9044-0"
                    fill="#c6e48b"
                    fillOpacity="0.985"
                    stroke="#040404"
                    strokeOpacity="0.418"
                    transform="translate(173.806 261.269) scale(.62944)"
                >
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text3487"
                        x="-266.99"
                        y="-115.944"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan3485"
                            x="-266.99"
                            y="-115.944"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-266.99"
                                y="-115.944"
                                style={{lineHeight: "0.5"}}
                                id="tspan3483"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                463
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="463"
                        className={`is-clickable ${((carrels[462].status === "RESERVED") || (carrels[462].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeOpacity="0.418"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text3493"
                        x="-267.034"
                        y="-125.265"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan3491"
                            x="-267.034"
                            y="-125.265"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.034"
                                y="-125.265"
                                style={{lineHeight: "0.5"}}
                                id="tspan3489"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                454
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="454"
                        className={`is-clickable ${((carrels[453].status === "RESERVED") || (carrels[453].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeOpacity="0.418"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path9042-7"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeOpacity="0.418"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g
                    id="g9052-3"
                    fill="#c6e48b"
                    fillOpacity="0.985"
                    stroke="#040404"
                    strokeOpacity="0.418"
                    transform="translate(173.806 272.868) scale(.62944)"
                >
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text3499"
                        x="-267.075"
                        y="-115.949"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan3497"
                            x="-267.075"
                            y="-115.949"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.075"
                                y="-115.949"
                                style={{lineHeight: "0.5"}}
                                id="tspan3495"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                464
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="464"
                        className={`is-clickable ${((carrels[463].status === "RESERVED") || (carrels[463].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeOpacity="0.418"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text3505"
                        x="-266.948"
                        y="-125.217"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan3503"
                            x="-266.948"
                            y="-125.217"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-266.948"
                                y="-125.217"
                                style={{lineHeight: "0.5"}}
                                id="tspan3501"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                453
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="453"
                        className={`is-clickable ${((carrels[452].status === "RESERVED") || (carrels[452].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeOpacity="0.418"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path9050-0"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeOpacity="0.418"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g
                    id="g9060-9"
                    fill="#c6e48b"
                    fillOpacity="0.985"
                    stroke="#040404"
                    strokeOpacity="0.418"
                    transform="translate(173.806 284.468) scale(.62944)"
                >
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text3511"
                        x="-267.004"
                        y="-115.949"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan3509"
                            x="-267.004"
                            y="-115.949"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.004"
                                y="-115.949"
                                style={{lineHeight: "0.5"}}
                                id="tspan3507"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                465
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="465"
                        className={`is-clickable ${((carrels[464].status === "RESERVED") || (carrels[464].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeOpacity="0.418"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text3517"
                        x="-266.981"
                        y="-125.217"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan3515"
                            x="-266.981"
                            y="-125.217"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-266.981"
                                y="-125.217"
                                style={{lineHeight: "0.5"}}
                                id="tspan3513"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                452
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="452"
                        className={`is-clickable ${((carrels[451].status === "RESERVED") || (carrels[451].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeOpacity="0.418"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path9058-6"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeOpacity="0.418"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g
                    id="g9068-5"
                    fill="#c6e48b"
                    fillOpacity="0.985"
                    stroke="#040404"
                    strokeOpacity="0.418"
                    transform="translate(173.806 296.067) scale(.62944)"
                >
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text3523"
                        x="-267.057"
                        y="-115.949"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan3521"
                            x="-267.057"
                            y="-115.949"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.057"
                                y="-115.949"
                                style={{lineHeight: "0.5"}}
                                id="tspan3519"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                466
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="466"
                        className={`is-clickable ${((carrels[465].status === "RESERVED") || (carrels[465].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text3529"
                        x="-266.849"
                        y="-125.257"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan3527"
                            x="-266.849"
                            y="-125.257"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-266.849"
                                y="-125.257"
                                style={{lineHeight: "0.5"}}
                                id="tspan3525"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                451
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="451"
                        className={`is-clickable ${((carrels[450].status === "RESERVED") || (carrels[450].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path9066-2"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g
                    id="g9076-5"
                    fill="#c6e48b"
                    fillOpacity="0.985"
                    stroke="#040404"
                    strokeOpacity="0.418"
                    transform="translate(173.806 307.667) scale(.62944)"
                >
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text3535"
                        x="-267.029"
                        y="-115.949"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan3533"
                            x="-267.029"
                            y="-115.949"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.029"
                                y="-115.949"
                                style={{lineHeight: "0.5"}}
                                id="tspan3531"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                467
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="467"
                        className={`is-clickable ${((carrels[466].status === "RESERVED") || (carrels[466].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text3541"
                        x="-266.978"
                        y="-125.218"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan3539"
                            x="-266.978"
                            y="-125.218"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-266.978"
                                y="-125.218"
                                style={{lineHeight: "0.5"}}
                                id="tspan3537"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                450
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="450"
                        className={`is-clickable ${((carrels[449].status === "RESERVED") || (carrels[449].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path9074-9"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g
                    id="g9084-6"
                    fill="#c6e48b"
                    fillOpacity="0.985"
                    stroke="#040404"
                    strokeOpacity="0.418"
                    transform="translate(173.806 319.266) scale(.62944)"
                >
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text3547"
                        x="-267.04"
                        y="-115.944"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        opacity="1"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan3545"
                            x="-267.04"
                            y="-115.944"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.04"
                                y="-115.944"
                                style={{lineHeight: "0.5"}}
                                id="tspan3543"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                468
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="468"
                        className={`is-clickable ${((carrels[467].status === "RESERVED") || (carrels[467].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text3553"
                        x="-266.978"
                        y="-125.215"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan3551"
                            x="-266.978"
                            y="-125.215"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-266.978"
                                y="-125.215"
                                style={{lineHeight: "0.5"}}
                                id="tspan3549"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                449
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="449"
                        className={`is-clickable ${((carrels[448].status === "RESERVED") || (carrels[448].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path9082-7"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g
                    id="g9092-4"
                    fill="#c6e48b"
                    fillOpacity="0.985"
                    stroke="#040404"
                    strokeOpacity="0.418"
                    transform="translate(173.806 330.866) scale(.62944)"
                >
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text3559"
                        x="-267.019"
                        y="-115.946"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan3557"
                            x="-267.019"
                            y="-115.946"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.019"
                                y="-115.946"
                                style={{lineHeight: "0.5"}}
                                id="tspan3555"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                469
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="469"
                        className={`is-clickable ${((carrels[468].status === "RESERVED") || (carrels[468].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text3565"
                        x="-266.998"
                        y="-125.217"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan3563"
                            x="-266.998"
                            y="-125.217"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-266.998"
                                y="-125.217"
                                style={{lineHeight: "0.5"}}
                                id="tspan3561"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                448
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="448"
                        className={`is-clickable ${((carrels[447].status === "RESERVED") || (carrels[447].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path9090-9"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <g
                    id="g9100-6"
                    fill="#c6e48b"
                    fillOpacity="0.985"
                    stroke="#040404"
                    strokeOpacity="0.418"
                    transform="translate(173.806 342.465) scale(.62944)"
                >
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text3571"
                        x="-267.019"
                        y="-115.946"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan3569"
                            x="-267.019"
                            y="-115.946"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-267.019"
                                y="-115.946"
                                style={{lineHeight: "0.5"}}
                                id="tspan3567"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                470
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="470"
                        className={`is-clickable ${((carrels[469].status === "RESERVED") || (carrels[469].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.425"
                        y="-120.922"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <text
                        xmlSpace="preserve"
                        style={{lineHeight: "0.5"}}
                        id="text3577"
                        x="-266.987"
                        y="-125.217"
                        fill="#000"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.265"
                        fontFamily="sans-serif"
                        fontSize="10.583"
                        fontStyle="normal"
                        fontWeight="normal"
                        letterSpacing="0"
                        transform="rotate(-90)"
                        wordSpacing="0"
                    >
                        <tspan
                            id="tspan3575"
                            x="-266.987"
                            y="-125.217"
                            strokeWidth="0.265"
                        >
                            <tspan
                                x="-266.987"
                                y="-125.217"
                                style={{lineHeight: "0.5"}}
                                id="tspan3573"
                                strokeWidth="0.265"
                                fontSize="6.35"
                            >
                                447
                            </tspan>
                        </tspan>
                    </text>
                    <rect
                        id="447"
                        className={`is-clickable ${((carrels[446].status === "RESERVED") || (carrels[446].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                        width="17.278"
                        height="9.217"
                        x="-270.442"
                        y="-130.195"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeWidth="0.265"
                        opacity="0.5"
                        ry="0.561"
                        transform="rotate(-89.639)"
                    ></rect>
                    <path
                        id="path9098-2"
                        fill="#c6e48b"
                        fillOpacity="0.985"
                        stroke="#040404"
                        strokeDasharray="none"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="4"
                        strokeWidth="1.613"
                        d="M-122.572 252.255l-.05 17.555"
                    ></path>
                </g>
                <text
                    xmlSpace="preserve"
                    style={{lineHeight: "0.5"}}
                    id="text3481"
                    x="74.116"
                    y="516.337"
                    fill="#000"
                    fillOpacity="1"
                    stroke="none"
                    strokeWidth="0.171"
                    fontFamily="sans-serif"
                    fontSize="6.847"
                    fontStyle="normal"
                    fontWeight="normal"
                    letterSpacing="0"
                    transform="scale(.99008 1.01001)"
                    wordSpacing="0"
                >
                    <tspan id="tspan3479" x="74.116" y="516.337" strokeWidth="0.171">
                        <tspan
                            x="74.116"
                            y="516.337"
                            style={{lineHeight: "0.5"}}
                            id="tspan3477"
                            strokeWidth="0.171"
                            fontSize="4.108"
                        >
                            471
                        </tspan>
                    </tspan>
                </text>
                <rect
                    id="471"
                    className={`is-clickable ${((carrels[470].status === "RESERVED") || (carrels[470].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                    width="10.875"
                    height="5.802"
                    x="74.963"
                    y="516.788"
                    fill="#c6e48b"
                    fillOpacity="0.984"
                    stroke="#040404"
                    strokeOpacity="0.418"
                    strokeWidth="0.167"
                    opacity="0.5"
                    ry="0.353"
                    transform="rotate(.361)"
                ></rect>
                <path
                    id="path11499-6"
                    fill="#4c7ca8"
                    fillOpacity="1"
                    stroke="#040404"
                    strokeDasharray="none"
                    strokeLinecap="butt"
                    strokeLinejoin="miter"
                    strokeMiterlimit="4"
                    strokeOpacity="0.418"
                    strokeWidth="1.015"
                    d="M82.659 517.283l-11.05-.032"
                ></path>
                <text
                    xmlSpace="preserve"
                    style={{lineHeight: "0.5"}}
                    id="text3475"
                    x="62.546"
                    y="514.798"
                    fill="#000"
                    fillOpacity="1"
                    stroke="none"
                    strokeWidth="0.168"
                    fontFamily="sans-serif"
                    fontSize="6.71"
                    fontStyle="normal"
                    fontWeight="normal"
                    letterSpacing="0"
                    transform="scale(.98713 1.01304)"
                    wordSpacing="0"
                >
                    <tspan id="tspan3473" x="62.546" y="514.798" strokeWidth="0.168">
                        <tspan
                            x="62.546"
                            y="514.798"
                            style={{lineHeight: "0.5"}}
                            id="tspan3471"
                            strokeWidth="0.168"
                            fontSize="4.026"
                        >
                            472
                        </tspan>
                    </tspan>
                </text>
                <rect
                    id="472"
                    className={`is-clickable ${((carrels[471].status === "RESERVED") || (carrels[471].studentNetId)) ? "TAKEN" : "AVAILABLE"}`}
                    width="10.875"
                    height="5.802"
                    x="63.273"
                    y="516.863"
                    fill="#c6e48b"
                    fillOpacity="0.984"
                    stroke="#040404"
                    strokeOpacity="0.418"
                    strokeWidth="0.167"
                    opacity="0.5"
                    ry="0.353"
                    transform="rotate(.361)"
                ></rect>
                <path
                    id="path11511-4"
                    fill="#4c7ca8"
                    fillOpacity="1"
                    stroke="#040404"
                    strokeDasharray="none"
                    strokeLinecap="butt"
                    strokeLinejoin="miter"
                    strokeMiterlimit="4"
                    strokeOpacity="0.418"
                    strokeWidth="1.015"
                    d="M70.959 517.283l-11.05-.032"
                ></path>
            </g>
            {Modal}
        </g> : null
    );
}